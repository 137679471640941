import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { themeColor } from '../../config';
import Checkbox from '../../components/Checkbox';
import InputField from '../../components/inputField';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import SelectField from '../../components/SelectField';
import { yupResolver } from '@hookform/resolvers/yup';
import { JamiatPermissionSchema } from '../../utils/Schemas';
import { useForm } from 'react-hook-form';
import RadioButton from '../../components/RadioButton';

const CreateRole = () => {
  const navigate = useNavigate()
    const [roleName, setRoleName] = useState('');
    const [roleData,setRoleData] = useState();
    const [roleAccessOptions,setRoleAccessOptions] = useState();
    const [loader, setLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [createRoleData, setCreateRoleData] = useState({
        name: '',
        roleDetails: []
    });
    const [selectJamiatOptions,setSelectJamiatOptions] = useState([])
    const [jamiats, setJamiats] = useState();
    const { handleSubmit, register, watch,reset,setValue, formState: {errors}, control } = useForm({
        resolver: yupResolver(JamiatPermissionSchema),
    });

    const dispatch = useDispatch();

    const fetchOptions = () => {
        setLoader(true);
        GetFetch(`/api/Roles/GetCreateReqDetails`).then((response) => {
            if (response.status == 200){
                setRoleAccessOptions(response.data);
            }
            else {
              showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            }
            setLoader(false);
        }).catch((err)=>{
            console.log('error', err);
            showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
            setLoader(false);
        })
      }

      const createRolePost = () => {
        return new Promise((resolve, reject)=>{
          setBtnLoader(true);
          PostFetch('/api/Roles', createRoleData, "POST").then((response)=>{
              if(response.status === 200) {
                  showSuccessAlert('Role Created Successfully');
                  resolve();
                  navigate('/access-control/role-management')
              }
              else {
                  showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                  reject();
              }
              setBtnLoader(false);
          }).catch((error)=>{
              console.log('error', error);
              showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
              setBtnLoader(false);
              reject();
          })
        })}

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
      dispatch({
          type: ALERT_SUCCESS,
          payload: msg
      })
  }
    
  const handleChange =(item,i,child)=>{
      if(child == 1){
        let dataT = roleData
        dataT?.map((data,index)=>{
          data?.childrens?.length && data?.childrens?.map((child,childIndex)=>{
            if(child.optionId == item.optionId){
              child.allow = !item.allow
              if(child.allow){
                data.allow = true
              }
              if(!child.allow){
                if (child.childrens && child.childrens.length) {
                  child.childrens.map((subChild, childIndex) => {
                    subChild.allow = false
                  })
                }
              }
              
              setRoleData([...dataT])
            }
          })
        })
      }
      else if(child == 2){
        let dataT = roleData
        dataT?.map((data,index)=>{
          data?.childrens && data.childrens?.length && data.childrens?.map((child,childIndex)=>{
            child.childrens && child.childrens.length && child.childrens.map((subChild,subIndex)=>{
                if(subChild.optionId == item.optionId){
                  subChild.allow = !item.allow
                  if(subChild.allow){
                    child.allow = true
                    data.allow = true
                  }
                  setRoleData([...dataT])
                }
              })
          })
        })
      }
      else{
        let dataT = roleData
          dataT.map((data,index)=>{
            if(data.optionId == item.optionId){
              data.allow = !item.allow
                if(!data.allow){
                  if( data.childrens && data.childrens.length){
                    data.childrens.map((child,childIndex)=>{
                      child.allow = false
                        if(child.childrens && child.childrens.length){
                          child.childrens.map((subChild,subIndex)=>{
                            subChild.allow = false
                          })
                        }
                    })
                  }
                }
            }
          })
          setRoleData([...dataT])
      }
    }

    const addRole = () => {
        if(roleName.length > 1 && roleName.charAt(0)!= " "){
          setShowNote(false)
            let a = [];
            for(let i=0; i < roleData?.length; i++){
              if(roleData[i].allow){
                if (roleData[i].jamaatPermissionReq){
                  if(roleData[i].jamiatPermission == 2){
                    let Id = []
                    let arrayData = watch(`${roleData.name}Jamiat`)
                    arrayData && arrayData.length > 0 && arrayData.map((item,i)=>{
                        Id.push(item.value)
                    })
                    a = [...a,{optionId: roleData[i].optionId, jamiatPermission: 0, jamaatPermission: 0,jamiatIds:Id}]
                  }else{
                    a = [...a,{optionId: roleData[i].optionId, jamiatPermission: 0, jamaatPermission: 0}];
                  }
                }else{
                  a = [...a,{optionId: roleData[i].optionId}];
                }
                if(roleData[i].childrens?.length > 0){
                  for(let j=0; j < roleData[i].childrens?.length; j++){
                    if(roleData[i].childrens[j].allow){
                      if(roleData[i].childrens[j].jamaatPermissionReq){
                        if(roleData[i].childrens[j].jamiatPermission == 2){
                          let Id = []
                          let arrayData = watch(`${roleData[i].childrens[j].name}Jamiat`)
                          arrayData && arrayData.length > 0 && arrayData.map((item,i)=>{
                              Id.push(item.value)
                          })
                        a = [...a,{optionId: roleData[i].childrens[j].optionId, jamiatPermission: roleData[i].childrens[j].jamaatPermissionReq ? roleData[i].childrens[j].jamiatPermission : 0, jamaatPermission: roleData[i].childrens[j].jamaatPermissionReq ? roleData[i].childrens[j].jamaatPermission : 0,jamiatIds:Id}];
                        }else{
                          a = [...a,{optionId: roleData[i].childrens[j].optionId, jamiatPermission: roleData[i].childrens[j].jamaatPermissionReq ? roleData[i].childrens[j].jamiatPermission : 0, jamaatPermission: roleData[i].childrens[j].jamaatPermissionReq ? roleData[i].childrens[j].jamaatPermission : 0}];
                        }
                      }else{
                        a = [...a,{optionId: roleData[i].childrens[j].optionId}];
                      }
                      if(roleData[i].childrens[j].childrens?.length > 0){
                        for(let k=0; k < roleData[i].childrens[j].childrens?.length; k++){
                          if(roleData[i].childrens[j].childrens[k].allow){
                            if(roleData[i].childrens[j].childrens[k].jamaatPermissionReq){
                              if(roleData[i].childrens[j].childrens[k].jamiatPermission == 2){
                                let Id = []
                                let arrayData = watch(`${roleData[i].childrens[j].childrens[k].name}Jamiat`)
                                arrayData && arrayData.length > 0 && arrayData.map((item,i)=>{
                                    Id.push(item.value)
                                })
                                a = [...a,{optionId: roleData[i].childrens[j].childrens[k].optionId, jamiatPermission: roleData[i].childrens[j].childrens[k].jamaatPermissionReq ? roleData[i].childrens[j].childrens[k].jamiatPermission : 0, jamaatPermission: roleData[i].childrens[j].childrens[k].jamaatPermissionReq ? roleData[i].childrens[j].childrens[k].jamaatPermission : 0,jamiatIds:Id}];
                              }else{
                                a = [...a,{optionId: roleData[i].childrens[j].childrens[k].optionId, jamiatPermission: roleData[i].childrens[j].childrens[k].jamaatPermissionReq ? roleData[i].childrens[j].childrens[k].jamiatPermission : 0, jamaatPermission: roleData[i].childrens[j].childrens[k].jamaatPermissionReq ? roleData[i].childrens[j].childrens[k].jamaatPermission : 0}];
                              }
                            }else{
                              a = [...a,{optionId: roleData[i].childrens[j].childrens[k].optionId}];
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }  
            let checkCustom = []
            a && a.map((item,i)=>{
              if(item && item.jamiatIds && item.jamiatIds.length == 0){
                checkCustom.push(true)
              }
            }) 
            if(checkCustom[0] == true){
              showErrorAlert("Please Select Jamiat in Custom Field");
            }else{
              setCreateRoleData({name:roleName,roleDetails: a});
            }
            setTimeout(() => {
              a=[];
            }, 2000);
        }else{
          setShowNote(true);
        }
    }

    const fetchJamiat = () => {
      GetFetch(`/api/Jamiat`).then((response) => {
          if (response.status == 200){
              setSelectJamiatOptions(response.data);
          }
          else {
              console.log('Something went wrong');
          }
      }).catch((err)=>{
          console.log('error', err);
      })
    }

    useEffect(()=>{
      fetchJamiat()
    },[])

    useEffect(()=>{
      if(selectJamiatOptions){
          let dataJamiat = []
          selectJamiatOptions.map((jamiat,i)=>{
            dataJamiat.push({value: jamiat.jamiatId, label: jamiat.name})
          })  
          setJamiats(dataJamiat)        
      }
    }, [selectJamiatOptions])

    useEffect(()=>{
        if( createRoleData.name.length > 0){
          createRolePost().then(()=>{
            navigate('/access-control/role-management', {replace: true});
            setCreateRoleData({
            name: '',
            roleDetails: []
        })});
        }
    }, [createRoleData]);

      useEffect(()=>{
        fetchOptions();
      }, []);

      useEffect(()=>{
        if(roleAccessOptions?.options){
            setRoleData(roleAccessOptions?.options);
        }
      },[roleAccessOptions]);

      const jamaatPermissionOnChange = (item,child,permissionType,e) => {  
        if(child == 1){
          let dataT = roleData
          dataT?.map((data,index)=>{
            data?.childrens?.length && data?.childrens?.map((child,childIndex)=>{
              if(child.optionId == item.optionId){
                if(permissionType == 'jamaat'){
                  child.jamaatPermission = parseInt(e)
                }
                if(permissionType == 'jamiat'){
                  child.jamiatPermission = parseInt(e)
                }
                if(child.jamiatPermission == 1 || child.jamiatPermission == 2){
                  child.jamaatPermission =1
                }
              }
            })
          })
          setRoleData([...dataT])
        }
        else if(child == 2){
          let dataT = roleData
          dataT.map((data,index)=>{
            data?.childrens?.length && data?.childrens?.map((child,childIndex)=>{
              child.childrens && child.childrens.length && child.childrens.map((subChild,i)=>{
                if(subChild.optionId == item.optionId){
                  if(permissionType == 'jamaat'){
                    subChild.jamaatPermission = parseInt(e)
                  }
                  if(permissionType == 'jamiat'){
                    subChild.jamiatPermission = parseInt(e)
                  }
                  if(subChild.jamiatPermission == 1 || subChild.jamiatPermission == 2){
                    subChild.jamaatPermission =1
                  }
                }
              })
              
            })
          })
          setRoleData([...dataT])
        }
        else if(child == "custom"){  
          let dataT = roleData
          dataT.map((data,index)=>{
            data?.childrens?.length && data?.childrens?.map((child,childIndex)=>{
              if(child.optionId == item.optionId){
                if(permissionType == 'jamiat'){
                  child.jamiatPermission = parseInt(e)
                }
                if(child.jamiatPermission == 1){
                  child.jamaatPermission = 1 
                }
              }
            })
          })
          setRoleData([...dataT])
        }
        else if(child == "custom2"){  
          let dataT = roleData
          dataT?.map((data,index)=>{
            data?.childrens?.length && data?.childrens?.map((child,childIndex)=>{
              child.childrens && child.childrens.length && child.childrens.map((subChild,i)=>{
                if(subChild.optionId == item.optionId){
                  if(permissionType == 'jamiat'){
                    subChild.jamiatPermission = parseInt(e)
                  }
                  if(subChild.jamiatPermission == 1){
                    subChild.jamaatPermission = 1 
                  }
                }
              })
             
            })
          })
          setRoleData([...dataT])
        }
      }

    return (
    <div className='main-wrapper'>
        <div className="container">
        <div className="app-head">
            <Link to='/access-control/role-management' className='back-btn' style={{color: `${themeColor.primaryColor}`}}><IoIosArrowBack/></Link>
            <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>CREATE ROLE</h2>
        </div>
        {loader ?
            <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
            </div>
            :
            <div className='role-access-wrapper'>
                <div className="edit-role-name">
                    <InputField name={'Role Name'} fieldPlaceholder={'Role Name'} 
                    fieldLabel={`Role Name`} value={roleName} error={ showNote  ? 'Please enter role name'  :""} handleChange={(e)=>setRoleName(e.target.value)} />
                    <Button variant='success' onClick={()=>roleName.match(/^[0-9a-zA-Z," ",.,-]+$/) ? addRole() :  showErrorAlert("Role name should not started with space or special character")} disabled={btnLoader}>Create Role{
                        btnLoader ? 
                        <div className='loader-wrapper btn-loader'>
                            <Spinner animation="border" variant="white" />
                        </div>: ''
                    }</Button>
                </div>
                {roleData?.map((option,i)=>(
                    <div key={option.name} className="role-wrapper" style={{color: `${themeColor.secondaryColor}`}}>
                        <div className="head">
                            <h4>{option.name}</h4>
                            <Checkbox name={"user-access"}  greenCheckbox={true} checked={option.allow} handleChange={()=>handleChange(option,i)}/>
                        </div>
                        {option.childrens?.length > 0 ?
                            <div className="checkbox-wrapper">
                                {option.childrens.map((children,i)=>(
                                    <div className="checkbox-field parent" key={children.name}>
                                        <Checkbox label={children.name} id={children.name} greenCheckbox={true} checked={children.allow} handleChange={()=>handleChange(children,i,1)} name={children.name} />
                                        {children.jamaatPermissionReq && children.allow ?
                                            <div className="field-wrapper radio-btn-wrapper">
                                                <div className="radio-wrapper">
                                                <p className='para'>Jamiat</p>
                                                <RadioButton label={'Own'} name={`${children.optionId}jamiat`} id={`${children.name}jamiatOwn`} value={0} checked={children.jamiatPermission == 0 ? true : false} handleChange={(e)=>jamaatPermissionOnChange(children,1,'jamiat',e.target.value)} />
                                                <RadioButton label={'All'} name={`${children.optionId}jamiat`} id={`${children.name}jamiatAll`} value={1} checked={children.jamiatPermission == 1 ? true : false} handleChange={(e)=>jamaatPermissionOnChange(children,1,'jamiat',e.target.value)} />
                                                <RadioButton label={'Custom'} name={`${children.optionId}jamiat`} id={`${children.name}Custom`} value={2} checked={children.jamiatPermission == 2 ? true:false}  handleChange={(e)=>jamaatPermissionOnChange(children,"custom",'jamiat',e.target.value)} />
                                                {children.jamiatPermission == 2 && 
                                                <SelectField isMulti={true} options={jamiats}
                                                register={{...register(`${children.name}Jamiat`)}}
                                                name={`${children.name}Jamiat`} 
                                                placeholder={'Select Jamiat'}
                                                control={control} 
                                                />
                                                }
                                              </div>
                                              {children.jamiatPermission == 0 ?
                                                <div className="radio-wrapper">
                                                <p className='para'>Jamaat</p>
                                                <RadioButton label={'Own'} name={`${children.optionId}jamaat`} id={`${children.name}jamaatOwn`} value={0} defaultChecked={true}   handleChange={(e)=>jamaatPermissionOnChange(children,1,'jamaat',e.target.value)} />
                                                <RadioButton label={'All'}name={`${children.optionId}jamaat`} id={`${children.name}jamaatAll`} value={1}   handleChange={(e)=>jamaatPermissionOnChange(children,1,'jamaat',e.target.value)} />
                                              </div>
                                              :
                                              <div className="radio-wrapper">
                                                <p className='para'>Jamaat</p>
                                                <RadioButton label={'Own'} name={`${children.optionId}jamaat`} id={`${children.name}jamaatOwn`} value={0} defaultChecked={true} disabled={true}  checked={false} handleChange={(e)=>jamaatPermissionOnChange(children,1,'jamaat',e.target.value)} />
                                                <RadioButton label={'All'}name={`${children.optionId}jamaat`} id={`${children.name}jamaatAll`} value={1}   checked={true} handleChange={(e)=>jamaatPermissionOnChange(children,1,'jamaat',e.target.value)} />
                                              </div>
                                              }
                                            </div>
                                        : ''}
                                        {children.childrens?.length > 0 ?
                                            <div className="checkbox-wrapper child">
                                                {children.childrens?.map((subChild,i)=>(
                                                    <div className="checkbox-field" key={subChild.name}>
                                                        <Checkbox label={subChild.name} id={subChild.name} greenCheckbox={true} checked={subChild.allow} handleChange={()=>handleChange(subChild,i,2)} name={subChild.name} />
                                                        {subChild.jamaatPermissionReq && subChild.allow ?
                                                            <div className="field-wrapper radio-btn-wrapper">
                                                                <div className="radio-wrapper">
                                                                <p className='para'>Jamiat</p>
                                                                <RadioButton label={'Own'} name={`${subChild.optionId}jamiat`} id={`${subChild.name}jamiatOwn`} value={0} checked={subChild.jamiatPermission == 0 ? true : false} handleChange={(e)=>jamaatPermissionOnChange(subChild,2,'jamiat',e.target.value)} />
                                                                <RadioButton label={'All'} name={`${subChild.optionId}jamiat`} id={`${subChild.name}jamiatAll`} value={1} checked={subChild.jamiatPermission == 1 ? true : false} handleChange={(e)=>jamaatPermissionOnChange(subChild,2,'jamiat',e.target.value)} />
                                                                <RadioButton label={'Custom'} name={`${subChild.optionId}jamiat`} id={`${subChild.name}Custom`} value={2} checked={subChild.jamiatPermission == 2 ? true:false}  handleChange={(e)=>jamaatPermissionOnChange(subChild,"custom2",'jamiat',e.target.value)} />
                                                                {subChild.jamiatPermission == 2 && 
                                                                <SelectField isMulti={true} options={jamiats}
                                                                register={{...register(`${subChild.name}Jamiat`)}}
                                                                name={`${subChild.name}Jamiat`} 
                                                                placeholder={'Select Jamiat'}
                                                                control={control} 
                                                                />
                                                                }
                                                              </div>
                                                              {subChild.jamiatPermission == 0 ?
                                                                <div className="radio-wrapper">
                                                                <p className='para'>Jamaat</p>
                                                                <RadioButton label={'Own'} name={`${subChild.optionId}jamaat`} id={`${subChild.name}jamaatOwn`} value={0} defaultChecked={true}   handleChange={(e)=>jamaatPermissionOnChange(subChild,2,'jamaat',e.target.value)} />
                                                                <RadioButton label={'All'}name={`${subChild.optionId}jamaat`} id={`${subChild.name}jamaatAll`} value={1}   handleChange={(e)=>jamaatPermissionOnChange(subChild,2,'jamaat',e.target.value)} />
                                                              </div>
                                                              :
                                                              <div className="radio-wrapper">
                                                                <p className='para'>Jamaat</p>
                                                                <RadioButton label={'Own'} name={`${subChild.optionId}jamaat`} id={`${subChild.name}jamaatOwn`} value={0} defaultChecked={true} disabled={true}  checked={false} handleChange={(e)=>jamaatPermissionOnChange(subChild,2,'jamaat',e.target.value)} />
                                                                <RadioButton label={'All'}name={`${subChild.optionId}jamaat`} id={`${subChild.name}jamaatAll`} value={1}   checked={true} handleChange={(e)=>jamaatPermissionOnChange(subChild,2,'jamaat',e.target.value)} />
                                                              </div>
                                                              }
                                                            </div>
                                                        : ''}
                                                    </div>
                                                ))}
                                            </div>
                                        :""}
                                    </div>
                                ))}
                            </div> 
                            : ""
                        }
                        
                    </div>
                ))}
            </div>
        }
        
        </div>
    </div>
    )
  
}

export default CreateRole