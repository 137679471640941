import { useDispatch } from "react-redux"
import { API_KEY } from "../config";
import { ALERT_ERROR } from "../store/reducers/alert";

export const replaceStringEmail = (data) => {
  let newstr = data.replace('<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a>', '')
  return newstr
}
export const replaceBackSlash = (data) => {
  let newstr = data.replace('\\', '')
  return newstr
}
export const nestedValidation = (args, names) => {
  let errors = "";
  args?.map((arg) => {
    names?.map((name) => {
      if (!arg[name]) {
        return (errors = `${name} is required`)
      }
    })
  })
  return errors;
}
export const handleChangeMemberITS = (data) => {
  let arr = data.ItsIds.split("\n");
  let numArr = []
  arr && arr.length && arr.map((str, i) => {
    if (str.length > 8 || str.length < 8) {
      // setIdError(true)
    }
    else if (str.length == 8) {
      numArr.push(str)
      // setIdError(false)
    }
  })
  return numArr
}

export const handleVenueSelect = (data) => {
  if (data) {
    let venueData = []
    data?.venue?.map((res) => {
      venueData.push(res.id)
    })
    return venueData
  }
}

export const handleTeamSelect = (data) => {
  if (data) {
    let teamData = []
    data?.team?.map((res) => {
      teamData.push(res.id)
    })
    return teamData
  }

}

const showErrorAlert = (msg, dispatch) => {
  dispatch({
    type: ALERT_ERROR,
    payload: msg
  })
}

export const handleGeocode = async (values, dispatch) => {
  let address = values.address + ',' + values.city + ',' + values.zip + ',' + values.state
  let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`;
  try {
    const response = await fetch(`${url}`);
    const data = await response.json();
    const result = data.results[0];
    if (result) {
      const { lat, lng } = result.geometry.location;
      return { latitude: lat, longitude: lng };
    } else {
      showErrorAlert('Invalid Address', dispatch);
    }
  } catch (error) {
    console.error('Error geocoding address:', error);
    return null;
  }
};