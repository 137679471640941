import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { themeColor } from '../../../config'
import InputField from '../../../components/inputField'
import { AddEditTaskSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import SelectField from '../../../components/SelectField'
// import { taskTableData, teamData } from '../../../appContent'
import TextareaField from '../../../components/TextareaField'
import { useDispatch, useSelector } from 'react-redux'
import { AllTeamAction } from '../../../store/actions/teamAction'
import { get, isEmpty } from "lodash";
import { AddTaskAction, GetSingleTaskAction, SINGLE_TASK, UpdateTaskAction } from '../../../store/actions/taskAction'

const AddEditTask = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [btnLoader, setBtnLoader] = useState(false)
  const [fieldValues, setFieldValues] = useState()
  const [teamList, setTeamList] = useState([])
  const teamData = useSelector((state) => state.teamreducer)
  const singleTask = useSelector((state) => state.taskreducer)
  // console.log("---single task", singleTask)
  const { handleSubmit, register, reset, formState: { errors }, control, setValue } = useForm({
    resolver: yupResolver(AddEditTaskSchema)
  })

  const submitAction = (data) => {
    let taskData = {
      miqaatId: params?.id,
      name: data?.TaskName,
      TeamId: data?.team?.id
    }
    // console.log("---data", taskData)
    if(params?.taskId){
      let updateData = { ...taskData, id: params?.taskId }
      dispatch(UpdateTaskAction(updateData, params.id, navigate))
    } else {
      dispatch(AddTaskAction(taskData, params.id, navigate))

    }
    reset()     
    setValue('team', '')
  }

  useEffect(() => {
    reset(fieldValues)
  }, [fieldValues, reset])

  useEffect(() =>{
    if(params?.id){
      dispatch(AllTeamAction(params?.id))
    }
  }, [params])

  useEffect(() =>{
    if(params?.taskId){
      dispatch(GetSingleTaskAction(params?.taskId))
    }
  }, [params])

  useEffect(() =>{
    if(!isEmpty(get(teamData, 'teamData'))){
      let newData = []
      teamData?.teamData.map((res)=>{
        let data = {
          id: res?.id,
          label: res?.name,
          value: res?.name
        }
        newData.push(data)
      })
      newData.unshift({id: 0, label:"None", value: 0})
      setTeamList(newData)
    }
  }, [get(teamData, 'teamData')])

  useEffect(() => {
    if (singleTask?.singleTask) {
      var keys = [];
      let teamData = {
        id: singleTask?.singleTask?.teamId,
        label: singleTask?.singleTask?.teamName || '',
        value: singleTask?.singleTask?.teamName
      }
      keys.push(teamData)
      setValue('team', singleTask?.singleTask?.teamName ? keys : '')
      setValue('TaskName', singleTask?.singleTask?.name)
    }
  }, [singleTask])

  const handleBack = () => {
    dispatch({
      type: SINGLE_TASK,
      singleTask: {}
    })
  }

  return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7} sm={12}>
                        <div className="app-head">
                            <Link to={`/view-miqaat/${params?.id}/tasks`} onClick={handleBack} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack/></Link>
                            <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>{params?.taskId ? 'Edit' : 'Add'} Tasks</h2>
                        </div>
                        {singleTask?.loading ? 
                        <div className='loader-wrapper'>
                        <Spinner animation="border" variant="success" />
                      </div> : 
                        <div className="form-wrapper">
                        <form onSubmit={handleSubmit(submitAction)}>
                            <InputField fieldPlaceholder={'Enter Task Name'} name={'TaskName'} fieldType={'text'} fieldLabel={'Task Name'}
                            error={errors.TaskName?.message} register={{ ...register('TaskName') }} />


                            <SelectField fieldLabel={'Assigned To:'} options={teamList} name={'team'}
                            placeholder={'Assigned To Team'} register={{ ...register('team') }}
                            error={errors.team?.message} control={control} />

                            <div className="btn-wrapper">
                                <Link to={`/view-miqaat/${params?.id}/tasks`} onClick={handleBack}><Button variant="danger">Cancel</Button></Link>
                                <Button variant="success" type='submit' disabled={singleTask?.loading} >Save{
                                    singleTask?.loading
                                      ? <div className='loader-wrapper btn-loader'>
                                        <Spinner animation="border" variant="white" />
                                    </div>
                                      : ''
                                }</Button>
                            </div>
                        </form>
                    </div>
                    }
                    </Col>
                </Row>
            </div>
        </div>
  )
}

export default AddEditTask
