import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from '../../components/inputField';
import { themeColor } from '../../config';
import TextEditor from '../../components/TextEditor';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { AddEditTemplateSchema } from '../../utils/Schemas';
import { useDispatch } from 'react-redux';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { replaceStringEmail } from '../../utils/helper';

const AddEditTemplate = () => {
    const navigate = useNavigate()
    const params = useParams();
    const tempId = {id:params?.id}
    const [templateFieldValues, setTemplateFieldValues] = useState();
    const [loader, setLoader] = useState(false);
    const [addTemplate,setAddTemplate] = useState();
    const [pageLoader,setPageLoader] = useState(false);
    const [fieldValues,setFieldValues] = useState({
        name: '',
        subject: '',
        htmlBody: ''
    });

    const dispatch = useDispatch();
    
    const { handleSubmit, register, reset, formState: {errors}, control, setValue } = useForm({
        resolver: yupResolver(AddEditTemplateSchema),
        defaultValues: {
            Name: fieldValues?.name,
            Subject: fieldValues?.subject,
            htmlBody: fieldValues?.htmlBody
        }
        
    });

    const addEmailTemplate = () => {
        setLoader(true);
        PostFetch('/api/EmailTemplates', templateFieldValues, "POST").then((response)=>{
            if(response.status === 200 || response.status === 202) {
                setAddTemplate(response.data);
                showSuccessAlert("Email Template Added Successfully")
                setTemplateFieldValues('');
            }else if(response.status === 409) {
                setAddTemplate(response.data);
                showErrorAlert(response.data?.msg ? response.data?.msg : "Something Went Wrong");
            }
            else {
                showErrorAlert(response.data?.msg ? response.data?.msg : "Something Went Wrong");
            }
            setLoader(false);
        }).catch((error)=>{
            console.log('error', error);
            showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
            setLoader(false);
        })
    }

    const UpdateEmailTemplate = () => {
        setLoader(true);
        PostFetch(`/api/EmailTemplates/${params.id}`, templateFieldValues, "PUT").then((response)=>{
            if(response.status === 200 || response.status === 202) {
                setAddTemplate(response.data);
                showSuccessAlert('Email Template Updated Successfully')
                setTemplateFieldValues('');
                navigate("/template")
            }else if(response.status === 409) {
                setAddTemplate(response.data);
                showErrorAlert(response.data.msg);
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            }
            setLoader(false);
        }).catch((error)=>{
            console.log('error', error);
            showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
            setLoader(false);
        })
    }

    const getTemplateDetails = (id) => {
        setPageLoader(true);
        GetFetch(`/api/EmailTemplates/${id}`).then((response) => {
            if (response.status == 200){
                setFieldValues(response.data);
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            }
            setPageLoader(false);
        }).catch((err)=>{
            console.log('error', err);
            showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
            setPageLoader(false);
        })
    }

    const submitAction = (data) => {
        let sub = data.Subject.charAt(0)
        let name = data.Name.charAt(0)
        if(name != " " && sub != " " && sub.match(/^[0-9a-zA-Z]+$/) &&  name.match(/^[0-9a-zA-Z]+$/)){
            data.htmlBody = replaceStringEmail(data.htmlBody)
            let updateData = {...data,Id:fieldValues.id}
            setTemplateFieldValues(updateData);
            reset();
        }
        else {
            showErrorAlert("Name or Subject should not start with a space/special character")
        }
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }

    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    useEffect(()=>{
        if(params && params.id){
            getTemplateDetails(params.id);
        }
    }, [params])

    useEffect(()=>{
        if(!tempId?.id && templateFieldValues && templateFieldValues.Name?.length > 0 ){
            addEmailTemplate();
        }
        else if(params && params.id && templateFieldValues && templateFieldValues.Name?.length > 0 ){
            UpdateEmailTemplate();
        }
    }, [templateFieldValues])

    useEffect(()=>{
        setValue("Name",fieldValues.name)
        setValue("Subject",fieldValues.subject)
        setValue("htmlBody",fieldValues.htmlBody)
    },[fieldValues])

  return (
    <div className='main-wrapper'>
        <div className="container">
            <Row className='justify-content-center'>
                <Col lg={7} sm={12}>
                    <div className="app-head">
                        <Link to='/template' className='back-btn' style={{color: `${themeColor.primaryColor}`}}><IoIosArrowBack/></Link>
                        <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>Template</h2>
                    </div>
                    {pageLoader ? 
                        <div className='loader-wrapper'>
                            <Spinner animation="border" variant="success" />
                        </div>
                        : 
                        <div className="form-wrapper">
                        <form onSubmit={handleSubmit(submitAction)}>
                            <InputField 
                            fieldPlaceholder={"Enter Template Name"} 
                            name='Name' 
                            val={fieldValues.name} 
                            fieldType={'text'} 
                            fieldLabel={'Template Name:'}
                            error={errors.Name?.message} 
                            register={{...register("Name")}} 
                            // {...( fieldValues?.name?.length > 0 ? {val: fieldValues.name, handleChng(e){setFieldValues(prev => ({...prev, name: e.target.value}))}} : {})}
                            handleChange={(e)=>setFieldValues(prev => ({...prev, name: e.target.value}))}
                             />
                            <InputField 
                            fieldPlaceholder={"Enter Subject"} 
                            val={fieldValues.subject} 
                            name={'Subject'} 
                            fieldType={'text'} 
                            fieldLabel={'Subject:'}
                            error={errors.Subject?.message} 
                            register={{...register("Subject")}} 
                            //  {...( fieldValues?.subject?.length > 0 ? {val: fieldValues.subject, handleChng(e){setFieldValues(prev => ({...prev, subject: e.target.value}))}} : {})}
                            handleChange={(e)=>setFieldValues(prev => ({...prev, subject: e.target.value}))}
                             />
                            <TextEditor 
                            placeholder={'Enter Message'} 
                            name={'htmlBody'} 
                            val={fieldValues.htmlBody}
                            label={'Message:'} 
                            register={{...register("htmlBody")}} 
                            error={errors.htmlBody?.message} 
                            control={control} 
                            // {...( fieldValues?.htmlBody?.length > 0 ? {val: fieldValues.htmlBody, handleChng(e){setFieldValues(prev => ({...prev, htmlBody: e}))}} : {})}
                            />

                            <div className="btn-wrapper">
                                <Link to='/template'><Button variant="danger">Cancel</Button></Link>
                                <Button variant="success" type='submit' disabled={loader} >Save{
                                    loader ? 
                                    <div className='loader-wrapper btn-loader'>
                                        <Spinner animation="border" variant="white" />
                                    </div>: ''
                                }</Button>
                            </div>
                        </form>
                    </div>
                    }
                    
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default AddEditTemplate