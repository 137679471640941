import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { TbFileExport } from 'react-icons/tb';
import { FiPrinter } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';
import { Spinner } from 'react-bootstrap'
import { themeColor } from '../../config'
import '../style.css'
import placeholderUserImg from "../../assets/images/profile.png";
import FilterData from '../../components/FilterData';
import ReactDatePicker from 'react-datepicker';
import { PostFetch } from '../../utils/fetchUrl';
import { useDispatch, useSelector } from 'react-redux';
import { ALERT_ERROR } from '../../store/reducers/alert';
import { GetSingleMiqaatAction, GetTeamAndVenuesAction } from '../../store/actions/miqaatAction';
import moment from 'moment';
import { exportExcelFile } from '../../components/ExportExcelFile';
import {get} from 'lodash';

const Attendance = () => {
  const params = useParams()
  const printRef = useRef();
  const dispatch = useDispatch()
  const dropDownOptions = useSelector((state) => state.miqaatreducer.dropdownOptions)
  const miqaatInfo = useSelector((state) => state.miqaatreducer.singleMiqaat)
  const [miqaatDates, setMiqaatDates] = useState({
    startDate: '',
    endDate: ''
  })
  const [processedData, setProcessedData] = useState([])
  const [loader, setLoader] = useState(true)
  const [filterValues, setFilterValue] = useState({});
  const [isDisabledClearBtn, setIsDisabledClearBtn] = useState(true)
  const [startDate, setDate] = useState()
  const [attendanceRecord, setAttendanceRecord] = useState([])
  const { teams, venues } = dropDownOptions

  const excelColumns = [
    { header: 'Photo', key: 'photo', width: 10 },
    { header: 'ITS ID', key: 'itsid', width: 14 },
    { header: 'Full Name', key: 'fullName', width: 45 },
    { header: 'Time', key: 'insertedAt', width: 20 },
    { header: 'Designantion', key: 'designantion', width: 25 },
    { header: 'Mobile', key: 'mobNo', width: 20 },
    { header: 'Whatsapp No.', key: 'whatsAppNo', width: 20 },
    { header: 'Venue', key: 'venue', width: 30 },
    { header: 'Team', key: 'teams', width: 35 },
  ]

  useEffect(() => {
    setMiqaatDates({
      startDate: get(miqaatInfo, 'dateFrom') || '',
      endDate:  get(miqaatInfo, 'dateTo') || '',
    })
  }, [miqaatInfo])

  useEffect(() => {
    if (miqaatDates.startDate && miqaatDates.endDate ) {
    if (new Date() < new Date(miqaatDates.startDate)) {
      setDate(null)
    }
    if (new Date() >= new Date(miqaatDates.startDate)){
      setDate(new Date())
    }
    if (new Date(miqaatDates.endDate) <= new Date()) {
      setDate(new Date(miqaatDates.endDate))
    }
  }
  }, [miqaatDates])

  useEffect(() => {
    if (params?.id) {
      dispatch(GetSingleMiqaatAction(params?.id))
      dispatch(GetTeamAndVenuesAction(params?.id))
    }
  }, [params])

  useEffect(() => {
    getAttendanceRecord()
  }, [startDate])

  useEffect(() => {
    setProcessedData(attendanceRecord)
  }, [attendanceRecord])

  useEffect(() => {
    const isFilterEmpty = Object.keys(filterValues).every(key => filterValues[key].trim() === "");
    setIsDisabledClearBtn(isFilterEmpty);
  }, [filterValues]);

  const showErrorAlert = (msg) => {
    dispatch({
      type: ALERT_ERROR,
      payload: msg
    })
  }

  const getAttendanceRecord = () => {
    setLoader(true);
    const formattedDate = startDate ? moment(startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
    const bodyData = {
      miqaatId: params?.id,
      date: formattedDate
    }
    if (new Date() >= new Date(miqaatDates.startDate)) {
      // setLoader(true);
    PostFetch(`/api/Attendances/Get?miqaatId=${params?.id}&date=${formattedDate}`, bodyData, "POST").then((response) => {
      if (response.status === 200) {
        setAttendanceRecord(response.data)
      }
      setLoader(false);
    }).catch((error) => {
      console.log('error', error);
      showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
      setLoader(false);
    })
  }
  }

  const handleClearFilter = () => {
    setFilterValue({})
    setIsDisabledClearBtn(true)
    document.querySelectorAll('input').forEach((input) => (input.value = null));
    document.querySelectorAll('select').forEach((select) => (select.value = ''));
  }

  const handleFilterChange = (keyName, value) => {
    setFilterValue({
      ...filterValues,
      [keyName]: value,
    });
  };

  const filterDataConfig = (columnName, keyName, filterType, handleFilterChange, options = null, width = '100%') => (
    <FilterData
      columnName={columnName}
      keyName={keyName}
      filterType={filterType}
      handleFilterChange={handleFilterChange}
      options={options}
      width={width}
    />
  );

  const columns = [
    {
      name: 'Photo',
      selector: (row) => {
        return row ? <img className="user-profile" src={row.photo ? `data:image/png;base64,${row.photo}` : placeholderUserImg} alt="User Photo" /> : "No data found"
      },
      minWidth: '100px',
      maxWidth: '80px',
    },
    {
      name: filterDataConfig('ITS ID', 'itsid', 'number', handleFilterChange),
      selector: (row) => row.itsid || ' ',
      minWidth: '140px',
      maxWidth: '150px',
      sortable: true,
    },
    {
      name: filterDataConfig('Full Name', 'fullName', 'text', handleFilterChange),
      selector: (row) => row.fullName || ' ',
      minWidth: '350px',
      maxWidth: '400px',
      sortable: true,
    },
    {
      name: filterDataConfig('Time', 'insertedAt', 'text', handleFilterChange),
      selector: (row) => row.insertedAt ? moment.utc(row.insertedAt).local().format('hh:mm A') : ' ',
      minWidth: '130px',
      maxWidth: '140px',
    },
    {
      name: filterDataConfig('Designantion', 'designation', 'text', handleFilterChange),
      selector: (row) => row.designation || ' ',
      minWidth: '180px',
      maxWidth: '200px',
    },
    {
      name: filterDataConfig('Mobile No.', 'mobNo', 'number', handleFilterChange),
      selector: (row) => row.mobNo || ' ',
      minWidth: '180px',
      maxWidth: '200px',
    },
    {
      name: filterDataConfig('Whatsapp No.', 'whatsAppNo', 'number', handleFilterChange),
      selector: (row) => row.whatsAppNo || ' ',
      minWidth: '180px',
      maxWidth: '200px',
    },
    {
      name: filterDataConfig('Zone', 'venue', 'select', handleFilterChange, venues, '100%'),
      selector: (row) => row.venue || ' ',
      minWidth: '200px',
      maxWidth: '200px',
    },
    {
      name: filterDataConfig('Team', 'Teams', 'select', handleFilterChange, teams, '100%'),
      selector: (row) => row.teams ? row.teams.join(', ') : ' ',
      minWidth: '250px',
      maxWidth: '300px',
    },
  ];


  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handlePrevDate = () => {
    const previousDate = new Date(startDate);
    previousDate.setDate(startDate.getDate() - 1);
    if (previousDate >= new Date(miqaatDates.startDate)) {
      setDate(previousDate);
    }
  }

  const handleNextDate = () => {
    const nextDate = new Date(startDate);
    nextDate.setDate(startDate.getDate() + 1);
    const miqaatEndDate = new Date(miqaatDates.endDate);
    miqaatEndDate.setHours(23, 59, 59, 999);
    if (nextDate <= miqaatEndDate) {
      setDate(nextDate);
    }
  }

  const getFilteredData = (filterData) => {
    setProcessedData(filterData)
  }

  return (
    <div className='main-wrapper'>

      <style>
        {`@media print {
  @page {
    size: landscape; / Set page size to landscape /
  }
.input-field {
    display: none;
  }
  .rdt_TableCell{
    min-width: 100px;
    max-width: 160px;
    padding: 6px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)
  }
  .rdt_TableRow{
    border: none !important;
  }
  .rdt_TableCol{
    min-width: 100px;
    max-width: 160px;
  }
  img {
    height: 50px;
    width: 50px
  }
  .print-page{
    page-break-before: always;
    page-break-after: always;
  }
   }`
        }
      </style>

      <div className="container">
        <div className="app-head">
          <Link to={`/view-miqaat/${params?.id}`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack /></Link>
          <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>ATTENDANCE</h2>
        </div>
        {loader
          ? <div className='loader-wrapper'>
            <Spinner animation="border" variant="success" />
          </div>
          :  (new Date() >= new Date(miqaatDates.startDate) ? (<div className="table-wrapper miqaat attandees attandance">
              <div style={{borderBottom: '1px solid #cccccc'}}>
              <header className='table-header'>
                <div>
                  <div className="customDatePickerWidth">
                    <i className="fa-solid fa-angle-left" onClick={handlePrevDate} style={{visibility: moment(miqaatDates.startDate).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD') ? 'hidden' : null}}></i>
                    <ReactDatePicker
                      className="input-field "
                      selected={startDate}
                      onChange={(d) => setDate(d)}
                      minDate={new Date(miqaatDates.startDate)}
                      maxDate={(new Date() > new Date(miqaatDates.endDate)) ? (new Date(miqaatDates.endDate)) : (new Date())}
                      todayButton={"Today"}
                      dateFormat="dd/MM/yyyy"
                    />
                    <i className="fa-solid fa-angle-right" onClick={handleNextDate} style={{visibility: (moment().format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD') || moment(miqaatDates.endDate).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')) ? 'hidden' : null}}></i>
                  </div>
                  <div className="bn-wrapper">
                    <button className='icon-btn dark' disabled={isDisabledClearBtn} style={{ backgroundColor: isDisabledClearBtn ? '#f5f5f5' : `${themeColor.primaryColor}`, color: isDisabledClearBtn ? '#070707' : '' }} onClick={handleClearFilter}>Clear Filter</button>
                    <button className='icon-btn' style={{ color: `${themeColor.primaryColor}` }} onClick={() => handlePrint()}> <FiPrinter />Print</button>
                    <button className='icon-btn dark' style={{ backgroundColor: `${themeColor.primaryColor}` }} onClick={() => exportExcelFile(processedData, excelColumns, `attendance-report-${moment(startDate).format("DD/MM/YYYY")}`)}> <TbFileExport />Download</button>
                  </div>
                </div>
              </header>
              <div ref={printRef} className='print-page'>
                <FilterData data={attendanceRecord}
                  columns={columns}
                  filterValues={filterValues}
                  getFilteredData={getFilteredData}
                />
              </div>
            </div>
            <div className='table-footer'>
              <p className="note">To scroll the report horizontally, press the Shift key and scroll using your mouse's scroll wheel.</p>
              <span>rows: {processedData.length}</span>
            </div>
          </div>) : <div className='no-data-heading'>This Miqaat is yet to start.</div>)
        }
      </div>
    </div>
  )
}

export default Attendance
