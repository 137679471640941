import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <p>© 2023 Nazafat Minal Iman - All rights reserved</p>
    </div>
  )
}

export default Footer