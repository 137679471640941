import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from '../../components/inputField';
import SelectField from '../../components/SelectField';
import Checkbox from '../../components/Checkbox';
import { themeColor } from '../../config';
import TextEditor from '../../components/TextEditor';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { SendEmailByPositionSchema } from '../../utils/Schemas';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import { replaceStringEmail } from '../../utils/helper';

const Position = () => {
  const [templateDetails, setTemplateDetails] = useState();
  const [templates, setTemplates] = useState();
  const [position, setPosition] = useState();
  const [positionsDetails, setPositionsDetails] = useState();
  const [positionId,setPositionId] = useState([])
  const [sendSubject,setSendSubject] = useState("")
  const [sendMessege,setSendMessege] = useState("")
  const [loader,setLoader] = useState(false);
  const [templateValue, setTemplateValue] = useState();
  const [templateFieldDetails, setTemplateFieldDetails] = useState();

  const dispatch = useDispatch();
  
  const { handleSubmit, register,watch, reset,setValue, formState: {errors}, control } = useForm({
      resolver: yupResolver(SendEmailByPositionSchema),
  });
  const resetData =()=>{
    reset();
    setValue("Position",null)
    setTemplateValue('');
}
  const submitAction = (data) => {
    if(data.Subject.charAt(0) === " "){
      showErrorAlert("Subject should not start with space");
    }
    else{
      setLoader(true);
      let body = {
        positionIds:positionId,
        subject:sendSubject,
        htmlBody:replaceStringEmail(sendMessege)
      }
      PostFetch('/api/Communications/sendEmailByPosition',body,"POST").then((response)=>{
          if (response.status == 200){
              showSuccessAlert('Successfull');
          }
          else {
            showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
          }
          setLoader(false);
          resetData();
      }).catch((err)=>{
        showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
        setLoader(false);
        resetData();
    })
    }
    
  }

  const fetchTemplate = () => {
    GetFetch(`/api/EmailTemplates`).then((response) => {
        if (response.status == 200){
            setTemplateDetails(response.data);
        }
    }).catch((err)=>{
        console.log('error', err);
    })
  }

  const fetchPositions = () => {
    
    GetFetch(`/api/Positions`).then((response) => {
        if (response.status == 200){
            setPositionsDetails(response.data);
        }
        
    }).catch((err)=>{
        console.log('error', err);
    })
  }
  const showErrorAlert = (msg) => {
    dispatch({
        type: ALERT_ERROR,
        payload: msg
    })
}
const showSuccessAlert = (msg) => {
    dispatch({
        type: ALERT_SUCCESS,
        payload: msg
    })
}

  useEffect(()=>{
    fetchPositions();
    fetchTemplate();
  }, [])

  useEffect(()=>{
    if(templateValue){
        GetFetch(`/api/EmailTemplates/${templateValue.value}`).then((response) => {
            if (response.status == 200){
                setTemplateFieldDetails(response.data);
            }
        }).catch((err)=>{
            console.log('error', err);
        })
    }
  }, [templateValue])

useEffect(()=>{
    if(templateFieldDetails){
        setValue('Subject', templateFieldDetails.subject);
        setValue('Message', templateFieldDetails.htmlBody);
    }
}, [templateFieldDetails])

  useEffect(()=>{
    if(templateDetails){
        setTemplates(
            templateDetails.map((template)=>(
                {value: template.id, label: template.name}
            ))
        )
    }
}, [templateDetails])

  useEffect(()=>{
    if(positionsDetails){
      let dataPosition = []
      positionsDetails.map((position)=>(
        dataPosition.push({value: position.id, label: position.name})
      ))
      dataPosition.unshift({value: -1, label:"All Select"})
      setPosition(dataPosition)
    }
}, [positionsDetails])

useEffect(()=>{
  let data = watch("Position")
  let Id = []
  if(data && data.length > 0 ){
      data.map((item,i)=>{
          Id.push(item.value)
          if(item.value == -1){
            setValue("Position",item) 
          }
      }) 
    }
      if(data && data.value == -1){
        Id.push(data.value)
      }
      setPositionId(Id)
}, [watch("Position")])

useEffect(()=>{
  let dataSubject = watch("Subject")
  setSendSubject(dataSubject)
  let dataBody = watch("Message")
  setSendMessege(dataBody)
},[watch("Subject"),watch("Message")])


  return (
    <div className='main-wrapper'>
        <div className="container">
            <Row className='justify-content-center'>
                <Col lg={7} sm={12}>
                    <div className="app-head">
                        <Link to='/dashboard' className='back-btn' style={{color: `${themeColor.primaryColor}`}}><IoIosArrowBack/></Link>
                        <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>POSITION</h2>
                    </div>
                    <div className="form-wrapper">
                      <form onSubmit={handleSubmit(submitAction)}>
                        <SelectField isMulti={true} fieldLabel={'Position:'} options={position} name={'Position'}
                        placeholder={'Select Position'} register={{...register("Position")}}
                        error={errors.Position?.message} control={control} />

                        {/* <Checkbox label={'Send to newly added Members'} checked={newMemberCheck ? 'checked' : ''}
                        handleChange={()=>setNewMemberCheck(prev => !prev)} /> */}

                        <SelectField fieldLabel={'Template:'} options={templates} name={'Template'}
                        placeholder={'Select Options'} value={templateValue} handleChange={(e)=>setTemplateValue(e)} />
                          
                        <InputField fieldPlaceholder={"Enter Subject"} fieldType={'text'}
                        fieldLabel={'Subject:'} error={errors.Subject?.message} 
                        register={{...register("Subject")}} name={'Subject'} />

                        <TextEditor placeholder={'Enter Message'} name={'Message'}
                          label={'Message:'} register={{...register("Message")}}
                          error={errors.Message?.message} control={control} />

                        <div className="btn-wrapper">
                          <Link to='/dashboard'><Button variant="danger">Cancel</Button></Link>
                          <Button variant="success" type='submit'disabled={loader} >Send{
                            loader ? 
                            <div className='loader-wrapper btn-loader'>
                                <Spinner animation="border" variant="white" />
                            </div>: ''
                          }</Button>
                        </div>
                      </form>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default Position