import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack, IoMdAddCircle } from 'react-icons/io'
import { MdModeEdit, MdDeleteOutline } from 'react-icons/md'
// import { GrFormView } from 'react-icons/gr'
import { Spinner } from 'react-bootstrap'
// import { FiPhone } from 'react-icons/fi'

// import { TbLocation } from 'react-icons/tb'
// profileUrl
import { themeColor } from '../../../config'
import DataTable from 'react-data-table-component'
import InputField from '../../../components/inputField'
// import placeholderUserImg from '../../../assets/images/profile.png'
// import { teamTableData } from '../../../appContent'
// import { questionData } from './questionJSON'
import { useDispatch, useSelector } from 'react-redux'
import { AllQuestionAction, DeleteQuestionAction, removeQuestions } from '../../../store/actions/questionAction'
import AppModal from '../../../components/Modal'

const ViewQuestion = () => {
  const dispatch = useDispatch();
  const params = useParams()
  const [showModal, setShowModal] = useState(false)
  const [deleteQuestioId, setDeleteQuestioId] = useState('')
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [loader, setLoader] = useState(true)
  const questionData = useSelector((state) => state.questionreducer.questionData)

  const columns = [
    {
      name: 'Question Title',
      selector: (row) => row?.title,
      width: '44%',
    },
    {
      name: 'Question Type',
      selector: (row) => row?.type,
    },
    {
      name: 'Priority',
      selector: (row) => row?.priority,
      maxWidth: '120px'
    },
    {
      name: 'Actions',
      cell: (row) => <p className='action-btn-wrapper'><Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat/${params?.id}/view-question/edit/${row.id}`} ><MdModeEdit/></Link> <button className='action-btn note-error' style={{ background: `${themeColor.lightGreen}`, color: 'red' }} onClick={() => deleteQuestion(row.id)} ><MdDeleteOutline/></button></p>,
      maxWidth: '120px'
    }
  ]

  const deleteQuestion = (id) => {
    setDeleteQuestioId(id)
    setShowModal(true)
  }

  const confirDelete = () => {
    let data = {
      id: deleteQuestioId,
      miqaatId: params?.id 
    }
    dispatch(DeleteQuestionAction(data))
    setShowModal(false)
  }

  useEffect(() =>{
    if(params?.id){
      dispatch(AllQuestionAction(params?.id))
    }
  },[params])

  useEffect(() => {
    setLoader(true)
    dispatch(removeQuestions());
  }, [])

  useEffect(() => {
    if (questionData.length > 0) {
      setFilteredData(questionData)
    } else {
      setFilteredData(questionData)
    }
  }, [questionData])

  useEffect(() => {
    if (search) {
      const result = questionData?.filter(data => Object.keys(data).some(key => {
        return String(data['title']).toLowerCase().includes(search.toLowerCase())
    }))
      setFilteredData(result)
    } else {
      setFilteredData(questionData)
    }
  }, [search])

  useEffect(() => {
    if (filteredData?.length > 0) {
      setLoader(false)
    } else {
      setTimeout(() => {
        setLoader(false)
      }, 1000);
    }
  }, [filteredData])

  return (
    <div className='main-wrapper'>
        <div className="container">
            <div className="app-head">
                <Link to={`/view-miqaat/${params?.id}`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack/></Link>
                <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}> QUESTIONS</h2>
            </div>
            {loader
              ? <div className='loader-wrapper'>
              <Spinner animation="border" variant="success" />
            </div>
              : <div className="table-wrapper view-user-table">
              <DataTable data={filteredData} columns={columns}
              fixedHeader fixedHeaderScrollHeight='482px'
              subHeader pagination
              subHeaderComponent={
                <div className='view-ques-head'>
                  <InputField className={'search-field'} fieldPlaceholder={'Search Questions'} fieldType={'text'} value={search}
                    handleChange={(e) => setSearch(e.target.value)} />
                    <Link to={`/view-miqaat/${params?.id}/view-question/add`} className='icon-btn dark' style={{ background: `${themeColor.primaryColor}`, display: 'inline-block' }}><IoMdAddCircle />Add Question</Link>
                </div>} subHeaderAlign="left"/>
            </div>
            }

            <AppModal handleAction={confirDelete} handleClose={()=>setShowModal(false)} hasBody={true} content='Are you sure, You want to delete Question?' showModal={showModal} title={'Confirm Delete'} actionBtnText={"Confirm"}/>
        </div>
    </div>
  )
}

export default ViewQuestion
