import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { Col, ProgressBar, Row, Spinner } from 'react-bootstrap';
import {HiUpload} from 'react-icons/hi';
import Button from 'react-bootstrap/Button';
import { useForm } from "react-hook-form";
import {MdOutlineSimCardDownload} from 'react-icons/md';
import { yupResolver } from "@hookform/resolvers/yup";
import {useCSVReader} from 'react-papaparse';
import * as yup from "yup";
import { themeColor } from '../../config';
import TextareaField from '../../components/TextareaField';
import { PostFetch } from '../../utils/fetchUrl';
import fileUrl from '../../assets/files/Sample.csv';
import { useDispatch } from 'react-redux';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import Checkbox from '../../components/Checkbox';

const AddUser = () => {
    const [addUserStatus, setAddUserStatus] = useState({
        syncedSuccessfulCount: 0,
        taggedCount: 0,
        taggedITSIds: [],
        untaggedCount: 0,
        untaggedITSIds: [],
        errorCount: 0,
        errorITSIds: []
    });
    const [itsId,setItsId] = useState();
    const [ITSIds, setITSIds] = useState({
        'ItsIds':[]
    });
    const [successPopup, setSuccessPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [CSVDetails,setCSVDetails] = useState();
    const [CSVIts,setCSVIts] = useState();
    const [disableField,setDisableField] = useState(false);
    const [progressStatus, setProgressStatus] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [idError,setIdError] = useState(false)
    const [skipExisting,setSkipExisting] = useState(false)

    const {CSVReader} = useCSVReader();
    const dispatch = useDispatch();

    const addUserApi = (data) => {
        return new Promise((resolve, reject)=>{
            setLoader(true);
            PostFetch('/api/user/syncByITS', data, "POST")
            .then((response)=>{
                // console.log('resp', response)
                if(response.status === 200) {
                    setAddUserStatus(prev=>({
                        syncedSuccessfulCount: prev?.syncedSuccessfulCount + response.data.syncedSuccessfulCount,
                        taggedCount: prev?.taggedCount + response.data.taggedCount,
                        taggedITSIds: prev?.taggedITSIds.concat(response.data.taggedITSIds),
                        untaggedCount: prev?.untaggedCount + response.data.untaggedCount,
                        untaggedITSIds: prev?.untaggedITSIds.concat(response.data.untaggedITSIds),
                        errorCount: prev?.errorCount + response.data.errorCount,
                        errorITSIds: prev?.errorITSIds.concat(response.data.errorITSIds)
                    }));
                    setSuccessPopup(true);
                    setLoader(false);
                    setITSIds({ItsIds:[]})

                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    setLoader(false);
                    setITSIds({ItsIds:[]})

                }
            }).catch((error)=>{
                console.log('error', error);
                showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
                setITSIds({ItsIds:[]})
                setLoader(false);

            })
        })
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    let i = 0;
    let count = 1 ;
    let limit = 50;
    const addUser = () => {
        let bodyData = {
            ITSIds: ITSIds.ItsIds,
            skipExisting:skipExisting
        }
        if(ITSIds && ITSIds.ItsIds.length > 0 && ITSIds.ItsIds.length <= 50){
            addUserApi(bodyData)
        }
        else{
            const slicedData = ITSIds.ItsIds.slice(i,(count * limit));
            setShowProgressBar(true);
            setProgressStatus(parseInt((i*100)/ITSIds.ItsIds.length));
            let bodyData = {
                ITSIds: slicedData,
                skipExisting:skipExisting
            }
            addUserApi(bodyData).then(()=>{
                count++;
                i= i + 50;
                if(count < (ITSIds.ItsIds.length / limit)){
                    addUser()
                }
            })
        }
    }
    
    const schema = yup
    .object().shape({
      ItsIds: yup.string()
      .required('This field is required')
      .matches(/^[0-9 ,'\n']+$/, "Must be only digits")
    })
    .required("required");
    const { handleSubmit, register, reset,setValue,watch, formState: {errors} } = useForm({
        resolver: yupResolver(schema),
    });
    const submitAction = (data) => {
        setIdError(false)
        {itsId == "" ? setIdError(true) : setIdError(false) }
        setAddUserStatus({
            syncedSuccessfulCount: 0,
            taggedCount: 0,
            taggedITSIds: [],
            untaggedCount: 0,
            untaggedITSIds: [],
            errorCount: 0,
            errorITSIds: []
        });

        let arr =  itsId.split("\n");
        let numArr =[]
        arr && arr.length && arr.map((str,i)=>{
            if(str.length > 8 || str.length < 8 ){
                setIdError(true)
            }
            else if(str.length == 8){
                numArr.push(parseInt(str))
                setIdError(false)
            }
        })
        setITSIds({
            'ItsIds': numArr
        });
    }

    const csvSubmitAction = () => {
        setAddUserStatus({
            syncedSuccessfulCount: 0,
            taggedCount: 0,
            taggedITSIds: [],
            untaggedCount: 0,
            untaggedITSIds: [],
            errorCount: 0,
            errorITSIds: []
        });

        if(CSVIts && CSVIts.length > 1){
            let CsvList = []
            CSVIts && CSVIts.map((item,i)=>{
                if(item.toString() != 'NaN'){
                    if(item.toString().length == 8){
                        CsvList.push(item)
                    }
                }
            })
            // console.log("after",CsvList);
            setITSIds({
                'ItsIds': CsvList
            });
        }
    }

    const downloadCsv = () => {
        const fileName = fileUrl.split('/').pop();
        const aTag = document.createElement('a');
        aTag.href = fileUrl;
        aTag.setAttribute('download', fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    useEffect(()=>{
        if(CSVDetails){
            let arrayToString = function(array){ 
                let str;
                array.map(function(a) {
                    let item = a[0];
                    str = str? str+', '+item : item;
                });
                function removeLastComma(strng){    
                    let n=strng.lastIndexOf(",");
                    let a=strng.substring(0,n);
                    return a;
                }
                str = removeLastComma(str);
                const arr = str.split(',');
                const numArr = arr.map((str)=>{
                    return parseInt(str)
                })
                return numArr
            }
            var str = arrayToString(CSVDetails.data);
            setCSVIts(str);
        }
    }, [CSVDetails])

    useEffect(()=>{
        if(!idError){
            if(ITSIds.ItsIds && ITSIds.ItsIds.length > 0){
             addUser();
             setItsId('');
             setIdError(false)
            }
        }
      
    },[ITSIds.ItsIds]);

    useEffect(()=>{
        if(addUserStatus?.taggedITSIds?.length > 0 || addUserStatus?.untaggedITSIds?.length > 0 || addUserStatus?.errorITSIds?.length > 0){
            showSuccessAlert(`Sucessfully Added ${addUserStatus?.syncedSuccessfulCount} ${addUserStatus?.syncedSuccessfulCount && addUserStatus?.syncedSuccessfulCount > 1 ? 'Users' : 'User'}`)
        }
    },[addUserStatus])

  return (
    <div className='main-wrapper'>
        <div className="container">
            <Row className='justify-content-center'>
                <Col lg={7}>
                    <div className="app-head">
                        <Link to='/dashboard' className='back-btn' style={{color: `${themeColor.primaryColor}`}}><IoIosArrowBack/></Link>
                        <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>Add User</h2>
                    </div>
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit(submitAction)}>
                            <TextareaField fieldPlaceholder={"Enter ITS ID(s)"} 
                            fieldLabel={'ITS ID(s):'} name={'ItsIds'} disable={disableField}
                            error={errors.ItsIds?.message} register={{...register("ItsIds")}} 
                            value={itsId} handleChng={(e)=>setItsId(e.target.value)}/> 
                            {idError ?
                             <h6 className="note" style={{color: "red"}}>ITS Ids should be of 8 digits</h6>
                             :''
                            }
                            <h6 className="note" style={{color: "green"}}>Press Enter After Every ITS ID</h6>
                            <p style={{margin: '30px 0', textAlign: 'center', fontWeight: '500',}}>OR</p>
                            
                            <CSVReader
                            onUploadAccepted={(results) => {
                                setCSVDetails(results);
                                setDisableField(true);
                                setItsId('');
                            }}
                            >
                            {({
                                getRootProps,
                                acceptedFile,
                                ProgressBar,
                                getRemoveFileProps,
                            }) => (
                                <>
                                    <div className='file-wrapper'>
                                        <button type='button' className='uploadBtn' style={{color: `${themeColor.primaryColor}`, backgroundColor: `${themeColor.lightGreen}`}}
                                        {...getRootProps()} ><HiUpload/> Upload CSV File</button>
                                        { !acceptedFile ? setDisableField(false) : setDisableField(true)}
                                        {acceptedFile ? 
                                        <div className='d-flex justify-content-center align-items-center my-4' >
                                            <div className='file-name' style={{color: `${themeColor.secondaryColor}`}}>
                                                {acceptedFile && acceptedFile.name}
                                            </div>
                                            <Button variant='danger' type='button' {...getRemoveFileProps()} >
                                            Remove
                                            </Button>
                                        </div> : '' }
                                        <ProgressBar />
                                    </div>
                                </>
                            )}
                            </CSVReader>
                            <div className="download-btn" style={{color: `${themeColor.primaryColor}`}}>
                                <MdOutlineSimCardDownload/>
                                <button className="link-btn" style={{color: `${themeColor.primaryColor}`}} type='button' onClick={downloadCsv}>Download Sample Csv</button>
                            </div>
                            <Checkbox id='skipExisting' label={"Skip Existing"}  greenCheckbox={true} checked={skipExisting} handleChange={()=>setSkipExisting(!skipExisting)} />
                            <div className="btn-wrapper">
                                <Link to='/dashboard'><Button variant="danger">Cancel</Button></Link>
                                <Button variant="success" type={!disableField ? 'submit' : 'button'} {...(disableField ?  {onClick: csvSubmitAction} : '')} disabled={loader} >Add {
                                loader ? 
                                <div className='loader-wrapper btn-loader'>
                                    <Spinner animation="border" variant="white" />
                                </div>: ''
                            }</Button>
                            </div>
                        </form>
                    </div>
                    {
                        showProgressBar && loader ?
                        <div className="progressBar-wrapper">
                            <ProgressBar animated now={progressStatus} label={`${progressStatus}%`} />
                        </div>: ''
                    }
                    {successPopup && !loader?
                        addUserStatus ? 
                            <div className="status-wrapper" style={{color: `${themeColor.secondaryColor}`}}>
                                <div className="status-container">
                                    {addUserStatus.syncedSuccessfulCount > 0 ?
                                        <div className="details">
                                            <p><b style={{color: `${themeColor.primaryColor}`}}>{addUserStatus.syncedSuccessfulCount}</b> {addUserStatus.syncedSuccessfulCount > 1 ? 'Members' : 'Member'} Successfully Synced</p>
                                        </div>
                                    : ''}
                                    {addUserStatus.untaggedCount > 0 ?
                                        <div className="details">
                                            <p><b style={{color: `${themeColor.primaryColor}`}}>{addUserStatus.untaggedCount}</b> {addUserStatus.untaggedCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Untagged</p>
                                        </div>
                                    : ""}
                                    {addUserStatus.errorCount > 0 ?
                                        <div className="details">
                                            <p><b style={{color: `${themeColor.primaryColor}`}}>{addUserStatus.errorCount}</b> {addUserStatus.errorCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Invalid</p>
                                        </div>
                                    : ""}
                                    {addUserStatus.errorCount === 0 && addUserStatus.untaggedCount === 0 && addUserStatus.syncedSuccessfulCount === 0 ?
                                        <div className="details">
                                            <p>No ITS Id Added</p>
                                        </div>
                                    : ''}
                                </div>
                            </div>
                            : ''
                    : ''
                    }
                </Col>
            </Row>
        </div>
        
        
    </div>
  )
}

export default AddUser