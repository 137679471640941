import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";

export const GetProductsAction = () => async (dispatch) => {
    dispatch({
      type: PRODUCTS_LOADING,
    });
      GetFetch(`/api/Products`)
          .then((response) => {
              var data = response.data;
              if (response.status === 200) {
                  dispatch({
                      type: ALL_PRODUCTS,
                      payload: data,
                  });
              } else {
                  // dispatch({
                  //   type: REQUEST_FAIL,
                  // });
                  dispatch({
                      type: ALERT_ERROR,
                      payload: "Something went wrong. Please try again later.",
                  });
              }
          })
          .catch((error) => {
              dispatch({
                  type: ALERT_ERROR,
                  payload: error.data.msg || "Something went wrong. Please try again later.",
              });
          });
  };

  export const DeleteProductAction = (id) => (dispatch) => {

    PostFetch(`/api/Products/${id}`, id, 'DELETE')
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: DELETE_PRODUCT,
        });
        dispatch(GetProductsAction())
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Product deleted successfully",
        });
      } else {
        dispatch({
          type: PRODUCT_FAIL,
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: PRODUCT_FAIL,
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const AddProductAction = (data, miqaatId,history) => (dispatch) => {
    dispatch({
      type: PRODUCTS_LOADING,
    });
      PostFetch("/api/Products", data, 'POST')
          .then((response) => {
              var data = response.data;
              if (response.status === 200) {
                  dispatch({
                      type: ADD_PRODUCT,
                      payload: {},
                  });
                  dispatch({
                      type: ALERT_SUCCESS,
                      payload: "Product added successfully",
                  });
                  history(`/view-miqaat/${miqaatId}/products`);
              } else {
                  dispatch({
                      type: PRODUCT_FAIL,
                      payload: "",
                  });
                  dispatch({
                      type: ALERT_ERROR,
                      payload: data.error,
                  });
              }
          })
          .catch((error) => {
              dispatch({
                  type: PRODUCT_FAIL,
                  payload: "Something went wrong. Please try again later.",
              });
              dispatch({
                  type: ALERT_ERROR,
                  payload: error.data.msg || "Something went wrong. Please try again later.",
              });
          });
  };

  export const UpdateProductAction = (id, updateDetails, miqaatId, history) => (dispatch) => {
    PostFetch(`/api/Products/${id}`, updateDetails, 'PUT')
    .then((response) => {
      if (response.status === 202) {
        dispatch({
          type: UPDATE_PRODUCT,
          payload: {},
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Product updated successfully",
        });
        history(`/view-miqaat/${miqaatId}/products`);
      } else {
        dispatch({
          type: PRODUCT_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: PRODUCT_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const GetSingleProuctAction = (id) => (dispatch) => {
    dispatch({
      type: PRODUCTS_LOADING,
    });
      GetFetch(`/api/Products/${id}`)
      .then((response) => {
        var SingleProduct = response.data;
        if (response.status === 200) {
          dispatch({
            type: SINGLE_PRODUCT,
            payload: SingleProduct || "",
          });
        } else {
          dispatch({
            type: PRODUCT_FAIL,
            payload: "Something went wrong. Please try again later.",
          });
          dispatch({
            type: ALERT_ERROR,
            payload: "Something went wrong. Please try again later.",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: error.data.msg || "Something went wrong. Please try again later.",
        });
      });
  };

  export const ALL_PRODUCTS = 'ALL_PRODUCTS'
  export const PRODUCTS_LOADING = 'PRODUCTS_LOADING'
  export const PRODUCT_FAIL = 'PRODUCT_FAIL';
  export const DELETE_PRODUCT = 'DELETE_PRODUCT'
  export const ADD_PRODUCT = 'ADD_PRODUCT'
  export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
  export const SINGLE_PRODUCT = 'SINGLE_PRODUCT'