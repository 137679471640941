import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import {MdModeEdit} from 'react-icons/md';
import {MdDeleteOutline} from 'react-icons/md';
import {IoMdAddCircle} from 'react-icons/io';
import { themeColor } from '../../config';
import DataTable from 'react-data-table-component';
import InputField from '../../components/inputField';
import AppModal from '../../components/Modal';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';


const RoleManagement = () => {
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState();
    const [showModal,setShowModal] = useState(false);
    const [rolesDetails,setRolesDetails] = useState();
    const [loader, setLoader] = useState(false);
    const [deleteRoleId,setDeleteRoleId] = useState();
    const [deleteStatus,setDeleteStatus] = useState();

    const dispatch = useDispatch();

    const fetchRoles = () => {
      setLoader(true);
      GetFetch(`/api/Roles`).then((response) => {
          if (response.status == 200){
            setRolesDetails(response.data);
          }
          else {
            showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
          }
          setLoader(false);
      }).catch((err)=>{
          console.log('error', err);
          showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
          setLoader(false);
      })
    }

    const DeleteRole = (id) => {
      return new Promise((resolve, reject)=>{
        PostFetch(`/api/Roles/${id}`,"",'DELETE').then((response) => {
        if (response.status == 200){
            setDeleteStatus(response.data);
            showSuccessAlert("Successfully Deleted");
            resolve();
        }
        else {
            showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            reject();
        }
        setLoader(false);
      }).catch((err)=>{
          console.log('error', err);
          showErrorAlert(err.data.msg ? err.data.msg : "Something Went Wrong");
          setLoader(false);
          reject();
      })
    })
    }

    const columns = [
      {
        name: 'Role Name',
        selector: (row) => row.name
      },
      {
        name: 'Actions',
        cell: (row) => <p className='action-btn-wrapper'><Link className='action-btn' style={{color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}`}} to={`/access-control/role-management/${row.id}`}><MdModeEdit/></Link> <button className='action-btn' style={{color: 'red', background: `${themeColor.lightGreen}`}} onClick={()=>deleteRole(row.id)}><MdDeleteOutline/></button></p>
      },
    ]

    const showErrorAlert = (msg) => {
      dispatch({
        type: ALERT_ERROR,
        payload: msg
      })
    }

    const showSuccessAlert = (msg) => {
      dispatch({
          type: ALERT_SUCCESS,
          payload: msg
      })
    }

    const deleteRole = (id) => {
      setShowModal(true);
      setDeleteRoleId(id);
    }

    const confirDelete = () => {
      setShowModal(false);
      DeleteRole(deleteRoleId).then(()=>{
        fetchRoles();
      })
    }

    useEffect(()=>{
      fetchRoles();
    }, []);

    useEffect(()=>{
      if(rolesDetails?.length > 0){
        setFilteredData(rolesDetails);
      }
    }, [rolesDetails])

    useEffect(()=>{
      const result = rolesDetails?.filter((role)=>{
        return role.name.toLowerCase().match(search.toLowerCase())
      });
      setFilteredData(result);
    }, [search])
  return (
    <div className='main-wrapper'>
        <div className="container">
            <div className="app-head">
                <Link to='/dashboard' className='back-btn' style={{color: `${themeColor.primaryColor}`}}><IoIosArrowBack/></Link>
                <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>ROLE MANAGEMENT</h2>
            </div>
            {loader?
            <div className='loader-wrapper'>
              <Spinner animation="border" variant="success" />
            </div>
            :
            <div className="table-wrapper role-management-table">
              <DataTable data={filteredData} columns={columns}
              fixedHeader fixedHeaderScrollHeight='482px' 
              subHeader pagination
              subHeaderComponent={
                <div>
                  <InputField className={'search-field'} fieldPlaceholder={"Search Role"} fieldType={'text'} value={search}
                    handleChange={(e)=>setSearch(e.target.value)}  />
                    <Link to={'/access-control/create-role'} className='icon-btn dark' style={{background: `${themeColor.primaryColor}`}}><IoMdAddCircle />Add Role</Link>
                </div>} subHeaderAlign="left"/>
            </div>
            }
            
            <AppModal handleClose={()=>setShowModal(false)} showModal={showModal} title={'Confirm Delete'} 
          handleAction={confirDelete} actionBtnText={"Confirm"}/>
        </div>
    </div>
  )
}

export default RoleManagement