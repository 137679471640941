import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { themeColor } from '../../../config'
import InputField from '../../../components/inputField'
import { AddEditDepartmentSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
// import SelectField from '../../../components/SelectField'
// import { miqaatTableData, venueOptions } from '../../../appContent'
import TextareaField from '../../../components/TextareaField'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from "lodash";
import { AddDepartmentAction, GetSingleDepartmentAction, SINGLE_DEPARTMENT, UpdateDepartmentAction } from '../../../store/actions/departmentAction'
import { handleChangeMemberITS } from '../../../utils/helper'

const AddEditDepartment = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [fieldValues, setFieldValues] = useState([])
  const departmentData = useSelector((state) => state.departmentreducer)

  const { handleSubmit, register, reset, formState: { errors }, control, setValue } = useForm({
    resolver: yupResolver(AddEditDepartmentSchema)
  })

  const submitAction = (data) => {
    let newData = {
      miqaatId: params?.id,
      name: data.Name,
      memberITSIDs: handleChangeMemberITS(data)
    }
    if (params?.departmentId) {
      let updateData = { ...newData, id: params?.departmentId }
      dispatch(UpdateDepartmentAction(updateData, params?.id, navigate))
    } else {
      dispatch(AddDepartmentAction(newData, params?.id, navigate))
    }
  }

  useEffect(() => {
    if (params?.departmentId) {
      dispatch(GetSingleDepartmentAction(params?.departmentId))
    }
  }, [params?.departmentId])


  useEffect(() => {
    if (departmentData && departmentData?.singleDepartment) {
      var alertString;
      let memberIts = []
      departmentData?.singleDepartment?.members?.map((data) => {
        memberIts.push(data.itsid)
      })

      let its = memberIts
      for (var i = 0; i < its?.length; i++) {
        if (i == 0) {
          alertString = its[i]
        } else {
          alertString = alertString + '\n' + its[i];
        }
      }
      setValue('Name', departmentData?.singleDepartment?.name)
      setValue('ItsIds', alertString)
    }
  }, [departmentData, params?.id])

  const handleBack = () => {
    dispatch({
      type: SINGLE_DEPARTMENT,
      singleDepartment: {}
    })
  }

  return (
    <div className='main-wrapper'>
      <div className="container">
        <Row className='justify-content-center'>
          <Col lg={7} sm={12}>
            <div className="app-head">
              <Link to={`/view-miqaat/${params?.id}/view-department`} onClick={handleBack} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack /></Link>
              <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>{params?.departmentId ? 'Edit' : 'Add'} Department</h2>
            </div>
            {departmentData?.loading ?
              <div className='loader-wrapper'>
                <Spinner animation="border" variant="succeszs" />
              </div> : <div className="form-wrapper">
                <form onSubmit={handleSubmit(submitAction)}>

                  <InputField fieldPlaceholder={'Enter Department Name'} name={'Name'} fieldType={'text'} fieldLabel={'Department Name:'}
                    error={errors.Name?.message} register={{ ...register('Name') }} />

                  <TextareaField fieldPlaceholder={'Enter ITS ID(s)'}
                    fieldLabel={'Team Member ITS ID(s):'} name={'ItsIds'}
                    error={errors.ItsIds?.message} register={{ ...register('ItsIds') }}
                  />
                  <h6 className="note" style={{ color: "green" }}>Press Enter After Every ITS ID</h6>
                  <div className="btn-wrapper">
                    <Link to={`/view-miqaat/${params?.id}/view-department`} onClick={handleBack}><Button variant="danger">Cancel</Button></Link>
                    <Button variant="success" type='submit' disabled={departmentData.loading} >Save{
                      departmentData.loading
                        ? <div className='loader-wrapper btn-loader'>
                          <Spinner animation="border" variant="white" />
                        </div>
                        : ''
                    }</Button>
                  </div>
                </form>
              </div>}

          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddEditDepartment
