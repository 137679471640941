import React, { useEffect } from 'react';
import {GrClose} from 'react-icons/gr';
import { themeColor } from '../config';

const FilterValues = ({jamiatFilter,jamaatFilter,positionFilter, handleClose, filterType}) => {
    
  return (
    <>
        {jamiatFilter && jamiatFilter.value.length > 0 ? jamiatFilter.value.map((item)=>(
            <span key={item.value} style={{color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}`}}>{item.label}<button className='link-btn' style={{color: `${themeColor.primaryColor}`}} onClick={()=>handleClose(item.value, filterType)}><GrClose/></button></span>
        )) : null}
        {jamaatFilter && jamaatFilter.value.length > 0 ? jamaatFilter.value.map((item)=>(
            <span key={item.value} style={{color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}`}}>{item.label}<button className='link-btn' style={{color: `${themeColor.primaryColor}`}} onClick={()=>handleClose(item.value, filterType)}><GrClose/></button></span>
        )) : null}
        {positionFilter && positionFilter.value.length > 0 ? positionFilter.value.map((item)=>(
            <span key={item.value} style={{color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}`}}>{item.label}<button className='link-btn' style={{color: `${themeColor.primaryColor}`}} onClick={()=>handleClose(item.value, filterType)}><GrClose/></button></span>
        )) : null}
    </>
  )
}

export default FilterValues