import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack, IoMdAddCircle } from 'react-icons/io'
import { MdDeleteOutline, MdModeEdit } from 'react-icons/md'
import { Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from 'react-redux'
import InputField from '../../../components/inputField'
import { InventoryByDateData, InventoryByVenueData, ProductInData} from '../../../appContent'
import { themeColor } from '../../../config'
import AppModal from '../../../components/Modal'
import { dateTimeFormatFunction } from '../../../utils/dateFormat'
import ReactDatePicker from 'react-datepicker'
import SelectField from '../../../components/SelectField'
import { AllVenueAction } from '../../../store/actions/venueAction'

const InventoryByVenue = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const [filteredData, setFilteredData] = useState([])
    const [loader, setLoader] = useState(false)
    const [venueValue, setvenueValue] = useState();
    const [venuesList, SetVenueList] = useState([])

    const venusData = useSelector((state) => state.venuereducer)

    const columns = [
        {
            name: 'Name',
            selector: (row) => row.productName
        },
        {
            name: 'Quantity',
            selector: (row) => row.quantity,
            minWidth: '160px',
            maxWidth: '160px'
        },
    ]

    useEffect(() => {
        if (!isEmpty(get(venusData, 'venusData'))) {
        let newVenue = []
        venusData?.venusData.map((res) => {
            let data = {
            id: res.id,
            label: res.name,
            value: res.id
            }
            newVenue.push(data)
        })
        // newVenue.unshift({ label: "All Select", value: -1 })
        SetVenueList(newVenue)
        }
    }, [get(venusData, 'venusData'), params?.teamId])

    useEffect(() => {
        setLoader(true)
        dispatch(AllVenueAction(params?.id))
    }, [])

    useEffect(()=>{
        if(venuesList && venuesList.length > 0){
            setvenueValue(venuesList[0])
        }
    }, [venuesList])

    useEffect(() => {
        if (venueValue) {
        const result = InventoryByVenueData?.filter((item) => {
            return item.id === venueValue.value
        })
        setFilteredData(result[0]?.products)
        } else {
        setFilteredData([])
        }
    }, [venueValue])


    useEffect(() => {
        if (filteredData?.length > 0) {
        setLoader(false)
        }else{
            setLoader(false)
        }
    }, [filteredData])

    return (
        <div className='main-wrapper'>
            <div className="container">
                <div className="app-head">
                    <Link to={`/view-miqaat/${params.id}/inventory-report`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack/></Link>
                    <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>Inventory By Zone</h2>
                </div>
                {loader
                ? <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
                </div>
                : <div className="table-wrapper">
                    <div style={{borderBottom: '1px solid rgb(204, 204, 204)'}}>
                        <DataTable data={filteredData} columns={columns}
                        fixedHeader fixedHeaderScrollHeight='482px'
                        subHeader
                        subHeaderComponent={
                        <div className='table-header'>
                            <SelectField options={venuesList} name={'product'}
                            placeholder="Select Product" value={venueValue} handleChange={(e)=>setvenueValue(e)} />
                        </div>} subHeaderAlign="left"/>
                    </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default InventoryByVenue
