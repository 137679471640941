export const themeColor = {
    primaryColor: '#155835',
    secondaryColor: '#696969',
    lightGreen: '#F2F6EE',
}

export const baseUrl = 'http://api-staging.nazafat.com/';
export const profileUrl = 'https://hr-nazafat-prod.s3.amazonaws.com/profile-images/';
export const API_KEY = 'AIzaSyCpWSb0knnOMffjRU4X-XK3X-Bk7HFmQeU';
export const editor_secret_key = '+QJjBP6lpRoSwL+e+sAiqgifV2tkxzuy4zqjxoaq';
export const editor_access_key = 'AKIAZFTIHAQ35K4REU57';
