import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack, IoMdAddCircle } from 'react-icons/io'
import { MdModeEdit, MdOutlineTaskAlt, MdDeleteOutline } from 'react-icons/md'
import { GrFormView, GrClose } from 'react-icons/gr'
import { Spinner } from 'react-bootstrap'
import { themeColor } from '../../../config'
import DataTable from 'react-data-table-component'
import InputField from '../../../components/inputField'
import { useDispatch, useSelector } from 'react-redux'
import { AllTaskAction, DeleteTaskAction } from '../../../store/actions/taskAction'
import AppModal from '../../../components/Modal'
// import { taskData } from '../../../appContent'

const ViewTasks = () => {
  const dispatch =  useDispatch()
  const params = useParams()
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [deleteTaskId, setDeleteTaskId] = useState('')
  const [viewTaskId, setViewTaskId] = useState()
  const [viewTaskDetails, setViewTaskDetails] = useState()
  const [openViewTeamPopup, setOpenViewTeamPopup] = useState(false)
  const taskData = useSelector((state) => state.taskreducer)
  // console.log("---taskData", taskData)

  const columns = [
    {
      name: 'Task Name',
      selector: (row) => row?.name
    },
    {
      name: 'Actions',
      cell: (row) => <p className='action-btn-wrapper'>
        {/* <button className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} onClick={() => { setViewTaskId(row.id); setOpenViewTeamPopup(true) }}><GrFormView/>
        </button> */}
         <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat/${params?.id}/tasks/edit/${row.id}`} ><MdModeEdit/></Link>
         <button className='action-btn' style={{ color: 'red', background: `${themeColor.lightGreen}` }} onClick={() => deleteTask(row.id)}><MdDeleteOutline /></button>
        </p>
    }
  ]

  useEffect(()=>{
    if(params?.id){
      dispatch(AllTaskAction(params?.id))
    }
  }, [params])

  useEffect(() => {
    if (taskData?.taskData?.length > 0) {
      setFilteredData(taskData?.taskData)
    } else {
      setFilteredData([])
    }
  }, [taskData])

  useEffect(() => {
    if (search.length > 0) {
      const result = taskData?.taskData?.filter(data => Object.keys(data).some(key => {
        return String(data['name']).toLowerCase().includes(search.toLowerCase())
      }))
      setFilteredData(result)
    } else {
      setFilteredData(taskData.taskData)
    }
  }, [search])

  const deleteTask = (id) =>{
    setDeleteTaskId(id)
    setShowModal(true)
  }

  const confirDelete = () =>{
    let data = {
      id: deleteTaskId
    }
    dispatch(DeleteTaskAction(data, params?.id))
    setShowModal(false)
  }


  return (
    <div className='main-wrapper'>
        <div className="container">
            <div className="app-head">
                <Link to={`/view-miqaat/${params?.id}`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack/></Link>
                <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>VIEW TASKS</h2>
            </div>
            {  taskData && taskData?.loading
              ? <div className='loader-wrapper'>
              <Spinner animation="border" variant="success" />
            </div>
              : <div className="table-wrapper role-management-table">
              <DataTable data={filteredData} columns={columns}
              fixedHeader fixedHeaderScrollHeight='482px'
              subHeader pagination
              subHeaderComponent={
                <div>
                  <InputField className={'search-field'} fieldPlaceholder={'Search Tasks'} fieldType={'text'} value={search}
                    handleChange={(e) => setSearch(e.target.value)} />
                    <Link to={`/view-miqaat/${params?.id}/tasks/add`} className='icon-btn dark' style={{ background: `${themeColor.primaryColor}` }}><IoMdAddCircle />Add Task</Link>
                </div>} subHeaderAlign="left"/>
            </div>
            }

            {openViewTeamPopup && viewTaskDetails && viewTaskDetails.length > 0
              ? <div className="popup-wrapper">
                <div className="popup-container">
                <div className="popup-head">
                    <h4>View Task</h4>
                  </div>
                  <div className="details">
                    <div className="details-block">
                      <h5>Task Name:</h5>
                      <p>{viewTaskDetails[0].name}</p>
                    </div>
                    <div className="details-block">
                      <h5>Task Discription:</h5>
                      <p>{viewTaskDetails[0].desc}</p>
                    </div>
                    <div className="details-block">
                      <h5>Assigned To:</h5>
                      {viewTaskDetails[0].assigned?.map((assign) => (
                        <div className="flex" key={assign.label}>
                          <MdOutlineTaskAlt/>
                          <p>{assign.label}</p>
                        </div>
                      ))}

                    </div>
                  </div>
                  <button className='close-btn' onClick={() => setOpenViewTeamPopup(false)}><GrClose/></button>
                </div>
              </div>
              : ''
            }
            <AppModal handleAction={confirDelete} handleClose={()=>setShowModal(false)} hasBody={true} content='Are you sure, You want to delete Task?' showModal={showModal} title={'Confirm Delete'} actionBtnText={"Confirm"}/>
        </div>
    </div>
  )
}

export default ViewTasks
