import React, { useEffect } from "react";
import DataTable from "react-data-table-component";

const FilterData = ({columnName, keyName, filterType, options, handleFilterChange, data, filterValues, columns, noDataColKey,width, getFilteredData}) => {
    const filteredData = data?.filter((item) => {
        return Object.keys(filterValues).every((columnName) => {
          const filterValue = filterValues[columnName];
          const cellValue = item[columnName];
          if (typeof filterValue === 'undefined' || filterValue === '') {
            return true; 
          }
          
          if (typeof cellValue === 'string' || typeof cellValue === 'number') {
            return cellValue.toString().toLowerCase().includes(filterValue.toString().toLowerCase());
          }
          
          if (Array.isArray(cellValue)) {
            return cellValue.includes(filterValue);
          }

          return false;
        });
      });
      useEffect(() => {
        if (filteredData && filteredData !== undefined){
          getFilteredData(filteredData);
        }
      }, [filterValues]);

    if (filterType === 'select') {
        return (
          <div className="column-header" >
              {columnName}
              <select 
                style={{ marginTop: "12px", width: width }} 
                onClick={(e) => e.stopPropagation()} 
                onChange={(e) => handleFilterChange(keyName, e.target.value)} 
                className="input-field " 
              >
                <option value=''>Select</option>
                {options && options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
        )
       }
       if (filterType === 'text' || filterType === 'number'){
         return (
           <span>
             {columnName}
             <input
               type={filterType === "number" ? "number" : "text"}
               style={{ marginTop: "12px", width: '95%' }}
               name={keyName}
               className="input-field"
               value={keyName && filterValues ? filterValues[keyName] : null}
               onChange={(e) => handleFilterChange(e.target.name, e.target.value)} 
               onClick={(e) => e.stopPropagation()}
             />
           </span>
         );
       }

       return (
        <>
          <DataTable 
          data={filteredData?.length > 0 ? filteredData :  [{[noDataColKey ? noDataColKey : 'itsid']: "No Data Found"}]} 
          columns={columns}
          // fixedHeader 
          // fixedHeaderScrollHeight='482px'
          // subHeader
          // pagination
          subHeaderAlign="left" />
        </>
       )
}

export default FilterData;