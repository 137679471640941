import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText, Grid } from '@mui/material';
import dayjs from "dayjs";
import { capitalize } from 'lodash';
import moment from 'moment';

export default function DatePickerComponent({ options, type, isRequired, handleChange, startDate, width, endDate, endDatePicker, disable, setDisableNext, endDateHandleChange, ...rest }) {





  const parseDateValue = (val) => {
    if (val) {
      const parts = val.split('/');
      if (parts.length === 3) {
        const [day, month, year] = parts;
        const isoDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        return dayjs(isoDate);
      }
      return null;
    } else {
      return null;
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}  >
      <Grid spacing={2}>
        {options?.map((option, i) => {

          return (<><DatePicker
            sx={{
              width: '100%',
              mt: '12px'
            }}
            // defaultValue={dayjs('2022-04-17')}
            label={capitalize(option.placeholder)}
            value={parseDateValue(option.value)}
            onChange={(val) => {
              handleChange(val, i, type)
            }}

            format="DD/MM/YYYY"
          />
            {
              isRequired && option?.required && (!option.value || option.value === 'Invalid date') ? <FormHelperText
                sx={{ color: '#d32f2f' }}
              >
                This field is required
              </FormHelperText> : option.value === 'Invalid date' && <FormHelperText
                sx={{ color: '#d32f2f' }}
              >
                Please enter valid date
              </FormHelperText>
            }</>)
        })}


      </Grid>
    </LocalizationProvider >
  );
}