import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { themeColor } from '../../../config'
import InputField from '../../../components/inputField'
import { AddQuestionSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import SelectField from '../../../components/SelectField'
import { miqaatTableData, qustionSelectOption } from '../../../appContent'
// import TextareaField from '../../../components/TextareaField'
import QuestionOptions from './component/add-question'
import { useDispatch, useSelector } from 'react-redux'
import { AddQuestionAction, AllQuestionAction } from '../../../store/actions/questionAction'
import { useNavigate } from "react-router-dom";
import Checkbox from '../../../components/Checkbox'
import TextareaField from '../../../components/TextareaField'
import { FormHelperText } from '@mui/material'
import { nestedValidation } from '../../../utils/helper'
const AddQuestion = () => {
  const params = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [btnLoader, setBtnLoader] = useState(false)
  const [fieldValues, setFieldValues] = useState()
  const [selectValue, setSelectValue] = useState('')
  const [options, setOptions] = useState([]);
  const [hiden, setHiden] = useState(false);
  const [filter, setFilter] = useState(true);
  const [required, setRequired] = useState(false);
  const questionData = useSelector((state) => state.questionreducer)
  const [maxSelectionValue, setMaxSelectionValue] = useState(0)
  const [disableSubmit, setdisableSubmit] = useState(false)
  const [error, setErrors] = useState('')
  let numberRegex = /^(?!.*[+-])\d+$/;

  const { handleSubmit, register, reset, formState: { errors }, setError, control, setValue, watch } = useForm({
    resolver: yupResolver(AddQuestionSchema)
  })

  const submitAction = (data) => {
    const validate = selectValue?.value !== 'arrival-departure' ? nestedValidation(options, ['placeholder']) : ''
    if (!error && !validate) {
      let addData = {
        miqaatId: params?.id,
        title: data.title,
        type: data.type.value,
        options: selectValue?.value !== 'arrival-departure' ? options
          : [{
            placeholder: 'Arrival Date',
            required: true
          },
          {
            placeholder: 'Departure Date',
            required: true
          }
          ],
        hidden: hiden,
        filter: filter,
        maxSelection: maxSelectionValue,

        priority: data.priority ? parseInt(data.priority) : 0,
        description: data.description,
        required: selectValue && selectValue?.value === 'select' || selectValue?.value === 'radio' || selectValue?.value === 'checkbox' ? required : null
      }
      dispatch(AddQuestionAction(addData, params?.id, navigate))
      setBtnLoader(true)
    }
  }


  useEffect(() => {
    if (params?.id) {
      const id = params.id - 1
      setFieldValues(miqaatTableData[id])
    }
  }, [params])

  useEffect(() => {
    setSelectValue(watch('type'))
  }, [watch('type')])

  const validNumber = () => {
    if (maxSelectionValue < 0 ) {
      return <p className='error-message' style={{ textAlign: 'left', color: '#d32f2f', fontSize: '0.75rem' }} >Must be greater than or equal to 0</p>
    }
  }

  useEffect(() => {
    if (!numberRegex.test(maxSelectionValue)) {
      setdisableSubmit(true)
    } else {
      setdisableSubmit(false)
    }
  }, [maxSelectionValue])
  return (
    <div className='main-wrapper'>
      <div className="container">
        <Row className='justify-content-center'>
          <Col lg={7} sm={12} style={{padding: '0'}}>
            <div className="app-head">
              <Link to={`/view-miqaat/${params?.id}/view-question`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack /></Link>
              <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>{params?.questionId ? 'Edit' : 'Add'} Question</h2>
            </div>
            <div className="form-wrapper">
              <form onSubmit={handleSubmit(submitAction)}>
                <TextareaField fieldPlaceholder={'Question Title'} name={'title'} fieldLabel={'Question Title:'}
                  error={errors.title?.message} register={{ ...register('title') }} rows='2' />
                <TextareaField fieldPlaceholder={'Question Description'} name={'description'} fieldLabel={'Question Description:'}
                  error={errors.description?.message} register={{ ...register('description') }} rows='3' />

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="checkboxWrapper question" style={{ width: '45%', paddingTop: '30px' }}>
                    <Checkbox label={"Hidden"} className={'big'} greenCheckbox={true} checked={hiden} id="hidden" handleChange={() => setHiden(!hiden)} />
                    <Checkbox label={"Filter"} className={'big'} greenCheckbox={true} checked={filter} id="filter" handleChange={() => setFilter(!filter)} />
                    {
                      selectValue && selectValue?.value === 'select' || selectValue?.value === 'radio' || selectValue?.value === 'checkbox' ?
                        <Checkbox label={"Required"} className={'big'} greenCheckbox={true} checked={required} id="required" handleChange={() => {
                          setRequired(!required)
                          // if (!required && maxSelectionValue < 1 && selectValue?.value === 'checkbox') {
                          //   setErrors("Maximum Selection must be greater than 0");
                          //   setRequired(!required)
                          // } else {
                          //   setErrors('')
                          //   setRequired(!required)
                          // }
                        }} />
                        : ''
                    }
                  </div>
                  <div style={{ width: '45%' }}>
                    <InputField fieldPlaceholder={'Priority'} fieldType={'number'}
                      fieldLabel={'Priority'} register={{ ...register("priority") }}
                      error={errors.priority?.message} name={'priority'} />
                  </div>

                </div>
                {watch('type')?.value == 'checkbox' ?
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <SelectField style={{ width: '45%' }} fieldLabel={'Question Type'} options={qustionSelectOption} name={'type'}
                      placeholder={'Question Type'} value={watch('type')} register={{ ...register('type') }}
                      error={errors.type?.message} control={control} />
                    <div item className="option-field" style={{ width: '45%' }}>
                      <InputField
                        fieldPlaceholder={'Enter Maximum Selection'}
                        fieldType={'number'}
                        fieldLabel={'Maximum Selection'}
                        className="question_addeditoption_value"
                        value={maxSelectionValue}
                        minValue={true}
                        handleChange={(e) => {
                          setMaxSelectionValue(e.target.value)
                          // if (required && e.target.value < 1 && selectValue?.value === 'checkbox') {
                          //   setErrors("Maximum Selection must be greater than 0");
                          //   setMaxSelectionValue(e.target.value)
                          // } else {
                          //   setErrors('')
                          //   setMaxSelectionValue(e.target.value)
                          // }
                        }}
                      />
                      {error && <FormHelperText
                        sx={{ color: '#d32f2f' }}
                      >
                        {error}
                      </FormHelperText>}
                      {validNumber()}
                    </div>
                  </div>
                  :
                  <div style={{ marginBottom: '30px' }}>
                    <SelectField fieldLabel={'Question Type'} options={qustionSelectOption} name={'type'}
                      placeholder={'Question Type'} value={watch('type')} register={{ ...register('type') }}
                      error={errors.type?.message} control={control} />
                  </div>
                }

                {
                  selectValue?.value !== 'arrival-departure' &&
                  <QuestionOptions
                    getValues={(value) => {
                      setOptions(value);
                    }}
                    type={selectValue?.value}
                  />
                }

                <div className="btn-wrapper">
                  <Link to={`/view-miqaat/${params?.id}/view-question`}><Button variant="danger">Cancel</Button></Link>
                  <Button variant="success" type='submit' disabled={disableSubmit || questionData.loading} >Save{
                    questionData.loading
                      ? <div className='loader-wrapper btn-loader'>
                        <Spinner animation="border" variant="white" />
                      </div>
                      : ''
                  }</Button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddQuestion
