import { GetFetch } from "../../utils/fetchUrl";
import { ALERT_ERROR } from "../reducers/alert";

export const getAllAttendeesAction = (miqaatId, setLoader) => (dispatch) => {

    GetFetch(`/api/MiqaatReg/GetAttendees?miqaatId=${miqaatId}`)
    .then((response) => {
      var data = response.data;
      setLoader(true)
      if (response.status === 200) {
        dispatch({
            type: ATTENDEES,
            payload: data,
          });
      } else {
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
      setLoader(false)
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: ALERT_ERROR,
        payload: "Something went wrong. Please try again later.",
      });
    });
};

export const ATTENDEES= "ATTENDEES";