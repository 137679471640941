import React from "react";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import SelectField from "./SelectField";
import moment from "moment";
import dayjs from "dayjs";
import { IoMdClose } from "react-icons/io";
import { newDateFormatFunction } from "../utils/dateFormat";

const ColumnFilter = ({ onChange, value, header, index }) => {
  if (
    header.type === "number" ||
    header.type === "text" ||
    header.type === "textArea" ||
    header.type === "slider"
  ) {
    return (
      <span>
        <input
          type={header.type === "number" ? "number" : "text"}
          style={{ marginTop: "12px" }}
          value={value}
          className="input-field"
          onChange={(e) => onChange(e.target.value, header.title, index)}
          onClick={(e) => e.stopPropagation()}
        />
      </span>
    );
  } else if (
    header.type === "select" ||
    header.type === "checkbox" ||
    header.type === "radio"
  ) {
    return (
      // <SelectField
      // options={header.options}
      // handleChange={(e)=>onChange(e.value, header.title, index)}
      // placeholder={'Select to filter'}
      // />
      <span>
        <select style={{ marginTop: "12px" }} onClick={(e) => e.stopPropagation()} onChange={(e) => onChange(e.target.value, header.title, index)} className="input-field " >
          <option value=''>None</option>
          {header.options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </span>
    );
  }
  else if (header.type === "datepicker" || header.type === 'arrival-departure') {
    return <span onClick={(e) => e.stopPropagation()} style={{ marginTop: '12px', display: 'flex' }}>
      <div className="customDatePickerWidth">
        <ReactDatePicker
          className="input-field "
          selected={value ? changeDateFormat(value) : null}
          dateFormat="dd/MM/yyyy"
          style={{ height: "40px" }}
          onChange={(e) => {
            onChange(dateFormatFunction(e), header.title, index)
          }} />
      </div>
      {/* <button className="close-btn" onClick={}><IoMdClose/></button> */}
    </span>
  }
};
export const dateFormatFunction = (date) => {
  if (!date) {
    return '';
  }
  return moment(new Date(date)).format("DD/MM/YYYY")
};
export const changeDateFormat = (date) => {
  if (!date) {
    return '';
  }
  const [day, month, year] = date.split('/');
  const formattedDate = new Date(year, month - 1, day);
  return formattedDate
}
export default ColumnFilter;
