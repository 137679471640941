import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack, IoMdAddCircle } from 'react-icons/io'
import { MdDeleteOutline, MdModeEdit } from 'react-icons/md'
import { Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import InputField from '../../../components/inputField'
import { InventoryByDateData, ProductInData} from '../../../appContent'
import { themeColor } from '../../../config'
import AppModal from '../../../components/Modal'
import { dateTimeFormatFunction } from '../../../utils/dateFormat'
import ReactDatePicker from 'react-datepicker'

const InventoryByDate = () => {
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [loader, setLoader] = useState(false)
  const params = useParams()
  const [showModal, setShowModal] = useState(false)
  const [deleteProductId, setDeleteProductId] = useState('');
  const [startDate, setDate] = useState();

  const columns = [
    {
      name: 'Product Name',
      selector: (row) => row.name
    },
    {
        name: 'Quantity',
        selector: (row) => row.quantity
    }
  ]

  useEffect(() => {
    setLoader(true)
    setDate(new Date())
  }, [])


  const deleteVenue = (id) => {
    setDeleteProductId(id)
    setShowModal(true)
  }
  const confirDelete = () => {
    // let data = {
    //   id: deleteProductId,
    //   miqaatId: params?.id 
    // }
    // dispatch(DeleteVenueAction(data))
    setShowModal(false)
  }

  const handlePrevDate = () => {
    const previousDate = new Date(startDate);
    previousDate.setDate(startDate.getDate() - 1);
    setDate(previousDate);
  }

  const handleNextDate = () => {
    const nextDate = new Date(startDate);
    if(nextDate.toDateString() === new Date().toDateString()){
        nextDate.setDate(startDate.getDate());
        setDate(nextDate);
    }else{
        nextDate.setDate(startDate.getDate() + 1);
        setDate(nextDate);
    }
  }

  useEffect(() => {
    if (search.length > 0) {
      const result = filteredData?.filter((team) => {
        return team.name.toLowerCase().match(search.toLowerCase())
      })
      setFilteredData(result)
    } else {
      setFilteredData(ProductInData)
    }
  }, [search])

  useEffect(()=>{
    if(startDate){
        const data = InventoryByDateData.filter((inventory)=>{
            return new Date(inventory.insertedAt).toDateString() === startDate.toDateString()
        })
        setFilteredData(data[0]?.products);
    }else{
        setFilteredData([])
    }
  }, [startDate])


  useEffect(() => {
    if (filteredData?.length > 0) {
      setLoader(false)
    }else{
        setLoader(false)
    }
  }, [filteredData])

  return (
    <div className='main-wrapper'>
        <div className="container">
            <div className="app-head">
                <Link to={`/view-miqaat/${params.id}/inventory-report`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack/></Link>
                <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>Inventory By Date</h2>
            </div>
            {loader
              ? <div className='loader-wrapper'>
              <Spinner animation="border" variant="success" />
            </div>
              : <div className="table-wrapper">
                <DataTable data={filteredData} columns={columns}
                fixedHeader fixedHeaderScrollHeight='482px'
                subHeader pagination
                subHeaderComponent={
                  <div className='table-header'>
                    <div className="customDatePickerWidth">
                        <i className="fa-solid fa-angle-left" onClick={handlePrevDate}></i>
                        <ReactDatePicker
                        className="input-field "
                        selected={startDate}
                        onChange={(d) => setDate(d)}
                        maxDate={new Date()}
                        todayButton={"Today"}
                        dateFormat="dd/MM/yyyy"
                        />
                        <i className="fa-solid fa-angle-right" onClick={handleNextDate}></i>
                    </div>
                  </div>} subHeaderAlign="left"/>
              </div>
            }

            <AppModal handleClose={()=>setShowModal(false)} showModal={showModal} title={'Confirm Delete'}
          handleAction={confirDelete} actionBtnText={"Confirm"}/>
        </div>
    </div>
  )
}

export default InventoryByDate
