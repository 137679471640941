import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import TextEditor from '../../components/TextEditor';
import SelectField from '../../components/SelectField';
import { themeColor } from '../../config';
import InputField from '../../components/inputField';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { SendEmailByJamaatJamiatSchema } from '../../utils/Schemas';
import { useDispatch, useSelector } from 'react-redux';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import Cookies from 'js-cookie';
import { replaceStringEmail } from '../../utils/helper';

const JamaatJamiat = () => {
    const dashboardData = useSelector((state) => state.login.dashboard);

  const profileD = useSelector((state) => state.login.profileData);
  const [userItsId, setUserItsId] = useState();
  const [jamaats, setJamaats] = useState();
  const [jamiats, setJamiats] = useState();
  const [jamaatDetails, setJamaatDetails] = useState();
  const [jamiatDetails, setJamiatDetails] = useState();
  const [templateDetails, setTemplateDetails] = useState();
  const [templates, setTemplates] = useState();
  const [positionsDetails, setPositionsDetails] = useState();

    const [position, setPosition] = useState();
    const [jamaatId,setJamaatId] = useState([])
    const [positionId,setPositionId] = useState([])
    const [sendSubject,setSendSubject] = useState("")
    const [sendMessege,setSendMessege] = useState("")
    const [allSelectData,setAllSelectDashData] = useState({})
    const [profileData,setProfileData] = useState({})
    const [loader,setLoader] = useState(false);
    const [templateValue, setTemplateValue] = useState();
    const [templateFieldDetails, setTemplateFieldDetails] = useState();

    const dispatch = useDispatch();
    const { handleSubmit, register, watch,reset,setValue, formState: {errors}, control } = useForm({
        resolver: yupResolver(SendEmailByJamaatJamiatSchema),
    });

    useEffect(()=>{
        if(dashboardData && dashboardData.length){
            dashboardData.map((item,i)=>{
                if(item.name == "Communication"){
                    item && item.childrens && item.childrens.map((childItem,i)=>{
                        if(childItem.name == "Jamiat/Jamaat"){
                            setAllSelectDashData(childItem)
                        }
                    })
                }
            })
        }
    },[dashboardData])

    const fetchUserDetails = () => {
        GetFetch(`/api/User/profile`).then((response) => {
            if (response.status == 200){
                setProfileData(response.data);
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                setLoader(false);
            }
            setLoader(false);
        }).catch((err)=>{
            console.log('error', err);
            setLoader(false);
            showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
    })}

    const fetchTemplate = () => {
      GetFetch(`/api/EmailTemplates`).then((response) => {
          if (response.status == 200){
              setTemplateDetails(response.data);
          }
      }).catch((err)=>{
          console.log('error', err);
      })
    }

    const fetchJamaat = () => {   
      GetFetch(`/api/Jamaat`).then((response) => {
          if (response.status == 200){
              setJamaatDetails(response.data);
          }

      }).catch((err)=>{
          console.log('error', err);
      })
    }
    const fetchJamiat = () => {
      GetFetch(`/api/Jamiat`).then((response) => {
          if (response.status == 200){
              setJamiatDetails(response.data);
          }
          
      }).catch((err)=>{
          console.log('error', err);
      })
    }
    const fetchPositions = () => {
        GetFetch(`/api/Positions`).then((response) => {
            if (response.status == 200){
                setPositionsDetails(response.data);
            }
            
        }).catch((err)=>{
            console.log('error', err);
        })
      }

    const resetData =()=>{
        reset();
        setValue("Jamiat",null)
        setValue("Jamaat",null)
        setValue("Position",null)
        setTemplateValue('');
    }

    const submitAction = (data) => {
        if(data?.Subject.charAt(0) === " "){
            showErrorAlert("Subject should not start with space");
        }
        else{
            setLoader(true);
            let body = {
                "jamaatIds":jamaatId,
                "positionIds":positionId,
                "subject":sendSubject,
                "htmlBody": replaceStringEmail(sendMessege)
            }
            PostFetch('/api/Communications/sendEmailByJamaat',body,"POST").then((response)=>{
                if (response.status == 200){
                    showSuccessAlert('Successfull');
                    resetData();
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                }
                setLoader(false);
            }).catch((err)=>{
                showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
                setLoader(false);
            })
            resetData();
        }
        
    }
    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    useEffect(()=>{
        if(userItsId){
            fetchUserDetails();
        }
    },[userItsId])

    useEffect(()=>{
      setUserItsId(Cookies.get('userItsId'));
      fetchJamaat();
      fetchJamiat();
      fetchPositions();
      fetchTemplate();
    }, [])

    useEffect(()=>{
        if(positionsDetails){
            let dataPosition = []
            positionsDetails.map((position)=>(
                dataPosition.push({value: position.id, label: position.name})
            ))
            dataPosition.unshift({value: -1, label:"All Select"})
            setPosition(dataPosition)
        }
    }, [positionsDetails])

    useEffect(()=>{
        if(templateValue){
            GetFetch(`/api/EmailTemplates/${templateValue.value}`).then((response) => {
                if (response.status == 200){
                    setTemplateFieldDetails(response.data);
                }
            }).catch((err)=>{
                console.log('error', err);
            })
        }
    }, [templateValue])

    useEffect(()=>{
        if(templateFieldDetails){
            setValue('Subject', templateFieldDetails.subject);
            setValue('Message', templateFieldDetails.htmlBody);
        }
    }, [templateFieldDetails])

  useEffect(()=>{
      if(templateDetails){
          setTemplates(
              templateDetails.map((template)=>(
                  {value: template.id, label: template.name}
              ))
          )
      }
  }, [templateDetails])

  useEffect(()=>{
      if(jamaatDetails){
        let dataJamaat = []
        if(allSelectData.jamiatPermission == "All"){
            jamaatDetails.map((jamaat)=>(
                dataJamaat.push({value: jamaat.jamaatId, label: jamaat.name})
            ))
            dataJamaat.unshift({value: -1, label:"All Select"})
        }else if(allSelectData.jamiatPermission == "Own"){
            if(profileData && profileData.jamiat){
                jamaatDetails.map((jamiat,i)=>{
                    if(jamiat.jamaatId ==profileData.jamaatId ){
                        dataJamaat.push({value: jamiat.jamaatId, label: jamiat.name})
                        setValue("Jamaat",{value: jamiat.jamaatId, label: jamiat.name})
                    }
                })
            }
        }
        else if(allSelectData.jamiatPermission == "Selected"){
            let jamiatArray = allSelectData.jamiatIds
                jamiatArray && jamiatArray.map((id,i)=>{
                    jamaatDetails && jamaatDetails.map((jamaat,i)=>{
                        if(jamaat.jamiatId == id ){
                            dataJamaat.push({value: jamaat.jamaatId, label: jamaat.name})
                        }
                    })
                })
        }
        let data = watch("Jamiat")
        if(data == undefined || data == []){
            setJamaats(undefined)
        }else{
            setJamaats(dataJamaat)
        }
      }
  }, [jamaatDetails,profileData])

  useEffect(()=>{
    if(jamiatDetails){
        let dataJamiat = []
        if(allSelectData.jamiatPermission == "All"){
            jamiatDetails.map((jamiat)=>(
                dataJamiat.push({value: jamiat.jamiatId, label: jamiat.name})
            ))
            dataJamiat.unshift({value: -1, label: "All Select"})
        }
        else if(allSelectData.jamiatPermission == "Own"){
            if(profileData && profileData.jamiat){
                    jamiatDetails.map((jamiat,i)=>{
                        if(jamiat.name ==profileData.jamiat ){
                            dataJamiat.push({value: jamiat.jamiatId, label: jamiat.name})
                            setValue("Jamiat",{value: jamiat.jamiatId, label: jamiat.name})
                        }
                    })
            }
        }
        else if(allSelectData.jamiatPermission == "Selected"){
            let jamiatArray = allSelectData.jamiatIds
            jamiatArray && jamiatArray.map((id,i)=>{
                jamiatDetails && jamiatDetails.map((jamiat)=>{
                    if(jamiat.jamiatId == id ){
                        dataJamiat.push({value: jamiat.jamiatId, label: jamiat.name})
                    }
                })
                
            })
            dataJamiat.unshift({value: -1, label: "All Select"})
        }
        setJamiats(dataJamiat)        
    }
    }, [jamiatDetails,profileData])

    useEffect(()=>{
        let data = watch("Jamiat")
        if(data == undefined || data == []){
            setJamaats(undefined)
        }

        if(data && data.value != -1){
            setValue('Jamaat',null)
        }
        if( data && data.length > 0 ){
            let filteredJamaats = []
            if(allSelectData.jamiatPermission == "Selected"){
                let jamaatIds = []
                data && data.map((data,i)=>{
                    if(data.value == -1){
                        jamaats && jamaats.map((jamaats,i)=>{
                            jamaatIds.push(jamaats.value)
                        })
                    }
                })
                setJamaatId(jamaatIds)
            }
            filteredJamaats.unshift({value: -1, label:"All Select"})
            data.map((filterJ)=>{
                jamaatDetails.map((item)=>{
                    if(filterJ.label == item.jamiat){
                    filteredJamaats.push({value: item.jamaatId, label: item.name})
                }
                })   
                if(filterJ.value == -1){
                    setValue("Jamiat",filterJ)
                }
            })
            {data == undefined || data == [] ? setJamaats(undefined): setJamaats(filteredJamaats)}     
        }
        else if(jamaatDetails){
            let dataJamaat = []
            if(allSelectData.jamiatPermission == "Selected"){
                let jamiatArray = allSelectData.jamiatIds
                jamiatArray && jamiatArray.map((id,i)=>{
                    jamaatDetails && jamaatDetails.map((jamaat,i)=>{
                        if(jamaat.jamiatId == id ){
                            dataJamaat.push({value: jamaat.jamaatId, label: jamaat.name})
                        }
                    })
                })
            }else{
                jamaatDetails.map((jamaat)=>(
                    dataJamaat.push({value: jamaat.jamaatId, label: jamaat.name})
                ))
                if(allSelectData.jamaatPermission == "All"){
                    dataJamaat.unshift({value: -1, label: "All Select"})
                }
            }
            if(data && data.length){
                setJamaats(dataJamaat)
            }else if(data && data.value == -1){
                setJamaats(dataJamaat)
            }
            else{
                setJamaats(undefined)
            }
        }
    }, [watch("Jamiat")])

  useEffect(()=>{
        let data = watch("Jamaat")
        if(data && data.value == -1){
            if(allSelectData.jamiatPermission == "Selected"){
                let watchJamiat = watch("Jamiat")
                if(watchJamiat && watchJamiat.value == -1){
                    setJamaatId(jamaatId)  
                }else{
                    let allId = []
                    jamaats && jamaats.map((id,i)=>{
                        if(id.value != -1){
                            allId.push(id.value)
                        }
                    })
                    setJamaatId(allId) 
                }
            }else{
                let watchJamiat = watch("Jamiat")
                if(watchJamiat && watchJamiat.value == -1){
                    setJamaatId([-1])  
                }else{
                    let allId = []
                    jamaats && jamaats.map((id,i)=>{
                        if(id.value != -1){
                            allId.push(id.value)
                        }
                    })
                    setJamaatId(allId) 
                }
            }
        }else{
            if(data && !data.length){
                setValue("Jamaat",null)
            }
            let Id = []
            data && data.length > 0 && data.map((item,i)=>{
                Id.push(item.value)
                if(item.value == -1){
                    setValue("Jamaat",item)
                }
                setJamaatId(Id)
        })
        }
    }, [watch("Jamaat")])

  useEffect(()=>{
        let data = watch("Position")
        let Id = []
        if(data && data.length > 0){
            data.map((item,i)=>{
                Id.push(item.value)
                if(item.value == -1){
                    setValue("Position",item) 
                }
            })
        }
            if(data && data.value == -1){
                Id.push(data.value)
            }
            setPositionId(Id)
    }, [watch("Position")])
    
    useEffect(()=>{
        let dataSubject = watch("Subject")
        setSendSubject(dataSubject)
        let dataBody = watch("Message")
        setSendMessege(dataBody)
    },[watch("Subject"),watch("Message")])

  return (
    <div className='main-wrapper'>
        <div className="container">
            <Row className='justify-content-center'>
                <Col lg={7} sm={12}>
                <div className="app-head">
                    <Link to='/dashboard' className='back-btn' style={{color: `${themeColor.primaryColor}`}}><IoIosArrowBack/></Link>
                    <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>JAMIAT/JAMAAT(s)</h2>
                </div>
                <div className="form-wrapper">
                  <form onSubmit={handleSubmit(submitAction)}>
                    <SelectField isMulti={true} fieldLabel={'Jamiat:'} options={jamiats} name={`Jamiat`}
                      placeholder={'Select Jamiat'} register={{...register("Jamiat")}}
                      error={errors.Jamiat?.message} control={control} />
                      
                    <SelectField isMulti={true} fieldLabel={'Jamaat:'} options={jamaats} name={'Jamaat'}
                      placeholder={'Select Jamaat'} register={{...register("Jamaat")}} 
                      error={errors.Jamaat?.message} control={control} />
 
                    <SelectField isMulti={true} fieldLabel={'Position:'} options={position} name={'Position'}
                    placeholder={'Select Position'} register={{...register("Position")}}
                    error={errors.Position?.message} control={control} />

                    <SelectField fieldLabel={'Template:'} options={templates} name={'Template'}
                    placeholder={'Select Options'} value={templateValue} handleChange={(e)=>setTemplateValue(e)} />

                    <InputField fieldPlaceholder={'Enter Subject'} fieldType={'text'}
                    fieldLabel={'Subject:'} register={{...register("Subject")}}
                    error={errors.Subject?.message} name={'Subject'}  />

                    <TextEditor placeholder={'Enter Message'} name={'Message'}
                    label={'Message:'} register={{...register("Message")}}
                    error={errors.Message?.message} control={control} />
                    
                    <div className="btn-wrapper">
                      <Link to='/dashboard'><Button variant="danger">Cancel</Button></Link>
                      <Button variant="success" type='submit' disabled={loader} >Send{
                        loader ? 
                        <div className='loader-wrapper btn-loader'>
                            <Spinner animation="border" variant="white" />
                        </div>: ''
                    }</Button>
                    </div>
                  </form>
                </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default JamaatJamiat