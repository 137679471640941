import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { Spinner } from 'react-bootstrap'
import { themeColor } from '../../../config'
import placeholderUserImg from "../../../assets/images/profile.png";
import '../../style.css'
import { TbFileExport } from 'react-icons/tb';
import { FiPrinter } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print'
import FilterData from '../../../components/FilterData'
import { getAllAttendeesAction } from '../../../store/actions/attendees'
import { useDispatch, useSelector } from 'react-redux'
import { GetTeamAndVenuesAction } from '../../../store/actions/miqaatAction'
import { exportExcelFile } from '../../../components/ExportExcelFile'


const KhidmatguzarOnDuty = () => {
  const params = useParams()
  const printRef = useRef();
  const dispatch = useDispatch()
  const [filteredData, setFilteredData] = useState([])
  const [loader, setLoader] = useState(true)
  const [filterValues, setFilterValue] = useState({});
  const [isDisabledClearBtn, setIsDisabledClearBtn] = useState(true)
  const [allAttendees, setAllAttendees] = useState([])
  const attendeesData = useSelector((state) => state.attendeesReducer.attendees)
  const dropDownOptions = useSelector((state) => state.miqaatreducer.dropdownOptions)

  const excelColumns = [
    { header: 'Photo', key: 'photo', width: 10 },
    { header: 'ITS ID', key: 'itsid', width: 14 },
    { header: 'Full Name', key: 'fullName', width: 45 },
    { header: 'Designantion', key: 'designantion', width: 25 },
    { header: 'Mobile', key: 'mobNo', width: 20 },
    { header: 'Whatsapp No.', key: 'whatsAppNo', width: 20 },
    { header: 'Venue', key: 'venue', width: 30 },
    { header: 'Team', key: 'teams', width: 35 },
  ]

  useEffect(() => {
    if (params?.id) {
      dispatch(getAllAttendeesAction(params?.id, setLoader))
      dispatch(GetTeamAndVenuesAction(params?.id, setLoader))
    }
  }, [params])

  useEffect(() => {
    setAllAttendees(attendeesData)
  }, [attendeesData])


  const handleFilterChange = (keyName, value) => {
    setFilterValue({
      ...filterValues,
      [keyName]: value,
    });
  };

  const columns = [
    {
      name: 'Photo',
      cell: (row) => <img className='user-profile' src={row.photo ? `data:image/png;base64,${row.photo}` : placeholderUserImg} />,
      minWidth: '90px',
      maxWidth: '90px'
    },
    {
      name: <FilterData columnName='ITS ID' keyName='itsid' filterType='number' handleFilterChange={handleFilterChange} />,
      selector: (row) => row.itsid ? row.itsid : ' ',
      minWidth: '140px',
      maxWidth: '160px',
      sortable: true
    },
    {
      name: <FilterData columnName='Full Name' keyName='fullName' filterType='text' handleFilterChange={handleFilterChange} />,
      selector: (row) => row.fullName ? row.fullName : ' ',
      minWidth: '350px',
      maxWidth: '400px',
      sortable: true
    },
    {
      name: <FilterData columnName='Designation' keyName='designation' filterType='text' handleFilterChange={handleFilterChange} />,
      selector: (row) => row.designation ? row.designation : ' ',
      minWidth: '180px',
      maxWidth: '200px',
    },
    {
      name: <FilterData columnName='Mobile no.' keyName='mobNo' filterType='number' handleFilterChange={handleFilterChange} />,
      selector: (row) => row.mobNo ? row.mobNo : ' ',
      minWidth: '180px',
      maxWidth: '200px',
    },
    {
      name: <FilterData columnName='Whatsapp no.' keyName='whatsAppNo' filterType='number' handleFilterChange={handleFilterChange} />,
      selector: (row) => row.whatsAppNo ? row.whatsAppNo : ' ',
      minWidth: '180px',
      maxWidth: '200px',
    },
    {
      name: <FilterData columnName='Zone' keyName='venues' filterType='select' handleFilterChange={handleFilterChange} options={dropDownOptions.venues} width='90%' />,
      selector: (row) => row.venues ? row.venues.join(', ') : ' ',
      minWidth: '250px',
      maxWidth: '300px',
    },
    {
      name: <FilterData columnName='Team' keyName='teams' filterType='select' handleFilterChange={handleFilterChange} options={dropDownOptions.teams} width='90%' />,
      selector: (row) => row.teams ? row.teams.join(', ') : ' ',
      minWidth: '250px',
      maxWidth: '300px',
    },
  ]

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleClearFilter = () => {
    setFilterValue({})
    setIsDisabledClearBtn(true)
    document.querySelectorAll('input').forEach((input) => (input.value = null));
    document.querySelectorAll('select').forEach((select) => (select.value = ''));
  }

  const getFilteredData = (filterData) => {
    setFilteredData(filterData)
  }

  useEffect(() => {
    const isFilterEmpty = Object.keys(filterValues).every(key => filterValues[key].trim() === "");
    setIsDisabledClearBtn(isFilterEmpty);
  }, [filterValues]);
  
  return (
    <div className='main-wrapper'>
       <style>
        {`@media print {
  @page {
    margin: 15px;
    size: landscape; / Set page size to landscape /
  }
.input-field {
    display: none;
  }
  // .rdt_TableCell div:first-child {
  //     min-width: 100px;
  //     max-width: 400px;
  //     width: 100px;
  //   }
    .rdt_TableCell{
      min-width: 100px;
      max-width: 150px;
      padding: 6px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12)
    }
    .rdt_TableRow{
      border: none !important;
      // page-break-inside: avoid;
      page-break-after: always;
    }
    .rdt_TableCol{
      min-width: 100px;
      max-width: 150px;
    }
    img {
      height: 50px;
      width: 50px
    }
    // .print-page{
    //   page-break-inside: avoid;
    // }
   }`
        }
      </style>
      <div className="container">
        <div className="app-head">
          <Link to={`/view-miqaat/${params?.id}`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack /></Link>
          <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>On Duty Khidmatguzar</h2>
        </div>
        {loader
          ? <div className='loader-wrapper'>
            <Spinner animation="border" variant="success" />
          </div>
          : <div className="table-wrapper miqaat attandees">
            <div style={{borderBottom: '1px solid #cccccc'}}>
                <div style={{ display: 'flex', justifyContent: 'end', margin: '10px' }}>
                  <button className='icon-btn dark' disabled={isDisabledClearBtn} style={{ backgroundColor: isDisabledClearBtn ? '#f5f5f5' : `${themeColor.primaryColor}`, color: isDisabledClearBtn ? '#070707' : '' }} onClick={handleClearFilter}>Clear Filter</button>
                  <button className='icon-btn' style={{ color: `${themeColor.primaryColor}` }} onClick={handlePrint}> <FiPrinter />Print</button>
                  <button className='icon-btn dark' style={{ backgroundColor: `${themeColor.primaryColor}` }} onClick={() => exportExcelFile(filteredData, excelColumns, 'khidmatGuzar')}> <TbFileExport />Download</button>
                </div>
            <div className='print-page' ref={printRef}>
              <FilterData
                data={allAttendees ? allAttendees : []}
                columns={columns}
                filterValues={filterValues}
                getFilteredData={getFilteredData}
              />
            </div>
          </div>
          <div className='table-footer'>
            <p className="note">To scroll the report horizontally, press the Shift key and scroll using your mouse's scroll wheel.</p>
            <span>rows: {filteredData.length}</span>
          </div>
          </div>
        }
      </div>
    </div>
  )
}

export default KhidmatguzarOnDuty
