import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";
// import { CANCEL_EDIT_FORM } from "../reducers/questions";

export const AllTaskAction = (miqaatId) => async (dispatch) => {
  dispatch({
    type: TASK_LOADING,
  });
    GetFetch(`/api/Tasks/get?miqaatId=${miqaatId}`)
        .then((response) => {
            var data = response.data;
            if (response.status === 200) {
                dispatch({
                    type: ALL_TASKS,
                    payload: data,
                });
            } else {
                // dispatch({
                //   type: REQUEST_FAIL,
                // });
                dispatch({
                    type: ALERT_ERROR,
                    payload: "Something went wrong. Please try again later.",
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: ALERT_ERROR,
                payload: error.data.msg || "Something went wrong. Please try again later.",
            });
        });
};

export const AddTaskAction = (addTaskDetails, miqaatId, history) => (dispatch) => {
    dispatch({
      type: TASK_LOADING,
    });
      PostFetch("/api/Tasks/add", addTaskDetails, 'POST')
          .then((response) => {
              var data = response.data;
              if (response.status === 200) {
                  dispatch({
                      type: ADD_TASK,
                      payload: {},
                  });
                  dispatch({
                      type: ALERT_SUCCESS,
                      payload: "Task added successfully",
                  });
                  history(`/view-miqaat/${miqaatId}/tasks`);
              } else {
                  dispatch({
                      type: TASK_FAIL,
                      payload: "",
                  });
                  dispatch({
                      type: ALERT_ERROR,
                      payload: data.error,
                  });
              }
          })
          .catch((error) => {
              dispatch({
                  type: TASK_FAIL,
                  payload: "Something went wrong. Please try again later.",
              });
              dispatch({
                  type: ALERT_ERROR,
                  payload: error.data.msg || "Something went wrong. Please try again later.",
              });
          });
  };
  
  export const GetSingleTaskAction = (venueId) => (dispatch) => {
    dispatch({
      type: TASK_LOADING,
    });
      GetFetch(`/api/tasks/getById?id=${venueId}`)
      .then((response) => {
        var SingleVenue = response.data;
        if (response.status === 200) {
          dispatch({
            type: SINGLE_TASK,
            payload: SingleVenue || "",
          });
        } else {
          dispatch({
            type: TASK_FAIL,
            payload: "Something went wrong. Please try again later.",
          });
          dispatch({
            type: ALERT_ERROR,
            payload: "Something went wrong. Please try again later.",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: TASK_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: error.data.msg || "Something went wrong. Please try again later.",
        });
      });
  };

  export const UpdateTaskAction = (updateDetails, miqaatId, history) => (dispatch) => {
    PostFetch('/api/tasks/edit', updateDetails, 'PUT')
    .then((response) => {
      if (response.status === 202) {
        dispatch({
          type: UPDATE_TASK,
          payload: {},
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Task updated successfully",
        });
        dispatch({
            type: SINGLE_TASK,
            payload: {}
        })
        history(`/view-miqaat/${miqaatId}/tasks`);
      } else {
        dispatch({
          type: TASK_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: TASK_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const DeleteTaskAction = (id, miqaatId) => (dispatch) => {

    PostFetch('/api/tasks/delete', id, 'DELETE')
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: DELETE_TASK,
        });
        dispatch(AllTaskAction(miqaatId))
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Task deleted successfully",
        });
      } else {
        dispatch({
          type: TASK_FAIL,
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: TASK_FAIL,
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};
  

export const TASK_LOADING = "TASK_LOADING";
export const ALL_TASKS = "ALL_TASKS";
export const TASK_ERROR = "TASK_ERROR";
export const TASK_FAIL = "TASK_FAIL";
export const ADD_TASK = "ADD_TASK";    
export const DELETE_TASK = "DELETE_TASK";
export const SINGLE_TASK = "SINGLE_TASK";
export const UPDATE_TASK = "UPDATE_TASK";