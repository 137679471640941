import { ATTENDEES } from "../actions/attendees";

  const initialState = {
    attendees: [],
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
  
      case ATTENDEES:
        return {
          ...state,
          attendees: action.payload,
        };
        default: {
            return state;
      }
    }
  };
  