import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { TbWorld } from 'react-icons/tb'
import { themeColor } from '../../../config'

import InputField from '../../../components/inputField'
import { AddEditVenueSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { set, useForm } from 'react-hook-form'
import SelectField from '../../../components/SelectField'
import { venueData, VenueAssignOptions } from '../../../appContent'
import { useDispatch, useSelector } from 'react-redux'
import { AddVenueAction, GetSingleVenueAction, SINGLE_VENUE, UpdateVenueAction } from '../../../store/actions/venueAction'

const AddEditVenue = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [btnLoader, setBtnLoader] = useState(false)
  const [venueDetails, setVenueDetails] = useState({
    address: '',
    city: '',
    state: '',
    zip: ''
  })
  const [cordinate, setCordinate] = useState();
  const singleVenue = useSelector((state) => state.venuereducer)

  const { handleSubmit, register, reset, watch, formState: { errors }, control, setValue } = useForm({
    resolver: yupResolver(AddEditVenueSchema)
  })

  const submitAction = (data) => {
    let newData = {
      miqaatId: params?.id,
      name: data.name,
      Latitude: data.latitude,
      Longitude: data.longitude,
      locationDescr: data.locationDescription
    }
    if (params?.venueId) {
      let data = {
        ...newData,
        id: params?.venueId
      }
      dispatch(UpdateVenueAction(data, params?.id, navigate))

    } else {
      dispatch(AddVenueAction(newData, params?.id, navigate))
    }
  }

  useEffect(() => {
    if (params?.venueId) {
      // setLoader(true)
      dispatch(GetSingleVenueAction(params?.venueId))
    }
  }, [params?.venueId])

  useEffect(() => {
    if (singleVenue && singleVenue.singleVenue) {
      setValue('name', singleVenue?.singleVenue?.name)
      setValue('longitude', singleVenue?.singleVenue?.longitude)
      setValue('latitude', singleVenue?.singleVenue?.latitude)
      setValue('locationDescription', singleVenue?.singleVenue?.locationDescr)
      if (params?.venueId == singleVenue?.singleVenue?.id) {
        // setLoader(false)
      }
    }
  }, [singleVenue])

  const handleBack = () => {
    dispatch({
      type: SINGLE_VENUE,
      singleVenue: {}
    })
  }

  const getLatLong = async () => {
    const url = "https://www.gps-coordinates.net/";
    window.open(url, '_blank');
  }

  useEffect(() => {
    let venueAddress = watch('VenueAddress');
    let venueCity = watch('city');
    let venueState = watch('state');
    let venueZip = watch('zip');
    setVenueDetails({
      address: venueAddress,
      city: venueCity,
      state: venueState,
      zip: venueZip
    })
  }, [watch('VenueAddress'), watch('city'), watch('state'), watch('zip')])

  useEffect(() => {
    if (cordinate) {
      setValue('latLong', `${cordinate.latitude.toFixed(2)}, ${cordinate.longitude.toFixed(2)}`)
    }
  }, [cordinate])

  return (
    <div className='main-wrapper'>
      <div className="container">
        <Row className='justify-content-center'>
          <Col lg={7} sm={12}>
            <div className="app-head">
              <Link to={`/view-miqaat/${params?.id}/zone`} onClick={handleBack} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack /></Link>
              <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>{params?.venueId ? 'Edit' : 'Add'} ZONE</h2>
            </div>
            {singleVenue.loading ?
              <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
              </div> :
              <div className="form-wrapper">
                <form onSubmit={handleSubmit(submitAction)}>
                  <InputField fieldPlaceholder={'Enter Zone Name'} name={'name'} fieldType={'text'} fieldLabel={'Zone Name:'}
                    error={errors.name?.message} register={{ ...register('name') }} />
                  <InputField fieldPlaceholder={'Enter Location Description'} name={'locationDescription'} fieldType={'text'} fieldLabel={'Location Description:'}
                    error={errors.locationDescription?.message} register={{ ...register('locationDescription') }} />

                  {/* <div className='venue-field-wrapper text-area'>
                                  <TextareaField fieldPlaceholder={'Enter Venue Address'}
                                  fieldLabel={'Venue Address:'} name={'VenueAddress'}
                                  error={errors.VenueAddress?.message} register={{ ...register('VenueAddress') }} rows={3} />

                                  <InputField fieldPlaceholder={'Enter City'} name={'city'} fieldType={'text'} fieldLabel={'City:'}
                                  error={errors.city?.message} register={{ ...register('city') }} />
                                </div> */}

                  <div className='venue-field-wrapper'>
                    {/* <InputField fieldPlaceholder={'Enter State'} name={'state'} fieldType={'text'} fieldLabel={'State:'}
                                  error={errors.state?.message} register={{ ...register('state') }} />

                                  <InputField fieldPlaceholder={'Enter Zip'} name={'zip'} fieldType={'text'} fieldLabel={'Zip:'}
                                  error={errors.zip?.message} register={{ ...register('zip') }} /> */}

                    <InputField name={'latitude'} fieldType={'text'} fieldLabel={'Latitude:'}
                      error={errors.latitude?.message} register={{ ...register('latitude') }} />

                    <InputField name={'longitude'} fieldType={'text'} fieldLabel={'Longitude:'}
                      error={errors.longitude?.message} register={{ ...register('longitude') }} />
                    <button className='icon-btn dark' type='button' style={{ backgroundColor: `${themeColor.primaryColor}`}} onClick={getLatLong}><TbWorld />Get Latitude Longitude</button>
                  </div>

                  {/* <SelectField isMulti={true} fieldLabel={'Assigned To Team:'} options={VenueAssignOptions} name={'Assigned'}
                    placeholder={'Assigned To Team'} register={{ ...register('Assigned') }}
                    error={errors.Assigned?.message} control={control} /> */}

                  <div className="btn-wrapper">
                    <Link to={`/view-miqaat/${params?.id}/venue`} onClick={handleBack}><Button variant="danger">Cancel</Button></Link>
                    <Button variant="success" type='submit' disabled={btnLoader} >Save{
                      btnLoader
                        ? <div className='loader-wrapper btn-loader'>
                          <Spinner animation="border" variant="white" />
                        </div>
                        : ''
                    }</Button>
                  </div>
                </form>
              </div>}

          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddEditVenue
