import React from 'react';
// import InputField from '../../../components/inputField';

// import TextareaField from '../../../components/TextareaField';
import RadioButtonInput from './input/radioButton';
import CheckBoxInput from './input/checkbox';
import SliderInput from './input/slider';
import SelectComponent from './input/selectField';
// import InputField from './input/textField';
import DatePickerComponent from './input/datePicker';
import TextAreaComponent from './input/textArea';
import InputField from './input/textField';
import ArrivalDeparture from './input/arrival-departure';

const QuestionInputs = ({ type, options, onInputChange, value, setDisableNext,maxLimit, singleMiqaat, isRequired, otherFieldValue, id, setOtherFieldValue }) => {

    return (
        <>
            {type === 'radio' ? <RadioButtonInput singleMiqaat={singleMiqaat} otherFieldValue={otherFieldValue} setOtherFieldValue={setOtherFieldValue} options={options} handleChange={onInputChange} id={id} value={value} type={type} isRequired={isRequired} /> : null}

            {type === 'checkbox' ?
                <>
                    <CheckBoxInput singleMiqaat={singleMiqaat} maxLimit={maxLimit} options={options} otherFieldValue={otherFieldValue} setOtherFieldValue={setOtherFieldValue} id={id} handleChange={onInputChange} type={type} value={value} isRequired={isRequired} />
                </>
                : null}

            {type === 'text' ? <InputField options={options} handleChange={onInputChange} value={value} type={type} isRequired={isRequired} /> : null}

            {type === 'number' ? <InputField options={options} handleChange={onInputChange} value={value} type={type} isRequired={isRequired} /> : null}

            {type === 'select' ? <SelectComponent options={options} handleChange={(val) => onInputChange(val, '', type)} value={value} isRequired={isRequired} /> : null}

            {type === 'textarea' ? <TextAreaComponent options={options} handleChange={onInputChange} value={value} type={type} isRequired={isRequired} /> : null}

            {type === 'slider' ? <SliderInput options={options} handleChange={onInputChange} value={value} type={type} isRequired={isRequired} /> : null}
            {type === 'datepicker' ? <DatePickerComponent options={options} type={type} handleChange={onInputChange} value={value} setDisableNext={setDisableNext} isRequired={isRequired} /> : null}
            {type === 'arrival-departure' ? <ArrivalDeparture options={options} type={type} handleChange={onInputChange} value={value} setDisableNext={setDisableNext} isRequired={isRequired} /> : null}
        </>
    );
};
export default QuestionInputs