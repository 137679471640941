import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { themeColor } from '../../config';
import TextareaField from '../../components/TextareaField';
import { PostFetch } from '../../utils/fetchUrl';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import Checkbox from '../../components/Checkbox';

const SyncUserByITS = () => {
    const [syncUserStatus, setSyncUserStatus] = useState();
    const [itsId,setItsId] = useState();
    const [ITSIds, setITSIds] = useState({
        ITSIds:[],
    });
    const [successPopup, setSuccessPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [idError,setIdError] = useState(false)

    const dispatch = useDispatch();

    const syncUser = () => {
        if(ITSIds.ITSIds.length > 0){
            setLoader(true);
            PostFetch('/api/user/syncByITS', ITSIds, "POST").then((response)=>{
                if(response.status === 200) {
                    setSyncUserStatus(response.data);
                    setSuccessPopup(true);
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    setLoader(false);
                }
                setLoader(false);
            }).catch((error)=>{
                console.log('error', error);
                showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
                setLoader(false);
            })
        }
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    const schema = yup
    .object({
      ItsIds: yup.string()
      .required('This field is required')
      .matches(/^[0-9,'\n' ]+$/, "Must be only digits")
    })
    .required("required");
    const { handleSubmit, register, reset,watch,setValue, formState: {errors} } = useForm({
        resolver: yupResolver(schema),
    });
    const submitAction = (data) => {
        setIdError(false)
        {itsId == "" ? setIdError(true) : setIdError(false) }
        const arr =  itsId.split("\n");
        let numArr =[]
        arr && arr.length && arr.map((str,i)=>{
            if(str.length > 8 || str.length < 8 ){
                setIdError(true)
            }
            else if(str.length == 8){
                numArr.push(parseInt(str))
                setIdError(false)
            }
        })
        setITSIds({
            ITSIds: numArr,
        });
    }
    useEffect(()=>{
        if(!idError){
            if(ITSIds.ITSIds && ITSIds.ITSIds.length > 0){
             syncUser();
             setItsId('');
            }
        }
        
    }, [ITSIds.ITSIds])

    useEffect(()=>{
        if(syncUserStatus){
            showSuccessAlert(`Sucessfuly Synced ${syncUserStatus?.syncedSuccessfulCount} ${syncUserStatus?.syncedSuccessfulCount && syncUserStatus?.syncedSuccessfulCount > 1 ? 'Users' : 'User'}`)
        }
    },[syncUserStatus])

  return (
    <div className='main-wrapper'>
        <div className="container">
            <Row className='justify-content-center'>
                <Col lg={7}>
                    <div className="app-head">
                        <Link to='/sync-user' className='back-btn' style={{color: `${themeColor.primaryColor}`}}><IoIosArrowBack/></Link>
                        <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>BY ITS ID(s)</h2>
                    </div>
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit(submitAction)}>
                            <TextareaField fieldPlaceholder={"Enter ITS ID(s)"} 
                                fieldLabel={'ITS ID(s):'} name={'ItsIds'} classNm={'no-margin'}
                                error={errors.ItsIds?.message} register={{...register("ItsIds")}} 
                                value={itsId}  handleChng={(e)=>setItsId(e.target.value)}
                            />
                            <br/>
                            {idError ?
                                <h6 className="note" style={{color: "red"}}>ITS Ids should be of 8 digits</h6>:''
                            }
                            <h6 className="note" style={{color: "green"}}>Press Enter After Every ITS ID</h6>
                            <div className="btn-wrapper">
                                <Link to='/sync-user'><Button variant="danger">Cancel</Button></Link>
                                <Button variant="success" type='submit' disabled={loader} >Sync{
                                    loader ? 
                                    <div className='loader-wrapper btn-loader'>
                                        <Spinner animation="border" variant="white" />
                                    </div>: ''
                                }</Button>
                            </div>
                        </form>
                    </div>
                    {successPopup && !loader?
                        syncUserStatus ? 
                            <div className="status-wrapper" style={{color: `${themeColor.secondaryColor}`}}>
                                <div className="status-container">
                                    {syncUserStatus.syncedSuccessfulCount > 0 ?
                                        <div className="details">
                                            <p><b style={{color: `${themeColor.primaryColor}`}}>{syncUserStatus.syncedSuccessfulCount}</b> {syncUserStatus.syncedSuccessfulCount > 1 ? 'Members' : 'Member'} Successfully Synced</p>
                                        </div>
                                    : ''}
                                    {syncUserStatus.untaggedCount > 0 ?
                                        <div className="details">
                                            <p><b style={{color: `${themeColor.primaryColor}`}}>{syncUserStatus.untaggedCount}</b> {syncUserStatus.untaggedCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Untagged</p>
                                        </div>
                                    : ""}
                                    {syncUserStatus.errorCount > 0 ?
                                        <div className="details">
                                            <p><b style={{color: `${themeColor.primaryColor}`}}>{syncUserStatus.errorCount}</b> {syncUserStatus.errorCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Invalid</p>
                                        </div>
                                    : ""}
                                </div>
                            </div>
                            : 'Something went wrong'
                    : ''
                    }
                </Col>
            </Row>
        </div>
        
    </div>
  )
}

export default SyncUserByITS