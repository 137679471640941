import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import QuestionInputs from '../questionInput';
import { Box, Grid } from '@mui/material';
import { themeColor } from '../../../../config';
import { Block } from '@mui/icons-material';

export default function ProgressMobileStepper({ activeStep, singleMiqaat, otherFieldValue, setDisableNext, setOtherFieldValue, miqaatPriview, preview, steper, handleBack, handleNext, questionsData, onInputChange, questionId, disableNext
}) {
    const theme = useTheme(activeStep);
    const [isRequired, setIsRequired] = React.useState(false);
    const [answer, setAnswer] = React.useState('')
    React.useEffect(()=>{
        let text = ''
        questionsData.details.map((item, index) => {
            if(item.type === 'checkbox'){
                if(item.options.every((op) => op.value !== true)){
                    text = '-'
                }else {
                    text = ''
                }
            }
            setAnswer(text)
        })
    }, [questionsData])
    return (<>
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: 'auto', }}>
            {
                steper &&
                <>
                    {questionsData.details?.map((questiondata, index) => {
                        return (<>
                            {activeStep - 1 === index ? <>
                                <h6 style={{ marginBottom: '20px', whiteSpace: 'pre-line', textTransform: 'capitalize' }}>{questiondata.title}</h6>
                                {questiondata.description &&
                                    <p style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>{questiondata.description}</p>
                                }
                                <Grid spacing={3}>
                                    <QuestionInputs singleMiqaat={singleMiqaat} otherFieldValue={otherFieldValue} setOtherFieldValue={setOtherFieldValue} maxLimit={questiondata?.maxSelection} setDisableNext={setDisableNext} type={questiondata.type} id={questiondata.id} options={questiondata.options} onInputChange={onInputChange} value={questiondata.answer} isRequired={isRequired} questionId={questionId} />
                                </Grid>
                            </> : null}
                        </>)
                    })}
                    <MobileStepper
                        variant="progress"
                        steps={questionsData.details.length + 1}
                        position="static"
                        activeStep={activeStep}
                        sx={{
                            width: { xs: '100%', sm: '100%', md: '448px' }, height: '80px', maxHeight: '80px', position: 'absolute', bottom: 0, left: 0, '& .MuiLinearProgress-bar': {
                                backgroundColor: themeColor.primaryColor
                            }
                        }}

                        nextButton={
                            <Button size="small" sx={{ color: themeColor.primaryColor }} onClick={() => {
                                const type = questionsData.details[activeStep - 1]?.type
                                if (type === 'select' || type === 'checkbox' || type === 'radio') {
                                    if (questionsData.details[activeStep - 1]?.required) {
                                        if (type === 'checkbox') {
                                            // Check if at least one checkbox is checked
                                            const atLeastOneChecked = questionsData.details[activeStep - 1]?.options
                                                .some((checkboxOption) => checkboxOption.value === true);
                                            if (atLeastOneChecked) {
                                                handleNext(questionsData.details);
                                                setIsRequired(false);
                                            }
                                            else {
                                                setIsRequired(true);
                                            }

                                        } else if (questionsData.details[activeStep - 1]?.answer) {
                                            handleNext(questionsData.details);
                                            setIsRequired(false);
                                        } else {
                                            setIsRequired(true);
                                        }
                                    } else {
                                        setIsRequired(false);
                                        handleNext(questionsData.details);
                                    }
                                }else if(type === 'datepicker'){
                                    if(questionsData?.details[activeStep - 1].options.some((op)=>op.value === 'Invalid date')){
                                    }else{
                                        handleNext(questionsData.details)
                                    }
                                }
                                else {
                                    const areAllRequiredOptionsFilled = questionsData?.details[activeStep - 1]?.options
                                        .filter((option) => option.required) // Filter only required options
                                        .every((option) => {
                                            if (type === 'datepicker' || type === 'arrival-departure') {
                                                // Check if type is 'datepicker' or 'arrival-departure'

                                                return (
                                                    !!option.value && option.value !== 'Invalid date' // Check for null or 'Invalid date'
                                                );
                                            } else {
                                                // For other types, no specific check is required
                                                return !!option.value;
                                            }
                                        });
                                    if (areAllRequiredOptionsFilled) {
                                        setIsRequired(false)
                                        handleNext(questionsData.details)
                                    } else {
                                        setIsRequired(true)

                                    }
                                }

                            }} disabled={disableNext}>
                                {activeStep === questionsData.details.length + 1 ? 'Submit' : 'Next'}
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={() => {
                                setDisableNext(false)
                                setIsRequired(false);
                                handleBack()
                            }} sx={{ color: themeColor.primaryColor }} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Back
                            </Button>
                        }
                    />
                </>
            }
            {
                preview &&
                <Grid>
                    {questionsData.details.map((item, index) => (

                        <Box sx={{ mb: '12px' }} className='review-tab'>
                            <h6 style={{ whiteSpace: 'pre-line' }}>{index + 1}. {item.title}</h6>
                            {
                                item.type === 'checkbox' &&
                                (item.answer ?
                                    (!answer ? 
                                        item.options?.map((option) => (
                                            option.placeholder.toLowerCase().includes('other') ?
                                                <p>{
                                                    miqaatPriview.map((data) => (data.id === item.id ? data.val ? data.val : 'other: ' : ''))
                                                }</p>
                                                :
                                                (option.value === 'true' || option.value == true ?
                                                    <p>{option.placeholder}</p>
                                                    :
                                                    '')
                                        ))
                                        : <p>{answer}</p>
                                    )
                                : <p>-</p> )
                            }
                            {
                                item.type === 'radio' &&
                                (
                                item.answer ?
                                (item.options?.map((option) => (
                                    option.placeholder.toLowerCase().includes('other') ?
                                        <p>{
                                            miqaatPriview.map((data) => (data.id === item.id ? data.val : ''))
                                        }</p>
                                        :
                                        option.value && !option.value.toLowerCase().includes('other') ?
                                        <p>{option.value}</p>
                                        : option.value && !option.value.toLowerCase().includes('other') &&
                                        <p>{item.answer}</p>
                                )))
                                : <p>-</p>)
                            }
                            {item.type === "datepicker" || item.type === "text" || item.type === "textarea" || item.type === "number"
                                || item.type === "arrival-departure" ?

                                item.options?.map((option) => (
                                    <Box display='flex' alignItems='center'>
                                        <p>{option.placeholder}: </p> <p>{option.value ? option.value : '-'}</p>
                                    </Box>
                                ))
                                : ''
                            }

                            {item.type === "select" || item.type === "slider" ?
                                <p>{item.answer ? item.answer : '-'}</p>
                                : ''
                            }
                            {/* {!item.answer && <p>{answer}</p>} */}
                        </Box>
                    ))}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', position: 'absolute', bottom: '20px', right: '20px' }}>
                        <Button variant='contained' color='error' onClick={handleBack} sx={{ mr: '20px' }}>Back</Button>
                        <Button variant='contained' color='success' onClick={() => handleNext(questionsData.details, 'submit')}  >Submit</Button>
                    </Box>
                </Grid>
            }

        </div >

    </>
    );
}