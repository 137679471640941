import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";
// import { CANCEL_EDIT_FORM } from "../reducers/questions";

export const AllTicketAction = (miqaatId) => async (dispatch) => {
  dispatch({
    type: TICKET_LOADING,
  });
    GetFetch(`/api/Tickets/get?miqaatId=${miqaatId}`)
        .then((response) => {
            var data = response.data;
            if (response.status === 200) {
                dispatch({
                    type: ALL_TICKET,
                    payload: data,
                });
            } else {
                // dispatch({
                //   type: REQUEST_FAIL,
                // });
                dispatch({
                    type: ALERT_ERROR,
                    payload: "Something went wrong. Please try again later.",
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: ALERT_ERROR,
                payload: error.data.msg || "Something went wrong. Please try again later.",
            });
        });
};

  
  export const GetSingleTicketAction = (ticketId) => (dispatch) => {
    dispatch({
      type: TICKET_LOADING,
    });
      GetFetch(`/api/Tickets/getById?id=${ticketId}`)
      .then((response) => {
        dispatch({
          type: TICKET_LOADING,
        });
        var SingleVenue = response.data;
        if (response.status === 200) {
          dispatch({
            type: SINGLE_TICKET,
            payload: SingleVenue || {},
          });
        } else {
          dispatch({
            type: TICKET_FAIL,
            payload: "Something went wrong. Please try again later.",
          });
          dispatch({
            type: ALERT_ERROR,
            payload: "Something went wrong. Please try again later.",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: TICKET_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: error.data.msg || "Something went wrong. Please try again later.",
        });
      });
  };

  export const UpdateVenueAction = (updateDetails, miqaatId, history) => (dispatch) => {
    PostFetch('/api/Venues/edit', updateDetails, 'PUT')
    .then((response) => {
      if (response.status === 202) {
        dispatch({
          type: UPDATE_TICKET,
          payload: {},
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Venue updated successfully",
        });
        history(`/view-miqaat/${miqaatId}/venue`);
      } else {
        dispatch({
          type: TICKET_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: TICKET_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};
export const addTicketAction = (details, history, setLoader) => (dispatch) => {
  PostFetch('/api/Tickets/Add', details, 'Post')
  .then((response) => {
    if (response.status === 202 || response.status === 200 ) {
      dispatch({
        type: ADD_TICKET,
        payload: {},
      });
      dispatch({
        type: ALERT_SUCCESS,
        payload: "Ticket Added successfully",
      });
      history(`/view-miqaat/${details.MiqaatId}/ticket`);
      setLoader(false);
    } else {
      dispatch({
        type: TICKET_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: "Something went wrong. Please try again later.",
      });
      setLoader(false);
    }
  })
  .catch((error) => {
    dispatch({
      type: TICKET_FAIL,
      payload: "Something went wrong. Please try again later.",
    });
    dispatch({
      type: ALERT_ERROR,
      payload: error.data.msg || "Something went wrong. Please try again later.",
    });
    setLoader(false);
  });
};

  export const UpdateTicketAction = (updateDetails, history, setLoader) => (dispatch) => {
    PostFetch('/api/Tickets/Edit', updateDetails, 'PUT')
    .then((response) => {
      if (response.status === 202 || response.status === 200 ) {
        dispatch({
          type: UPDATE_TICKET,
          payload: {},
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Ticket updated successfully",
        });
        history(`/view-miqaat/${updateDetails.MiqaatId}/ticket`);
        setLoader(false);
      } else {
        dispatch({
          type: TICKET_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
        setLoader(false);
      }
    })
    .catch((error) => {
      dispatch({
        type: TICKET_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
      setLoader(false);
    });
};

export const DeleteTicketAction = (id, miqaatId) => (dispatch) => {

    PostFetch('/api/Tickets/delete', id, 'DELETE')
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: DELETE_TICKET,
        });
        dispatch(AllTicketAction(miqaatId))
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Ticket deleted successfully",
        });
      } else {
        dispatch({
          type: TICKET_FAIL,
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: TICKET_FAIL,
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};
  

export const TICKET_LOADING = "TICKET_LOADING";
export const ALL_TICKET = "ALL_TICKET";
export const TICKET_FAIL = "TICKET_FAIL";
// export const ADD_VENUE = "ADD_VENUE";    
export const DELETE_TICKET = "DELETE_TICKET";
export const SINGLE_TICKET = "SINGLE_TICKET";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const ADD_TICKET = "ADD_TICKET";