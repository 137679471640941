import {
    ALL_REGISTERED, REMOVE_REPORT_DATA,
  } from "../actions/MiqaatReport";
  
  // export const CANCEL_EDIT_FORM = "CANCEL_EDIT_FORM";
  
  const initialState = {
    registeredUsers: {},
  };
  
  export default (state = initialState, action) => {
    // console.log("---action", action)
    switch (action.type) {
  
      case ALL_REGISTERED:
        return {
          ...state,
          registeredUsers: action.payload,
        };
        case REMOVE_REPORT_DATA:
          return {
            registeredUsers: '',
          };
        case "USER_LOGOUT":
            return (state = initialState);
        default: {
            return state;
      }
    }
  };
  