import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack, IoMdAddCircle } from 'react-icons/io'
import { MdDeleteOutline, MdModeEdit } from 'react-icons/md'
import { Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import InputField from '../../../components/inputField'
import { InventoryByDateData, InventoryByProductData, ProductInData} from '../../../appContent'
import { themeColor } from '../../../config'
import AppModal from '../../../components/Modal'
import { dateTimeFormatFunction } from '../../../utils/dateFormat'
import ReactDatePicker from 'react-datepicker'
import SelectField from '../../../components/SelectField'

const InventoryByProduct = () => {
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [loader, setLoader] = useState(false)
  const params = useParams()
  const [showModal, setShowModal] = useState(false)
  const [deleteProductId, setDeleteProductId] = useState('');
  const [productValue, setProductValue] = useState();
  const [remainingProduct, setRemainingProduct] = useState();

  const columns = [
    {
      name: 'ITS Id',
      selector: (row) => row.ItsId
    },
    {
        name: 'Name',
        selector: (row) => row.name
    },
    {
        name: 'Arrived/Depart At',
        selector: (row) => dateTimeFormatFunction(row.insertedAt)
    },
    {
        name: 'In',
        selector: (row) => row.transactionType === "In" ? row.quantity : '-',
        minWidth: '160px',
        maxWidth: '160px'
    },
    {
        name: 'Out',
        selector: (row) => row.transactionType === "Out" ? row.quantity : '-',
        minWidth: '160px',
        maxWidth: '160px'
    }
  ]

  const products = [
    {value: 1, label: 'Vacuum cleaner'},
    {value: 2, label: 'Laptop'},
    {value: 3, label: 'Waste Bin'},
  ]

  useEffect(() => {
    setLoader(true)
    setProductValue(products[0])
  }, [])

  const deleteVenue = (id) => {
    setDeleteProductId(id)
    setShowModal(true)
  }
  const confirDelete = () => {
    // let data = {
    //   id: deleteProductId,
    //   miqaatId: params?.id 
    // }
    // dispatch(DeleteVenueAction(data))
    setShowModal(false)
  }


  useEffect(() => {
    if (productValue) {
      const result = InventoryByProductData?.filter((team) => {
        return team.id === productValue.value
      })
      setFilteredData(result[0].transactions)
    } else {
      setFilteredData([])
    }
  }, [productValue])


  useEffect(() => {
    if (filteredData?.length > 0) {
      setLoader(false)
      let count = 0;
      filteredData.map((data)=>{
        if(data.transactionType === "In"){
            count += data.quantity
        }else{
            count -= data.quantity
        }
        setRemainingProduct(count)
      })
      
    }else{
        setLoader(false)
    }
  }, [filteredData])

  return (
    <div className='main-wrapper'>
        <div className="container">
            <div className="app-head">
                <Link to={`/view-miqaat/${params.id}/inventory-report`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack/></Link>
                <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>Inventory By Product</h2>
            </div>
            {loader
              ? <div className='loader-wrapper'>
              <Spinner animation="border" variant="success" />
            </div>
              : <div className="table-wrapper">
                <div style={{borderBottom: '1px solid rgb(204, 204, 204)'}}>
                    <DataTable data={filteredData} columns={columns}
                    fixedHeader fixedHeaderScrollHeight='482px'
                    subHeader
                    subHeaderComponent={
                    <div className='table-header'>
                        <SelectField options={products} name={'product'}
                        placeholder="Select Product" value={productValue} handleChange={(e)=>setProductValue(e)} />
                    </div>} subHeaderAlign="left"/>
                    <div className="table-footer">
                        <p className="note">Product With Us</p>
                        <span>{remainingProduct}</span>
                    </div>
                </div>
                </div>
            }

            <AppModal handleClose={()=>setShowModal(false)} showModal={showModal} title={'Confirm Delete'}
          handleAction={confirDelete} actionBtnText={"Confirm"}/>
        </div>
    </div>
  )
}

export default InventoryByProduct
