import React, { useState, useEffect } from "react";
import InputField from "../../../../components/inputField";
// import TextareaField from "../../../../components/TextareaField";
import { IoMdAddCircle } from 'react-icons/io'
import { FaMinus } from 'react-icons/fa'
import Checkbox from "../../../../components/Checkbox";
import { FormHelperText } from "@mui/material";
const EditQuestionOptions = ({ type, getValues, setValues, unlimitedCondition }) => {
  //   const classes = commonStyles();
  const [options, setOptions] = useState([]);

  // useEffect(() => {
  //     if(setValues){
  //         setOptions(setValues[1]);
  //     }
  // }, [setValues]);
  useEffect(() => {
    if (unlimitedCondition && setValues?.length > 1) {
      setOptions([setValues[1]]);
    } else {
      setOptions(setValues);
    }
  }, [setValues]);

  // Prepare data
  const intit = (add) => {
    var obj = {};

    switch (type) {
      case "number":
        obj = {
          placeholder: "",
          min: "",
          max: "",
          step: "",
          label: `${type}-${add ? options.length + 1 : 1}`,
          value: "",
          required: null
        };
        break;

      case "slider":
        obj = {
          placeholder: "",
          min: "",
          max: "",
          label: `${type}-${add ? options.length + 1 : 1}`,
          value: "",
          required: null
        };
        break;

      case "text":
        obj = {
          placeholder: "",
          required: null
        };
        break;

      case "checkbox":
      case "select":
      case "radio":
        obj = {
          placeholder: "",
        };
        break;
      case "datepicker":
        obj = {
          placeholder: "",
          required: null
        };
        break;

      case "textarea":
        obj = {
          placeholder: "",
          min: "",
          max: "",
          row: "",
          label: `${type}-${add ? options.length + 1 : 1}`,
          value: "",
          required: null
        };
        break;

      default:
        obj = options;
        break;
    }
    if (add) {
      options.push(obj);
      setOptions([...options]);
    } else {
      setOptions([obj]);
    }
  };

  const removeOption = (input) => {
    var filterOptions = options.filter((o) => o !== input);
    getValues(filterOptions);
    setOptions(filterOptions);
  };

  const handleOptionChange = (e, index, element) => {

    if (element === 'required') {
      options[index][element] = e.target.checked;
    } else {
      options[index][element] = e.target.value.toLowerCase().includes('other') ? e.target.value.replace('other', 'Other') : e.target.value;
    }
    setOptions([...options]);
    getValues(options);
  };

  return (
    <React.Fragment>
      {options?.length > 0 && type ? (
        <React.Fragment>
          {options.map((input, i) => (
            <div className="question_addeditoption_wrapper">
              <div
                key={i}
              >
                <div className="option-style">
                  <h6> Option {i + 1}</h6>
                </div>
                <div container className="option-field-wrapper">
                  <div className="question-field-style">
                    <InputField
                      fieldPlaceholder={'Placeholder'}
                      fieldType={'text'}
                      value={options[i].placeholder}
                      handleChange={(e) => handleOptionChange(e, i, "placeholder")}
                    />
                    {options[i].placeholder === '' ? <FormHelperText sx={{ color: '#d32f2f' }}> This field is required </FormHelperText> : ''}
                  </div>

                  {type === "number" ||
                    type === "slider" ||
                    type === "textarea" ? (
                    <React.Fragment>
                      <div item className="option-field">
                        <InputField
                          fieldPlaceholder={'Min'}
                          fieldType={'number'}
                          className="question_addeditoption_value"
                          value={options[i].min}

                          handleChange={(e) => handleOptionChange(e, i, "min")}
                        />
                      </div>
                      <div item className="option-field">
                        <InputField
                          fieldPlaceholder={'Max'}
                          fieldType={'number'}
                          variant="outlined"
                          size="small"
                          value={options[i].max}
                          handleChange={(e) => handleOptionChange(e, i, "max")}
                        />
                      </div>
                    </React.Fragment>
                  ) : null}

                  {type === "number" ? (
                    <React.Fragment>
                      <div className="option-field">
                        <InputField
                          fieldPlaceholder={'Step'}
                          fieldType={'number'}
                          value={options[i].step}
                          handleChange={(e) => handleOptionChange(e, i, "step")}
                        />
                      </div>
                    </React.Fragment>
                  ) : null}

                  {type === "textarea" ? (
                    <React.Fragment>
                      <div >
                        <InputField
                          fieldPlaceholder={'Row'}
                          fieldType={'number'}
                          value={options[i].row}
                          handleChange={(e) => handleOptionChange(e, i, "row")}
                        />
                      </div>
                    </React.Fragment>
                  ) : null}

                  {/* {i === 0 ? null : (
                  <div className="sub-option">

                    <FaMinus onClick={() => removeOption(input)} />
                    </div>
                )} */}
                </div>
                
                {
                  type === "number" || type === "slider" || type === "textarea" || type === 'datepicker' || type === 'text' || type === 'text' ?
                    <Checkbox label={"Required"} className={'big'} greenCheckbox={true} checked={options[i].required} id={i} handleChange={(e) => handleOptionChange(e, i, "required")} />
                    : ''
                }
              </div>

            </div>
          ))}
        </React.Fragment>
      ) : null}

      <div className="add-option">
        {type ? (
          <React.Fragment>

            <IoMdAddCircle onClick={() => intit(true)} />
            {/* </IoMdAddCircle> */}
          </React.Fragment>
        ) : null}
      </div>

    </React.Fragment>
  );
};

export default EditQuestionOptions;
