import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack, IoMdAddCircle } from 'react-icons/io'
import { MdModeEdit, MdDeleteOutline } from 'react-icons/md'
import { GrClose, GrFormView } from 'react-icons/gr'
import { Spinner } from 'react-bootstrap'
import { themeColor, profileUrl } from '../../../config'
import DataTable from 'react-data-table-component'
import InputField from '../../../components/inputField'
import { useDispatch, useSelector } from 'react-redux'
import AppModal from '../../../components/Modal'
import { AllDepartmentAction, DeleteDepartmentAction, GetSingleDepartmentAction, SINGLE_DEPARTMENT } from '../../../store/actions/departmentAction'
import { TbLocation } from 'react-icons/tb'
import { FiPhone } from 'react-icons/fi'
import placeholderUserImg from '../../../assets/images/profile.png'
// '../../assets/images/profile.png';
const ViewDepartment = () => {
  const dispatch = useDispatch();
  const params = useParams()
  const [showModal, setShowModal] = useState(false)
  const [search, setSearch] = useState('')
  const [deleteDepartmentId, setDeleteDepartmentId] = useState('')
  const [filteredData, setFilteredData] = useState([])
  // const [loader, setLoader] = useState(true)
  const [popupLoading, setPopupLoader] = useState(false)
  const departmentData = useSelector((state) => state.departmentreducer)
  const [viewDepartMentDetails, setViewDepartMentDetails] = useState({})
  const [openViewDepartMentPopup, setOpenDepartMentTeamPopup] = useState(false)
  const columns = [
    {
      name: 'Department',
      selector: (row) => row?.name,
    },
    {
      name: 'Total Member',
      selector: (row) => row?.noOfMembers,
      maxWidth: '160px'
    },
    {
      name: 'Actions',
      cell: (row) => <p className='action-btn-wrapper'>
        <button className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} onClick={() => handleViewDepartment(row?.id)}><GrFormView />
        </button>
        <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat/${params?.id}/view-department/edit/${row.id}`} ><MdModeEdit /></Link> <button className='action-btn note-error' style={{ background: `${themeColor.lightGreen}`, color: 'red' }} onClick={() => deleteDepartment(row?.id)} ><MdDeleteOutline /></button></p>,
        maxWidth: '160px'
    }
  ]


  useEffect(() => {
    dispatch(AllDepartmentAction(params?.id))
  }, [params?.id])

  useEffect(() => {
    if (departmentData?.departmentData.length > 0) {

      setFilteredData(departmentData?.departmentData)
    } else {
      setFilteredData(departmentData?.departmentData)
    }
  }, [departmentData?.departmentData])
  useEffect(() => {
    if (departmentData?.singleDepartment) {
      setPopupLoader(false)
      setViewDepartMentDetails(departmentData?.singleDepartment)
    } else {
      setViewDepartMentDetails({})
    }
  }, [departmentData?.singleDepartment])
  useEffect(() => {
    if (search) {
      const result = departmentData?.departmentData?.filter(data => Object.keys(data).some(key => {
        return String(data['name']).toLowerCase().includes(search?.toLowerCase())
      }))
      setFilteredData(result)
    } else {
      setFilteredData(departmentData?.departmentData)
    }
  }, [search])


  const deleteDepartment = (id) => {
    setDeleteDepartmentId(id)
    setShowModal(true)
  }

  const confirDelete = () => {
    let data = {
      id: deleteDepartmentId
    }
    dispatch(DeleteDepartmentAction(data, params?.id))
    setShowModal(false)
  }

  const handleViewDepartment = (id) => {
    setPopupLoader(true)
    setOpenDepartMentTeamPopup(true)
    dispatch(GetSingleDepartmentAction(id))
  }

  return (
    <div className='main-wrapper'>
      <div className="container">
        <div className="app-head">
          <Link to={`/view-miqaat/${params?.id}`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack /></Link>
          <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}> Department</h2>
        </div>
        {departmentData?.loading
          ? <div className='loader-wrapper'>
            <Spinner animation="border" variant="success" />
          </div>
          : <div className="table-wrapper role-management-table">
            <DataTable data={filteredData} columns={columns}
              fixedHeader fixedHeaderScrollHeight='482px'
              subHeader pagination
              subHeaderComponent={
                <div>
                  <InputField className={'search-field'} fieldPlaceholder={'Search Department'} fieldType={'text'} value={search}
                    handleChange={(e) => setSearch(e.target.value)} />
                  <Link to={`/view-miqaat/${params?.id}/view-department/add`} className='icon-btn dark' style={{ background: `${themeColor.primaryColor}` }}><IoMdAddCircle />Add Department</Link>
                </div>} subHeaderAlign="left" />
          </div>
        }
        {popupLoading
          ? <div className='loader-wrapper'>
            <Spinner animation="border" variant="success" />
          </div> :
          <>
            {openViewDepartMentPopup
              ? <div className="popup-wrapper">
                <div className="popup-container">
                  <div className="popup-head">
                    <h4 style={{ textTransform: 'capitalize' }}>{viewDepartMentDetails.name}</h4>
                  </div>
                  <div className="details">

                    <div className="details-block">
                      <div className="details-block">
                        <h5>Team Members:</h5>
                        {viewDepartMentDetails.members && viewDepartMentDetails.members.length > 0 ? viewDepartMentDetails.members?.map((member) => (
                          <div className="flex" key={member.name}>
                            <img src={`data:image/png;base64,${member.photo}`} onError={(e) => { e.target.src = placeholderUserImg }} alt="profile" />
                            <div className="info">
                              <p>{member?.fullName}</p>
                              <a href={`tel:${member.mobNo}`}><FiPhone /> {member.mobNo}</a>
                            </div>
                          </div>

                        )) : 'No Member Available'}

                      </div>
                      {/* <h5>Total Memebers:</h5>
                  {viewDepartMentDetails?.members?.map((member) => (
                    <div className="flex" key={member.itsid}>
                      <TbLocation />
                      <p>{member.name}</p>
                    </div>
                  ))} */}

                    </div>
                  </div>
                  <button className='close-btn' onClick={() => {
                    dispatch({
                      type: SINGLE_DEPARTMENT,
                      singleDepartment: {}
                    })
                    setOpenDepartMentTeamPopup(false)
                  }}><GrClose /></button>
                </div>
              </div>
              : ''
            }
          </>}
        <AppModal handleAction={confirDelete} handleClose={() => setShowModal(false)} hasBody={true} content='Are you sure, You want to delete Department?' showModal={showModal} title={'Confirm Delete'} actionBtnText={"Confirm"} />
      </div>
    </div>
  )
}

export default ViewDepartment
