import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";
// import { CANCEL_EDIT_FORM } from "../reducers/questions";

export const AllVenueAction = (miqaatId) => async (dispatch) => {
  dispatch({
    type: VENUE_LOADING,
  });
  GetFetch(`/api/Venues/get?miqaatId=${miqaatId}`)
    .then((response) => {
      var data = response.data;
      if (response.status === 200) {
        dispatch({
          type: ALL_VENUES,
          payload: data,
        });
      } else {
        // dispatch({
        //   type: REQUEST_FAIL,
        // });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const AddVenueAction = (addVenueDetails, miqaatId, history) => (dispatch) => {
  dispatch({
    type: VENUE_LOADING,
  });
  PostFetch("/api/Venues/add", addVenueDetails, 'POST')
    .then((response) => {
      var data = response.data;
      if (response.status === 200) {
        dispatch({
          type: ADD_VENUE,
          payload: {},
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Venue added successfully",
        });
        history(`/view-miqaat/${miqaatId}/zone`);
      } else {
        dispatch({
          type: VENUE_FAIL,
          payload: "",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: VENUE_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const GetSingleVenueAction = (venueId) => (dispatch) => {
  dispatch({
    type: VENUE_LOADING,
  });
  GetFetch(`/api/Venues/getById?id=${venueId}`)
    .then((response) => {
      var SingleVenue = response.data;
      if (response.status === 200) {
        dispatch({
          type: SINGLE_VENUE,
          payload: SingleVenue || "",
        });
      } else {
        dispatch({
          type: VENUE_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: VENUE_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const UpdateVenueAction = (updateDetails, miqaatId, history) => (dispatch) => {
  PostFetch('/api/Venues/edit', updateDetails, 'PUT')
    .then((response) => {
      if (response.status === 202) {
        dispatch({
          type: UPDATE_VENUE,
          payload: {},
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Venue updated successfully",
        });
        history(`/view-miqaat/${miqaatId}/zone`);
      } else {
        dispatch({
          type: VENUE_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: VENUE_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const DeleteVenueAction = (id) => (dispatch) => {

  PostFetch('/api/Venues/delete', id, 'DELETE')
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: DELETE_VENUE,
        });
        dispatch(AllVenueAction(id.miqaatId))
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Venue deleted successfully",
        });
      } else {
        dispatch({
          type: VENUE_FAIL,
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: VENUE_FAIL,
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};


export const VENUE_LOADING = "VENUE_LOADING";
export const ALL_VENUES = "ALL_VENUES";
export const QUESTION_ERROR = "QUESTION_ERROR";
export const PAGINATE_QUESTIONS = "PAGINATE_QUESTIONS";
export const VENUE_FAIL = "VENUE_FAIL";
export const ADD_VENUE = "ADD_VENUE";
export const DELETE_VENUE = "DELETE_VENUE";
export const SINGLE_VENUE = "SINGLE_VENUE";
export const UPDATE_VENUE = "UPDATE_VENUE";