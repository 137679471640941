import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { themeColor } from '../../config';
import Checkbox from '../../components/Checkbox';
import InputField from '../../components/inputField';
import { Button, Spinner } from 'react-bootstrap';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import RadioButton from '../../components/RadioButton';
import { useDispatch } from 'react-redux';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../store/reducers/alert';
import { yupResolver } from "@hookform/resolvers/yup";
import { JamiatPermissionSchema } from '../../utils/Schemas';
import { useForm } from 'react-hook-form';
import SelectField from '../../components/SelectField';

export const AdminRoleAccess = () => {
  const userId = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [roleName, setRoleName] = useState('');
  const [upData, setUpData] = useState();
  const [editData, setEditData] = useState([])
  const [edit, setEdit] = useState([])
  const [showNote, setShowNote] = useState(false);
  const [selectJamiatOptions, setSelectJamiatOptions] = useState([])
  const [jamiats, setJamiats] = useState();
  const [loader, setLoader] = useState(false);
  const [btnloader, setBtnLoader] = useState(false);
  const [count, setCount] = useState(0);

  const { handleSubmit, register, watch, reset, setValue, formState: { errors }, control } = useForm({
    resolver: yupResolver(JamiatPermissionSchema),
  });

  useEffect(() => {
    setLoader(true);
    GetFetch(`/api/Roles/${userId.id}`).then((response) => {
      if (response.status == 200) {
        setUpData(response.data);
      }
      else {
        showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
      }
      setLoader(false);
    }).catch((err) => {
      setLoader(false);
      showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
    })
  }, [])

  useEffect(() => {
    if (upData && upData.options) {
      setEditData(upData.options)
      setEdit(upData.options)
    }
    if (upData && upData.roleName) {
      setRoleName(upData.roleName)
    }
  }, [upData])

  const fetchJamiat = () => {
    GetFetch(`/api/Jamiat`).then((response) => {
      if (response.status == 200) {
        setSelectJamiatOptions(response.data);
      }
      else {
        console.log('Something went wrong');
      }
    }).catch((err) => {
      console.log('error', err);
    })
  }

  useEffect(() => {
    fetchJamiat()
  }, [])

  useEffect(() => {
    if (selectJamiatOptions) {
      let dataJamiat = []
      selectJamiatOptions.map((jamiat, i) => {
        dataJamiat.push({ value: jamiat.jamiatId, label: jamiat.name })
      })
      setJamiats(dataJamiat)
    }
  }, [selectJamiatOptions])

  useEffect(() => {
    let optionData = editData;
    optionData.map((data, index) => {
      data?.childrens?.length && data?.childrens?.map((child, childIndex) => {
        if (child.jamiatPermission == 0) {
          child.disableRadio = false
        }
        else if (child.jamiatPermission == 2) {
          let dataSet = []
          let apiId = []
          if (child.jamiatIds && child.jamiatIds.length) {
            apiId = child.jamiatIds
          }
          apiId && apiId.map((id, i) => {
            jamiats && jamiats.map((item, i) => {
              if (id == item.value) {
                dataSet.push(item)
              }
            })
          })
          if(dataSet && dataSet.length ){
            setValue(`${child.name}Jamiat`, dataSet)
          }else{
            setCount(count + 1)
          }
        }
        else {
          child.disableRadio = true
        }
        child.childrens && child.childrens.length && child.childrens.map((subChild,subChildIndex)=>{
          if (subChild.jamiatPermission == 0) {
            subChild.disableRadio = false
          }
          else if (subChild.jamiatPermission == 2) {
            let dataSet = []
            let apiId = []
            if (subChild.jamiatIds && subChild.jamiatIds.length) {
              apiId = subChild.jamiatIds
            }
            apiId && apiId.map((id, i) => {
              jamiats && jamiats.map((item, i) => {
                if (id == item.value) {
                  dataSet.push(item)
                }
              })
            })
            if(dataSet && dataSet.length ){
              setValue(`${subChild.name}Jamiat`, dataSet)
            }else{
              setCount(count + 1)
            }
          }
          else {
            subChild.disableRadio = true
          }
        })
      })
    })
  }, [edit, count])


  const handleChange = (item, i, child) => {
    if (child == 1) {
      let optionData = editData
      optionData.map((data, index) => {
        data?.childrens?.length && data?.childrens?.map((child, childIndex) => {
          if (child.optionId == item.optionId) {
            child.allow = !item.allow
            if (child.allow) {
              data.allow = true
            }
            if (!child.allow) {
              if (child.childrens && child.childrens.length) {
                child.childrens.map((subChild, childIndex) => {
                  subChild.allow = false
                })
              }
            }
            setEditData([...optionData])
          }
        })
      })
    }
    else if (child == 2) {
      let optionData = editData
      optionData.map((data, index) => {
        data?.childrens && data?.childrens?.length && data?.childrens?.map((child, childIndex) => {
          child.childrens && child.childrens.length && child.childrens.map((subChild, subIndex) => {
            if (subChild.optionId == item.optionId) {
              subChild.allow = !item.allow
              if (subChild.allow) {
                child.allow = true
                data.allow = true
              }
              setEditData([...optionData])
            }
          })
        })
      })
    }
    else {
      let optionData = editData
      optionData.map((data, index) => {
        if (data.optionId == item.optionId) {
          data.allow = !item.allow
          if (!data.allow) {
            if (data?.childrens && data?.childrens?.length) {
              data?.childrens?.map((child, childIndex) => {
                child.allow = false
                if (child.childrens && child.childrens.length) {
                  child.childrens.map((subChild, subIndex) => {
                    subChild.allow = false
                  })
                }
              })
            }
          }
        }
      })
      setEditData([...optionData])
    }
  }

  const updateData = () => {
    if (roleName.length > 1 && roleName.charAt(0)!= " ") {
      let editRole = []

      if (editData && editData.length) {
        editData.map((item, i) => {
          if (item.allow == true) {
            if (item.jamaatPermissionReq == true) {
              editRole.push({ optionId: item.optionId, jamiatPermission: item.jamiatPermission, jamaatPermission: item.jamaatPermission })
            } else {
              editRole.push({ optionId: item.optionId })
            }
          }
        
          if (item.childrens && item.childrens.length) {
          item.childrens.map((child, childIndex) => {
            if (child.allow == true) {
              if (child.jamaatPermissionReq == true) {
                if (child.jamiatPermission == 2) {
                  let Id = []
                  let arrayData = watch(`${child.name}Jamiat`)
                  arrayData && arrayData.length > 0 && arrayData.map((item, i) => {
                    Id.push(item.value)
                  })
                  editRole.push({ optionId: child.optionId, jamiatPermission: child.jamiatPermission, jamaatPermission: child.jamaatPermission, jamiatIds: Id, })
                } else {
                  editRole.push({ optionId: child.optionId, jamiatPermission: child.jamiatPermission, jamaatPermission: child.jamaatPermission })
                }
              } else {
                editRole.push({ optionId: child.optionId })
              }
            }
            if(child.childrens && child.childrens.length){
              child.childrens.map((subChild,i)=>{
                if(subChild.allow == true){
                  if (subChild.jamaatPermissionReq == true) {
                    if (subChild.jamiatPermission == 2) {
                      let Id = []
                      let arrayData = watch(`${subChild.name}Jamiat`)
                      arrayData && arrayData.length > 0 && arrayData.map((item, i) => {
                        Id.push(item.value)
                      })
                      editRole.push({ optionId: subChild.optionId, jamiatPermission: subChild.jamiatPermission, jamaatPermission: subChild.jamaatPermission, jamiatIds: Id, })
                    } else {
                      editRole.push({ optionId: subChild.optionId, jamiatPermission: subChild.jamiatPermission, jamaatPermission: subChild.jamaatPermission })
                    }
                  } else {
                    editRole.push({ optionId: subChild.optionId })
                  }
                }
              })
            }
          })}
      })

      
    }
    let checkCustom = []
    editRole && editRole.map((item,i)=>{
      if(item && item.jamiatIds && item.jamiatIds.length == 0){
        checkCustom.push(true)
      }
    })    
    let bodyData = {
      id: parseInt(userId.id),
      name: roleName,
      roleDetails: editRole,
    }

    if(checkCustom[0] == true){
      showErrorAlert("Please Select Jamiat in Custom Field");
    }else{
      setBtnLoader(true);
      PostFetch(`/api/Roles/${userId.id}`, bodyData, "PUT").then((response) => {
        if (response.status === 200 || response.status === 202) {
          showSuccessAlert("Role Updated Successfully");
          navigate("/access-control/role-management")
        }
        else {
          dispatch({
            type: ALERT_ERROR,
            payload: "Something Went Wrong"
          })
        }
        setBtnLoader(false);
      })
    }
  }
      else {
  setShowNote(true);
}
    }
const showErrorAlert = (msg) => {
  dispatch({
    type: ALERT_ERROR,
    payload: msg
  })
}
const showSuccessAlert = (msg) => {
  dispatch({
      type: ALERT_SUCCESS,
      payload: msg
  })
}

const jamaatPermissionOnChange = (item, child, permissionType, e) => {

  let dataT = editData
  if (child == 1) {
    dataT.map((data, index) => {
      data?.childrens?.length && data?.childrens?.map((child, childIndex) => {
        if (child.optionId == item.optionId) {
          if (permissionType == 'jamiat') {
            child.jamiatPermission = parseInt(e)
          }
          if (permissionType == 'jamaat') {
            child.jamaatPermission = parseInt(e)
          }

          if (child.jamiatPermission == 1 || child.jamiatPermission == 2) {
            child.jamaatPermission = 1
            child.disableRadio = true
          }
          else {
            child.disableRadio = false
          }
        }
      })
    })
    setEditData([...dataT])
  }
  else if (child == 2) {
    dataT.map((data, index) => {
      data?.childrens?.length && data?.childrens?.map((child, childIndex) => {
       child.childrens && child.childrens.length && child.childrens.map((subChild,subChildIndex) => {
        if (subChild.optionId == item.optionId) {
          if (permissionType == 'jamiat') {
            subChild.jamiatPermission = parseInt(e)
          }
          if (permissionType == 'jamaat') {
            subChild.jamaatPermission = parseInt(e)
          }

          if (subChild.jamiatPermission == 1 || subChild.jamiatPermission == 2) {
            subChild.jamaatPermission = 1
            subChild.disableRadio = true
          }
          else {
            subChild.disableRadio = false
          }
        }
       })
      })
    })
    setEditData([...dataT])
  }
  else if (child == "custom") {
    let dataT = editData
    dataT.map((data, index) => {
      data?.childrens?.length && data?.childrens.map((child, childIndex) => {
        if (child.optionId == item.optionId) {
          if (permissionType == 'jamiat') {
            child.jamiatPermission = parseInt(e)
          }
          if (child.jamiatPermission == 1 || 2) {
            child.jamaatPermission = 1
          }
        }
      })
    })
    setEditData([...dataT])
  }
  else if (child == "custom2") {
    let dataT = editData
    dataT.map((data, index) => {
      data?.childrens?.length && data?.childrens?.map((child, childIndex) => {
        child.childrens && child.childrens.length && child.childrens.map((subChild,subChildIndex) => {
          if (subChild.optionId == item.optionId) {
            if (permissionType == 'jamiat') {
              subChild.jamiatPermission = parseInt(e)
            }
            if (subChild.jamiatPermission == 1 || 2) {
              subChild.jamaatPermission = 1
            }
          }
        })
      })
    })
    setEditData([...dataT])
  }
}

return (
  <div className='main-wrapper'>
    <div className="container">
      <div className="app-head">
        <Link to='/access-control/role-management' className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack /></Link>
        <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>{roleName}</h2>
      </div>
      {loader ?
        <div className='loader-wrapper'>
          <Spinner animation="border" variant="success" />
        </div>
        :
        <div className='role-access-wrapper'>
          <div className="edit-role-name">
            <InputField name={'Edit Role Name'} fieldPlaceholder={'Role Name'}
              fieldLabel={'Edit Role Name'} value={roleName} handleChange={(e) => setRoleName(e.target.value) } roleEdit={true}
              error={ roleName == "" ? 'Please enter role name'  :""} 
              />
            <div className="btn-wrapper" style={{ marginTop: '0' }}>
              <Button variant='success' onClick={()=>roleName.match(/^[0-9a-zA-Z," ",.,-]+$/) ? updateData() :  showErrorAlert("Role name should not started with space or special character")} disabled={btnloader}>Save
              {btnloader ? 
                <div className='loader-wrapper btn-loader'>
                    <Spinner animation="border" variant="white" />
                </div>: ''}</Button>
              <Link to='/access-control/role-management'><Button variant='danger'>Cancel</Button></Link>
            </div>
          </div>
          {editData && editData.length && editData.map((item, i) => {
            return (
              <div key={item.name}>
                <div className="user-role role-wrapper" style={{ color: `${themeColor.secondaryColor}` }}>
                  <div className="head">
                    <h4>{item.name}</h4>
                    <Checkbox name={"user-access"} greenCheckbox={true} checked={item.allow} handleChange={() => handleChange(item, i)} />
                  </div>
                  {item.childrens?.length > 0 ?
                    <div className="checkbox-wrapper">
                      {item.childrens.map((children, i) => (
                        <div className="checkbox-field parent" key={children.name}>
                          <Checkbox label={children.name} id={children.name} checked={children.allow} greenCheckbox={true} handleChange={() => handleChange(children, i, 1)} name={children.name} />

                          {children.jamaatPermissionReq && children.allow ?
                            <div className="field-wrapper radio-btn-wrapper">
                              <div className="radio-wrapper">
                                <p className='para'>Jamiat</p>
                                <RadioButton label={'Own'} name={`${children.name}jamiat`} id={`${children.name}jamiatOwn`} value={0} checked={children.jamiatPermission == 0 ? true : false} handleChange={(e) => jamaatPermissionOnChange(children, 1, 'jamiat', e.target.value)} />
                                <RadioButton label={'All'} name={`${children.name}jamiat`} id={`${children.name}jamiatAll`} value={1} checked={children.jamiatPermission == 1 ? true : false} handleChange={(e) => jamaatPermissionOnChange(children, 1, 'jamiat', e.target.value)} />
                                <RadioButton label={'Custom'} name={`${children.name}jamiat`} id={`${children.name}Custom`} value={2} checked={children.jamiatPermission == 2 ? true : false} handleChange={(e) => jamaatPermissionOnChange(children, "custom", 'jamiat', e.target.value)} />
                                <span>
                                  {children.jamiatPermission == 2 &&
                                    <SelectField isMulti={true} options={jamiats}
                                      value={watch(`${children.name}Jamiat`) || ''}
                                      register={{ ...register(`${children.name}Jamiat`) }}
                                      name={`${children.name}Jamiat`}
                                      placeholder={'Select Jamiat'}
                                      control={control}
                                    />
                                  }
                                </span>
                              </div>
                              {children.jamiatPermission == 0 ?
                                <div className="radio-wrapper">
                                  <p className='para'>Jamaat</p>
                                  <RadioButton label={'Own'} name={`${children.name}jamaat`} id={`${children.name}jamaatOwn`} value={0} defaultChecked={children.jamiatPermission == 0 ? true : false} handleChange={(e) => jamaatPermissionOnChange(children, 1, 'jamaat', e.target.value)} />
                                  <RadioButton label={'All'} name={`${children.name}jamaat`} id={`${children.name}jamaatAll`} value={1} defaultChecked={children.jamiatPermission == 1 ? true : false} handleChange={(e) => jamaatPermissionOnChange(children, 1, 'jamaat', e.target.value)} />
                                </div>
                                :
                                <div className="radio-wrapper">
                                  <p className='para'>Jamaat</p>
                                  <RadioButton label={'Own'} name={`${children.name}jamaat`} id={`${children.name}jamaatOwn`} value={0} disabled={true} checked={false} handleChange={(e) => jamaatPermissionOnChange(children, 1, 'jamaat', e.target.value)} />
                                  <RadioButton label={'All'} name={`${children.name}jamaat`} id={`${children.name}jamaatAll`} value={1} checked={true} handleChange={(e) => jamaatPermissionOnChange(children, 1, 'jamaat', e.target.value)} />
                                </div>
                              }
                            </div>
                            : ''}
                          {children.childrens?.length > 0 ?
                            <div className="checkbox-wrapper child">
                              {children.childrens?.map((subChild, i) => (
                                <div className="checkbox-field" key={subChild.name}>
                                  <Checkbox label={subChild.name} id={subChild.name} checked={subChild.allow} greenCheckbox={true} handleChange={() => handleChange(subChild, i, 2)} name={subChild.name} />
                                  {subChild.jamaatPermissionReq && subChild.allow ?
                                  <div className="field-wrapper radio-btn-wrapper">
                                    <div className="radio-wrapper">
                                      <p className='para'>Jamiat</p>
                                      <RadioButton label={'Own'} name={`${subChild.name}jamiat`} id={`${subChild.name}jamiatOwn`} value={0} checked={subChild.jamiatPermission == 0 ? true : false} handleChange={(e) => jamaatPermissionOnChange(subChild, 2, 'jamiat', e.target.value)} />
                                      <RadioButton label={'All'} name={`${subChild.name}jamiat`} id={`${subChild.name}jamiatAll`} value={1} checked={subChild.jamiatPermission == 1 ? true : false} handleChange={(e) => jamaatPermissionOnChange(subChild, 2, 'jamiat', e.target.value)} />
                                      <RadioButton label={'Custom'} name={`${subChild.name}jamiat`} id={`${subChild.name}Custom`} value={2} checked={subChild.jamiatPermission == 2 ? true : false} handleChange={(e) => jamaatPermissionOnChange(subChild, "custom2", 'jamiat', e.target.value)} />
                                      <span>
                                        {subChild.jamiatPermission == 2 &&
                                          <SelectField isMulti={true} options={jamiats}
                                            value={watch(`${subChild.name}Jamiat`) || ''}
                                            register={{ ...register(`${subChild.name}Jamiat`) }}
                                            name={`${subChild.name}Jamiat`}
                                            placeholder={'Select Jamiat'}
                                            control={control}
                                          />
                                        }
                                      </span>
                                    </div>
                                    {subChild.jamiatPermission == 0 ?
                                      <div className="radio-wrapper">
                                        <p className='para'>Jamaat</p>
                                        <RadioButton label={'Own'} name={`${subChild.name}jamaat`} id={`${subChild.name}jamaatOwn`} value={0} defaultChecked={subChild.jamiatPermission == 0 ? true : false} handleChange={(e) => jamaatPermissionOnChange(subChild, 2, 'jamaat', e.target.value)} />
                                        <RadioButton label={'All'} name={`${subChild.name}jamaat`} id={`${subChild.name}jamaatAll`} value={1} defaultChecked={subChild.jamiatPermission == 1 ? true : false} handleChange={(e) => jamaatPermissionOnChange(subChild, 2, 'jamaat', e.target.value)} />
                                      </div>
                                      :
                                      <div className="radio-wrapper">
                                        <p className='para'>Jamaat</p>
                                        <RadioButton label={'Own'} name={`${subChild.name}jamaat`} id={`${subChild.name}jamaatOwn`} value={0} disabled={true} checked={false} handleChange={(e) => jamaatPermissionOnChange(subChild, 2, 'jamaat', e.target.value)} />
                                        <RadioButton label={'All'} name={`${subChild.name}jamaat`} id={`${subChild.name}jamaatAll`} value={1} checked={true} handleChange={(e) => jamaatPermissionOnChange(subChild, 2, 'jamaat', e.target.value)} />
                                      </div>
                                    }
                                  </div>
                                  : ''}
                                </div>
                              ))}
                            </div>
                            : ""}
                        </div>
                      ))}
                    </div>
                    : ""
                  }

                </div>
              </div>
            )
          })}


        </div>
      }
    </div>
  </div>
)
    }

export default AdminRoleAccess