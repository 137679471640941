import React from 'react'

const InputField = ({fieldPlaceholder, style, fieldType, fieldLabel, classname, name, register, error, value, handleChange, val, handleChng, disabled, roleEdit,minValue}) => {
  return (
    <div className="field" style={style}>
      {fieldLabel ? 
        <label>{fieldLabel}</label>
      :''}
      {register && !val ?
        <>
          <input type={fieldType} name={name} className={`input-field ${classname}`} placeholder={fieldPlaceholder}
          {...register} disabled={disabled} />
        </>
      : 
      register && handleChange ?
        <>
          <input type={fieldType} name={name} className={`input-field ${classname}`} placeholder={fieldPlaceholder}
          {...register} onChange={handleChange} />
        </>
        :
      roleEdit ? 
      <input type={fieldType} name={name} className={`input-field ${classname}`} onChange={handleChange}    placeholder={fieldPlaceholder}
       defaultValue={val ? val : value} />
      :
      minValue ? 
      <input type={fieldType} name={name} min={0}  required="true"  className={`input-field ${classname}`} placeholder={fieldPlaceholder} onChange={handleChange}
       value={val ? val : value} /> :
      <input type={fieldType} name={name} className={`input-field ${classname}`} placeholder={fieldPlaceholder} onChange={handleChange}
       value={val ? val : value} />
      }
      {error?<p className='error-message'>{error}</p>:''}
    </div>
  )
}

export default InputField