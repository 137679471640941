import React, { useEffect, useState } from 'react'
import { themeColor } from '../../../config'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import SelectField from '../../../components/SelectField'
import { ByVenueCommunicationSchema } from '../../../utils/Schemas'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import TextEditor from '../../../components/TextEditor'
import { venueOptions, teamData, templateOptions } from '../../../appContent'
import { useDispatch, useSelector } from 'react-redux'
import { AllVenueAction } from '../../../store/actions/venueAction'
import { get, isEmpty } from "lodash";
import InputField from '../../../components/inputField'
import { handleVenueSelect, replaceStringEmail } from '../../../utils/helper'
import { communicationByVenue } from '../../../store/actions/communicationAction'

const ByVenue = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const [btnLoader, setBtnLoader] = useState(false)
  const [venuesList, SetVenueList] = useState([])
  const venusData = useSelector((state) => state.venuereducer)
  const communicationData = useSelector((state) => state.communicationreducer)

  const { handleSubmit, register, reset, formState: { errors }, control, setValue } = useForm({
    resolver: yupResolver(ByVenueCommunicationSchema)
  })

  const submitAction = (data) => {
    let sendEmail = {
      MiqaatId: params.id,
      VenueIds: handleVenueSelect(data),
      subject: data.subject,
      htmlBody: replaceStringEmail(data.message)
    }
    dispatch(communicationByVenue(sendEmail))
    reset()
    setValue('venue', '')
    setValue('message', '')
    setBtnLoader(false)
  }

  useEffect(() => {
    dispatch(AllVenueAction(params?.id))
  }, [])

  useEffect(() => {
    if (!isEmpty(get(venusData, 'venusData'))) {

      let newVenue = []
      venusData?.venusData.map((res) => {
        let data = {
          id: res.id,
          label: res.name,
          value: res.name
        }
        newVenue.push(data)
      })
      newVenue.unshift({ id: -1, label: "All Select", value: -1 })
      SetVenueList(newVenue)
    }
  }, [get(venusData, 'venusData')])


  return (
    <div className='main-wrapper'>
      <div className="container">

        <Row className='justify-content-center'>
          <Col lg={7} sm={12}>
            <div className="app-head">
              <Link to={`/view-miqaat/${params?.id}`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack /></Link>
              <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>By Zone</h2>
            </div>
            <div className="form-wrapper">
              <form onSubmit={handleSubmit(submitAction)}>

                <SelectField isMulti={true} fieldLabel={'Zone:'} options={venuesList} name={'venue'}
                  placeholder={'Select Zone'} register={{ ...register('venue') }}
                  error={errors.venue?.message} control={control} />

                {/* <SelectField isMulti={true} fieldLabel={'Template:'} options={templateOptions} name={'Template'}
                            placeholder={'Select Template'} register={{ ...register('Template') }}
                            error={errors.Template?.message} control={control} /> */}
                <InputField fieldPlaceholder={"Enter Subject"} name={'subject'} fieldType={'text'} fieldLabel={'Subject:'}
                  error={errors.subject?.message} register={{ ...register("subject") }} />

                <TextEditor placeholder={'Enter Message'} name={'message'}
                  label={'Message:'} register={{ ...register('message') }}
                  error={errors.message?.message} control={control} />

                <div className="btn-wrapper">
                  <Link to={`/view-miqaat/${params?.id}`}><Button variant="danger">Cancel</Button></Link>
                  <Button variant="success" type='submit' disabled={communicationData?.loading} >{communicationData?.loading ? 'Sending' : 'Send'}{
                    communicationData?.loading
                      ? <div className='loader-wrapper btn-loader'>
                        <Spinner animation="border" variant="white" />
                      </div>
                      : ''
                  }</Button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ByVenue
