import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { Col, Row, Spinner } from 'react-bootstrap'
import { themeColor } from '../../../config'
import { GetSingleTicketAction, SINGLE_TICKET } from '../../../store/actions/ticketAction'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, get } from 'lodash'
import { dateFormatFunction, dateTimeFormatFunction } from '../../../utils/dateFormat'

const ViewTicket = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const [pageLoader, setPageLoader] = useState(false)
    const [singleTicketData, setSingleTicketData] = useState({})
    const ticketData = useSelector((state) => state.ticketreducer)

  useEffect(() =>{
    if(params?.ticketId)
    dispatch(GetSingleTicketAction(params?.ticketId))
  }, [params?.ticketId])
  useEffect(() => {
    setPageLoader(false)
  }, [])

useEffect(() => {
    if (params?.ticketId && ticketData && ticketData?.singleTicket) {
        setSingleTicketData(ticketData?.singleTicket)
    }
  }, [params])


const handleBack = () => {
    dispatch({
      type: SINGLE_TICKET,
      singleTicket: {}
    })
  }

  return (
    <div className='main-wrapper'>
        <div className="container">
            <Row className='justify-content-center'>
                <Col lg={7} sm={12}>
                    <div className="app-head">
                        <Link to={`/view-miqaat/${params?.id}/ticket`} onClick={handleBack} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack/></Link>
                        <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>VIEW TICKET</h2>
                    </div>

                    {ticketData?.loading
                      ? <div className='loader-wrapper'>
                            <Spinner animation="border" variant="success" />
                        </div>
                      : <div className="form-wrapper-view">
                            <div className="ticket-detail">
                                <p className='view-heading'> Ticket Title :  </p>
                                <p className='radio-para'> {ticketData?.singleTicket?.name}</p>
                            </div>
                            <div className="ticket-detail">
                                <p className='view-heading'>Created By :  </p>
                                <p className='radio-para'>  {ticketData?.singleTicket?.generatedByDetail?.fullName}</p>
                            </div>
                            <div className="ticket-detail">
                                <p className='view-heading'>Created On  : </p>
                                <p className='radio-para'>  {dateTimeFormatFunction(ticketData?.singleTicket?.insertedAt)}</p>
                            </div>
                            <div className="ticket-detail">
                                <p className='view-heading'>Priority : </p>
                                <p className='radio-para'>  {ticketData?.singleTicket?.priority}</p>

                            </div>
                            <div className="ticket-detail">
                                <p className='view-heading'>Status :  </p>
                                <p className='radio-para'>  {ticketData?.singleTicket?.status}</p>
                            </div>
                            <div className="ticket-detail">
                                <p className='view-heading'>Venue : </p>
                                <p className='radio-para'>  {ticketData?.singleTicket?.venueName}</p>
                            </div>

                            <div className="ticket-detail">
                                <p className='view-heading'>Team Name : </p>
                                <p className='radio-para'>  {ticketData?.singleTicket?.teamName ? ticketData?.singleTicket?.teamName : '-'}</p>
                            </div>
                            <div className="ticket-detail">
                                <p className='view-heading'>Description : </p>
                                <p className='radio-para'>  {ticketData?.singleTicket?.additionalInfo}</p>
                            </div>
                            <div className='ticket-image'>
                        {ticketData?.singleTicket?.images?.map((venue) => (

                        <div className="ticket-view-image" >
                          <img src={`data:image/png;base64,${venue}`} className="fr-fic fr-dib\" width="150\" height="151\"/>
                        </div>
                        ))}
                            </div>
                            {/* <p>hii</p> */}

                            {/* <TextEditor
                            placeholder={'Enter Message'}
                            name={'htmlBody'}
                            register={{...register("htmlBody")}}
                            error={errors.htmlBody?.message}
                            control={control}
                            /> */}
                    </div>
                    }

                </Col>
            </Row>
        </div>
    </div>
  )
}

export default ViewTicket
