import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { themeColor } from '../../../config';
import { AttendeesReportAction } from '../../../store/actions/MiqaatReportAction';

const AttendeesReport = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const attendeesData = useSelector((state)=> state.MiqaatReportReducer.attandeesData)
    const loader = useSelector((state)=> state.MiqaatReportReducer.loading)


    useEffect(()=>{
        dispatch(AttendeesReportAction(params))
    },[])

    const List = ({item}) => {
        return (
            <>
                <div  className='chart-count' style={{ color: `${themeColor.secondaryColor}` }}>
                    <b>{item.label || 'Unknown'} :  </b>
                    <p style={{ marginLeft: "10px" }}>{item.count}</p>
                </div>
            </>
        )
    }

    return ( 
        <div className='main-wrapper'>
            {loader ?
                <div className='loader-wrapper'>
                    <Spinner animation="border" variant="success" />
                </div>
                :
                <div className="container ">
                    <div className="app-head" style={{marginBottom: '60px'}}>
                        <Link to={`/view-miqaat/${params.id}/report`} className='back-btn' style={{color: `${themeColor.primaryColor}`}}><IoIosArrowBack/></Link>
                        <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>Attendees Stats</h2>
                    </div>
                    <div className='chart-report' style={{background: `${themeColor.lightGreen}`,marginRight:"20px",width:"100%"}}>
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between' }} className='chart-details attendees'>
                            <div className='report-grid-wrapper'>
                                <div className="head report-grid" >
                                    <h5>Gender Count:</h5>
                                    {
                                        attendeesData.gender?.length > 0 ? 
                                        <>
                                            {attendeesData.gender?.map((item)=>(
                                                <List item={item}/>
                                            ))}
                                        </>
                                        :
                                        <div><p style={{marginBottom: '0'}}>-</p></div>
                                    }
                                </div>
                                <div className="head report-grid" >
                                    <h5>Venues Count:</h5>
                                    {
                                        attendeesData.venues?.length > 0 ? 
                                        <>
                                            {attendeesData.venues?.map((item)=>(
                                                <List item={item}/>
                                            ))}
                                        </>
                                        :
                                        <div><p style={{marginBottom: '0'}}>-</p></div>
                                    }
                                </div>
                            </div>
                            <div className="head report-grid" >
                                <h5>Jamiat Count:</h5>
                                {
                                    attendeesData.jamiat?.length > 0 ? 
                                    <>
                                        {attendeesData.jamiat?.map((item)=>(
                                            <List item={item}/>
                                        ))}
                                    </>
                                    :
                                    <div><p style={{marginBottom: '0'}}>-</p></div>
                                }
                            </div>
                            <div className="head report-grid" >
                                <h5>Teams Count:</h5>
                                {
                                    attendeesData.teams?.length > 0 ? 
                                    <>
                                        {attendeesData.teams?.map((item)=>(
                                            <List item={item}/>
                                        ))}
                                    </>
                                    :
                                    <div><p style={{marginBottom: '0'}}>-</p></div>
                                }
                            </div>
                        </div>                   
                    </div>
                </div>
            }
        </div>
    )
}

export default AttendeesReport;