import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import {GrFormView} from 'react-icons/gr';
import {BsFilter} from 'react-icons/bs';
import {GrClose} from 'react-icons/gr';
import {TbFileExport} from 'react-icons/tb'
import { useDispatch } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { profileUrl, themeColor } from '../../config';
import InputField from '../../components/inputField';
import SelectField from '../../components/SelectField';
import { GetFetch } from '../../utils/fetchUrl';
import FilterValues from '../../components/FilterValues';
import { ALERT_ERROR } from '../../store/reducers/alert';
import placeholderUserImg from "../../assets/images/profile.png";
import { CSVLink } from "react-csv";

const ViewUser = () => {
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState();
  const [openViewUserPopup, setOpenViewUserPopup] = useState(false);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [viewUserDetails, setViewUserDetails] = useState();
  const [viewUserId,setViewUserId] = useState();
  const [usersDetails, setUsersDetails] = useState();
  const [loader, setLoader] = useState(false);
  const [jamaats, setJamaats] = useState();
  const [jamiats, setJamiats] = useState();
  const [position, setPosition] = useState();
  const [filterp,setFilterP] = useState([])
  const [jamaatDetails, setJamaatDetails] = useState([]);
  const [jamiatDetails, setJamiatDetails] = useState();
  const [positionsDetails, setPositionsDetails] = useState();
  const [defaultPositionsDetails, setDefaultPositionsDetails] = useState([]);
  const [lastUserResult,setLastUserResult] = useState([])
  const [jamiatFilter, setJamiatFilter] = useState({
    value: []
  });
  const [jamaatFilter, setJamaatFilter] = useState({
    value: []
  });
  const [positionFilter, setPositionFilter] = useState({
    value: []
  });
  const [viewFilters, setViewFilters] = useState(false);
  const [removedFilter, setRemovedFilter] = useState(0);
  const [filteredUserDetails, setFilteredUserDetails] = useState([]);
  const [clickFilter,setClickFilter] = useState(false)
  const [csvData,setCsvData] = useState([])

  const dispatch = useDispatch();

  const fetchUsersDetails = () => {
    setLoader(true);
    GetFetch(`/api/User/view`).then((response) => {
        if (response.status == 200){
            setUsersDetails(response.data);
        }
        else {
          showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
        }
        setLoader(false);
    }).catch((err)=>{
        console.log('error', err);
        showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
        setLoader(false);
    })
  }
  const fetchJamaat = () => {
      
    GetFetch(`/api/Jamaat`).then((response) => {
        if (response.status == 200){
            setJamaatDetails(response.data);
        }
        
    }).catch((err)=>{
        console.log('error', err);
    })
  }
  const fetchJamiat = () => {
    
    GetFetch(`/api/Jamiat`).then((response) => {
        if (response.status == 200){
            setJamiatDetails(response.data);
        }
        
    }).catch((err)=>{
        console.log('error', err);
    })
  }
  const fetchPositions = () => {
    
    GetFetch(`/api/Positions`).then((response) => {
        if (response.status == 200){
            setPositionsDetails(response.data);
        }
    }).catch((err)=>{
        console.log('error', err);
    })
  }

  const onSearch = (e) => {
    let val = e.target.value
    let checkString = val.substr(0,1)
    if(isNaN(checkString)){
      if(e){
        let val = e.target.value
        if(val.length){
          setSearch(val);
        }
        else{
          setSearch("")
        }
        if(val == ""){    
          if(viewFilters){
            setFilteredUserDetails(lastUserResult);
          }else{
            setFilteredUserDetails(usersDetails);
          }
        }
        else if(lastUserResult && lastUserResult.length){
          let filterResult =  lastUserResult.filter(item => item.fullName.toLowerCase().includes(val.toLowerCase())) 
          setFilteredUserDetails(filterResult); 
        }
        else{
          let filterResult =  usersDetails.filter(item => item.fullName.toLowerCase().includes(val.toLowerCase())) 
          setFilteredUserDetails(filterResult);
        }
      }
    }else {
      if(e.target.value.length <= 8){
        let val = e.target.value
        if(val.length < 9 ){
          setSearch(val);
        }
        if(val == ""){
          if(viewFilters){
            setFilteredUserDetails(lastUserResult);
          }else{
            setFilteredUserDetails(usersDetails);
          }
        }
        else if(lastUserResult && lastUserResult.length){
          let filterResult =  lastUserResult.filter(item => item.itsid.toString().includes(val)) 
          setFilteredUserDetails(filterResult); 
        }
        else{
          let filterResult =  usersDetails.filter(item => item.itsid.toString().includes(val)) 
          setFilteredUserDetails(filterResult);
        }
      }
    }
  }

  const showErrorAlert = (msg) => {
    dispatch({
        type: ALERT_ERROR,
        payload: msg
    })
}

  const closeFilterPopup = () => {
    setOpenFilterPopup(false);
  }
  const applyFilter = () => {
    if(jamaatFilter && jamaatFilter.value.length > 0 || jamiatFilter && jamiatFilter.value.length > 0
    || positionFilter && positionFilter.value.length > 0){
      setViewFilters(true);
    }else{
      setViewFilters(false);
    }
    if(filteredUserDetails && filteredUserDetails.length > 0){
      let filterUser = []
      if(jamiatFilter && jamiatFilter.value.length > 0){
        jamiatFilter.value.map((item)=>{
          usersDetails.map((jamiatCity)=>{
            if(item.label == jamiatCity.jamiat){
              filterUser.push(jamiatCity)
            }
          })
        })
        if(jamaatFilter && jamaatFilter.value.length > 0){
          let filterJamaat = []
          jamaatFilter.value.map((item)=>{
            filterUser.map((jamiatCity)=>{
              if(item.label == jamiatCity.jamaat){
                filterJamaat.push(jamiatCity)
              }
            })
          })
          filterUser = filterJamaat
        }
        if(positionFilter && positionFilter.value.length > 0){
          let filterPosition = []
          positionFilter.value.map((item)=>{
            filterUser.map((jamiatCity)=>{
              if(item.label == jamiatCity.positionName){
                filterPosition.push(jamiatCity)
              }
            })
          })
          filterUser = filterPosition
        }
        setFilteredUserDetails(filterUser)
        setLastUserResult(filterUser)

      }
      else if(jamaatFilter && jamaatFilter.value.length > 0){
        let filterJamaat = [];
        jamaatFilter.value.map((item)=>{
          usersDetails.map((jamiatCity)=>{
            if(item.label == jamiatCity.jamaat){
              filterJamaat.push(jamiatCity)
            }
          })
        })
        if(positionFilter && positionFilter.value.length > 0){
          let filterPosition = []
          positionFilter.value.map((item)=>{
            filterJamaat.map((jamiatCity)=>{
              if(item.label == jamiatCity.positionName){
                filterPosition.push(jamiatCity)
              }
            })
          })
          filterJamaat = filterPosition
        }
        setFilteredUserDetails(filterJamaat)
        setLastUserResult(filterJamaat)

      }
      else if(positionFilter && positionFilter.value.length > 0){
        let filterPosition = [];
        positionFilter.value.map((item)=>{
          usersDetails.map((jamiatCity)=>{
            if(item.label == jamiatCity.positionName){
              filterPosition.push(jamiatCity)
            }
          })
        })
        setFilteredUserDetails(filterPosition)
        setLastUserResult(filterPosition)

      }
      else{
        setFilteredUserDetails(usersDetails);
      }
    }
    setOpenFilterPopup(false);
    setSearch("")
  }
  const clearFilter = () => {
    setJamaatFilter({value: []});
    setJamiatFilter({value: []});
    setPositionFilter({value: []});
    setViewFilters(false);
    setFilteredUserDetails(usersDetails)
  }
  const removeFilter = (value, filterName) => {
    if(filterName === 'jamiat'){
      let filterData = []
      jamiatFilter && jamiatFilter.value.length && jamiatFilter.value.map((item,i)=>{
        if(value != item.value){
          filterData.push(item) 
        }
      })
        setJamiatFilter({
          value: filterData
        })
      setClickFilter(!clickFilter)
    }
    else if(filterName === 'jamaat'){
      let filterData = []
      jamaatFilter && jamaatFilter.value.length && jamaatFilter.value.map((item,i)=>{
        if(value != item.value){
          filterData.push(item) 
        }
      })
      setJamaatFilter({
        value: filterData
      })
      setClickFilter(!clickFilter)
    }
    else if(filterName === 'position'){
      let filterData = []
      positionFilter && positionFilter.value.length && positionFilter.value.map((item,i)=>{
        if(value != item.value){
          filterData.push(item) 
        }
      })
      setPositionFilter({
        value: filterData
      })
      setClickFilter(!clickFilter)
    }
    setRemovedFilter(prev=>prev + 1);
  }

  useEffect(()=>{
      applyFilter()
  },[clickFilter])

  const columns = [
    // {
    //   name: '',
    //   cell: (row) =>filteredUserDetails && filteredUserDetails.length > 0 ?
    //   <img className='user-profile' src={`${profileUrl}${row.itsid}.png`} onError={(e) => e.target.src = placeholderUserImg} alt='Profile'/>:""
    // },
    {
      name: 'Name',
      selector: (row) => row.fullName ? row.fullName : ' ',
      sortable: true,
    },
    {
      name: 'ITS No.',
      selector: (row) => row.itsid ? row.itsid : ' ',
      sortable: true,
      minWidth: '130px',
      maxWidth: '130px'
    },
    {
      name: 'Jamaat',
      selector: (row) => row.jamaat ? row.jamaat : ' '
    },
    {
      name: 'Jamiat',
      selector: (row) => row.jamiat ? row.jamiat : ' ',
      minWidth: '130px',
      maxWidth: '130px'
    },
    {
      name: 'Position',
      selector: (row) => row.positionName ? row.positionName : ' ',
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) =>
      filteredUserDetails && filteredUserDetails.length ?
      <button className='action-btn' style={{color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}`}} onClick={()=>{setViewUserId(row.itsid);
        setOpenViewUserPopup(true);}} ><GrFormView/></button>:"",
        minWidth: '90px',
        maxWidth: '90px'
    },
  ]

  useEffect(()=>{
    fetchUsersDetails();
  }, [])
  
  useEffect(()=>{
    if(viewFilters == false){
      setFilteredUserDetails(usersDetails)
    }
  }, [viewFilters])

  useEffect(()=>{
    if(removedFilter > 0){
      if(jamaatFilter && jamaatFilter.value.length <= 0 && jamiatFilter && jamiatFilter.value.length <= 0
        && positionFilter && positionFilter.value.length <= 0){
          setViewFilters(false);
        }
    }
  },[removedFilter])

  useEffect(()=>{
    setFilteredUserDetails(usersDetails);
  }, [usersDetails])

  useEffect(()=>{
    if(usersDetails){
      const result =usersDetails && usersDetails.filter((user)=>{
        return user.itsid && user.itsid.toString().match( viewUserId && viewUserId.toString())
      });
      setViewUserDetails(result);
    }
  }, [viewUserId])

  useEffect(()=>{
    fetchUsersDetails();
    fetchJamaat();
    fetchJamiat();
    fetchPositions();
  }, []);

  useEffect(()=>{
      if(jamaatDetails && jamiatFilter && jamiatFilter.value.length <= 0 ){
          setJamaats(
            jamaatDetails.map((jamaat)=>(
                  {value: jamaat.jamaatId, label: jamaat.name}
              ))
          )
      }
  }, [jamaatDetails]);

  useEffect(()=>{
    if(jamaatDetails && jamiatFilter && jamiatFilter.value.length > 0 ){
        setPositionFilter({value: []})
        let filteredJamaats = []
        jamiatFilter.value.map((filterJ)=>{
         jamaatDetails.map((item)=>{
            if(filterJ.label == item.jamiat){
              filteredJamaats.push({value: item.jamaatId, label: item.name})
            }
          })
        })
        setJamaats(filteredJamaats)
      let newJamaatFilter = []
      if(jamaatFilter && jamaatFilter.value.length){
        jamaatFilter.value.map((item,i)=>{
          filteredJamaats && filteredJamaats.map((jamaat,i)=>{
            if(item.label.includes(jamaat.label)){
              newJamaatFilter.push(item) 
            }
          })
        })
      }
      let setData = [...new Set(newJamaatFilter)];
      setJamaatFilter({value: setData});
        let filterPositions = []
        let positionFilterData = []
        filteredJamaats.map((item,i)=>{
          usersDetails && usersDetails.map((viewData,i)=>{
            if(item.label == viewData.jamaat){
              positionFilterData.push(viewData.positionName)
            }
          })
        })
        let newPosition = [...new Set(positionFilterData)]
        newPosition && newPosition.map((item,i)=>{
          defaultPositionsDetails && defaultPositionsDetails.map((position,i)=>{
            if(item == position.label){
              filterPositions.push(position)
            }
          })
        })
        setPosition(filterPositions)
        setFilterP(filterPositions)
      }
      else{
        setJamaats(
          jamaatDetails.map((jamaat)=>(
                {value: jamaat.jamaatId, label: jamaat.name}
            ))
        )
        setPosition(defaultPositionsDetails)
      }
  }, [jamiatFilter])

  useEffect(()=>{
    if(jamaatDetails && jamaatFilter && jamaatFilter.value.length > 0 ){
      setPositionFilter({value: []})
      let filterPositions = []
      let positionFilterData = []
      jamaatFilter && jamaatFilter.value.map((item,i)=>{
        usersDetails && usersDetails.map((viewData,i)=>{
          if(item.label == viewData.jamaat){
            positionFilterData.push(viewData.positionName)
          }
        })
      })
      let newPosition = [...new Set(positionFilterData)]
      newPosition && newPosition.map((item,i)=>{
        defaultPositionsDetails && defaultPositionsDetails.map((position,i)=>{
          if(item == position.label){
            filterPositions.push(position)
          }
        })
      })
      setPosition(filterPositions)
    }
    else {
      if(jamiatFilter && jamiatFilter.value.length > 0){
        setPosition(filterp)
      }else{
        setPosition(defaultPositionsDetails)
      }
    }
  },[jamaatFilter])

  useEffect(()=>{
      if(jamiatDetails){
          setJamiats(
              jamiatDetails.map((jamiat)=>(
                  {value: jamiat.jamiatId, label: jamiat.name}
              ))
          )
      }
  }, [jamiatDetails])

  useEffect(()=>{
    if(positionsDetails){
        setPosition(
            positionsDetails.map((jamiat)=>(
                {value: jamiat.id, label: jamiat.name}
            ))
        )
        setDefaultPositionsDetails(
            positionsDetails.map((jamiat)=>(
                {value: jamiat.id, label: jamiat.name}
            ))
        )
    }
}, [positionsDetails])

useEffect(()=>{
  if(filteredUserDetails && filteredUserDetails.length){
    setCsvData(filteredUserDetails)
  }
},[filteredUserDetails])
  if (loader) {
    return(
        <>
            <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
            </div>
        </>
    )
}
else {
  
    return (
      <div className="main-wrapper">
          <div className="container">
              <div className="app-head">
                  <Link to='/dashboard' className='back-btn' style={{color: `${themeColor.primaryColor}`}}><IoIosArrowBack/></Link>
                  <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>VIEW USER</h2>
              </div>
              {usersDetails && filteredUserDetails && filteredUserDetails.length ? 
                <>
                  <div className="table-wrapper view-user-table">
                    <DataTable data={filteredUserDetails && filteredUserDetails.length > 0 ? filteredUserDetails : filteredData} columns={columns}
                    pagination fixedHeader fixedHeaderScrollHeight='540px' dense
                    subHeader subHeaderComponent={
                      <div>
                        <InputField fieldPlaceholder={"Search by ITS/Name"} fieldType={'text'} value={search}
                          handleChange={onSearch} />
                          <div className="filter-btn-wrappper">
                            {viewFilters ? 
                              <div className="filter-values-wrapper">
                                <div>
                                  <FilterValues jamiatFilter={jamiatFilter} handleClose={(val, name)=>removeFilter(val, name)} filterType={'jamiat'}/>
                                  <FilterValues jamaatFilter={jamaatFilter} handleClose={(val, name)=>removeFilter(val, name)} filterType={'jamaat'}/>
                                  <FilterValues positionFilter={positionFilter} handleClose={(val, name)=>removeFilter(val, name)} filterType={'position'}/>
                                </div>
                                <button className='link-btn'  style={{marginLeft : '15px', minWidth: '64px', color: `${themeColor.primaryColor}`}} onClick={clearFilter}>Clear All</button>
                              </div>
                              : ''
                            }
                            <div className="btn-wrapper">  
                              <button className='icon-btn' style={{color: `${themeColor.primaryColor}`}} onClick={()=>{setOpenFilterPopup(true)}}><BsFilter/>Filter</button>
                              <CSVLink data={csvData}   filename={"User-Details.csv"}>
                                <button className='icon-btn dark' style={{backgroundColor: `${themeColor.primaryColor}`}}  > <TbFileExport/>Download</button>
                              </CSVLink>
                            </div>
                          </div>
      
                      </div>
                    } subHeaderAlign="left"/>
                  </div>
                </>
                : 
                <>
                <div className="table-wrapper view-user-table">
                  <DataTable data={[{"fullName": "No Data Found"}] } columns={columns}
                  pagination fixedHeader fixedHeaderScrollHeight='540px' dense 
                  subHeader subHeaderComponent={
                    <div>
                      <InputField fieldPlaceholder={"Search by ITS/Name"} fieldType={'text'} value={search}
                        handleChange={onSearch} />
                    </div>
                  } subHeaderAlign="left"/>
                </div>
              </>
              }
              
    
              {openViewUserPopup && viewUserDetails && viewUserDetails.length > 0 ? 
                <div className="popup-wrapper">
                  <div className="popup-container">
                    <div className="popup-head">
                      <img src={`${profileUrl}${viewUserDetails[0].itsid}.png`} onError={(e) => e.target.src = placeholderUserImg} alt="profile" />
                      <div className='details'>
                        <p>{viewUserDetails[0].fullName ? viewUserDetails[0].fullName : '-'}</p>
                        <p style={{color: `${themeColor.primaryColor}`}}>{viewUserDetails[0].itsid ? viewUserDetails[0].itsid : '-'}</p>
                      </div>
                    </div>
                    <div className="user-details">
                      <div>
                        <b>Email ID:</b>
                        <a href={`mailto:${viewUserDetails[0].email}`}>{viewUserDetails[0].email}</a>
                      </div>
                      <div>
                        <b>Phone No.:</b>
                        <a href={`tel:${viewUserDetails[0].mobNo}`}>{viewUserDetails[0].mobNo}</a>
                      </div>
                      <div>
                        <b>Jamiat:</b>
                        <p>{viewUserDetails[0].jamiat ? viewUserDetails[0].jamiat : '-'}</p>
                      </div>
                      <div>
                        <b>Jamaat:</b>
                        <p>{viewUserDetails[0].jamaat ? viewUserDetails[0].jamaat : '-'}</p>
                      </div>
                      <div>
                        <b>Position:</b>
                        <p>{viewUserDetails[0].positionName ? viewUserDetails[0].positionName : '-'}</p>
                      </div>
                    </div>
                    <button className='close-btn' onClick={()=>setOpenViewUserPopup(false)}><GrClose/></button>
                  </div>
                </div>
                : ''
              }
              {openFilterPopup ? 
                <div className="popup-wrapper">
                  <div className="popup-container">
                    <button className='close-btn' onClick={closeFilterPopup}><GrClose/></button>
                    <div className="popup-head">
                      <h4>Filter</h4>
                    </div>
                    <div className="filter-wrapper">
                      <form>
                        <SelectField isMulti={true} className='select-field' placeholder={'Jamiat'} options={jamiats} name={'Jamiat'}
                        value={jamiatFilter.value} handleChange={(e)=>setJamiatFilter({value: e})}/>
                        
                        <SelectField isMulti={true} className='select-field' placeholder={'Jamaat'} options={jamaats} name={'Jamaat'}
                        value={jamaatFilter.value} handleChange={(e)=>setJamaatFilter({value: e})}/>
                        
                        <SelectField isMulti={true} className='select-field' placeholder={'Position'} options={position} name={'Position'}
                        value={positionFilter.value} handleChange={(e)=>setPositionFilter({value: e})}/>
                        
                        <Button variant='success' style={{marginTop: '30px', width: '100%'}} onClick={applyFilter} >Apply Filter</Button>
                      </form>
                    </div>
                  </div>
                </div>
                :
                ''
              }
              
          </div>
      </div>
    )
  
  }
}

export default ViewUser;