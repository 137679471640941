import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { themeColor } from "../../config";
import { IoIosArrowBack } from "react-icons/io";
import InputField from "../../components/inputField";
import { useEffect, useRef, useState } from "react";
import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { useDispatch } from "react-redux";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../store/reducers/alert";
import { Col, Row, Spinner } from "react-bootstrap";
import moment from "moment";

const EmailLogById = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [filterData, setFilterData] = useState([])
    const [emailLogData, setEmailLogData] = useState([])

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }

    const getDetailsById = (id) => {
        setLoader(true);
        GetFetch(`/api/EmailLogs/${id}`).then((response) => {
            if (response.status == 200) {
                setEmailLogData(response?.data)
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            }
            setLoader(false);
        }).catch((err) => {
            console.log('error', err);
            showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
            setLoader(false);
        })
    }

    useEffect(() => {
        if (params && params.id) {
            getDetailsById(params.id)
        }
    }, [])

    const columns = [
        {
            name: 'ITS ID',
            selector: (row) => row.itsid ? row.itsid : ' ',
            sortable: true,
            minWidth: '130px',
            maxWidth: '130px'
        },
        {
            name: "Name",
            selector: (row) => row.fullName ? row.fullName : ' ',
            sortable: true,
            minWidth: '330px',
            maxWidth: '330px'
        },
        {
            name: 'Email',
            selector: (row) => row.email ? row.email : ' ',
            minWidth: '380px',
            maxWidth: '380px'
        },
        {
            name: 'Status',
            selector: (row) => row.status ? row.status : ' ',
            minWidth: '380px',
            maxWidth: '380px'
        },
    ]

    const onSearch = (e) => {
        setSearch(e.target.value)
        if (emailLogData.emailLogDetails && emailLogData.emailLogDetails.length) {
            const filterData = emailLogData.emailLogDetails.filter((item, i) => item?.fullName.toLowerCase().includes(e.target.value.toLowerCase()) 
             || item?.itsid.toString().includes(e.target.value)
            )
            if(filterData.length){
                setFilterData(filterData)
            }else{
                setFilterData([{ "fullName": "No Data Found" }])
            }
        }
    }

    return (
        <div className="main-wrapper">
            {loader ?
                <div className='loader-wrapper'>
                    <Spinner animation="border" variant="success" />
                </div>
                :
                <div className="container">
                    <div className="app-head">
                        <Link to='/email-log' className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack /></Link>
                        <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>Email Log ID : {params.id}</h2>
                    </div>
                    <div className=" container emailLog-card" style={{ background: `${themeColor.lightGreen}` }}>
                        <Row className='justify-content-center'>
                            < div className="emailLog-head">
                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                    <b>Sender ITS ID </b>
                                    <p>{emailLogData.itsid ? emailLogData.itsid : '-'}</p>
                                </div>
                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                    <b>Sender Name </b>
                                    <p>{emailLogData.fullName ? emailLogData.fullName : '-'}</p>
                                </div>
                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                    <b>Date/Time </b>
                                    <p>{emailLogData.logDate ? moment(emailLogData.logDate + "Z").format("lll") : '-'}</p>
                                </div>
                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                    <b>Total Sent </b>
                                    <p>{emailLogData.submittedSuccessfullyCount ? emailLogData.submittedSuccessfullyCount : '0'}</p>
                                </div>
                            </div>
                        </Row>
                        <Row className='justify-content-center'>
                            < div className="emailLog-head">
                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                    <b>Title :-</b>
                                    <p>{emailLogData.subject ? emailLogData.subject : '-'}</p>
                                </div>
                            </div>
                        </Row>
                        <Row className='justify-content-center bodyWrapper' >
                            <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                <b>Body :-</b>
                                <div dangerouslySetInnerHTML={{ __html: `${emailLogData.body}` || '-' }}></div>
                            </div>
                        </Row>
                    </div>
                    {emailLogData.emailLogDetails?.length ?
                        <div className="table-wrapper view-user-table email-log">
                            <DataTable data={filterData && filterData.length ? filterData : emailLogData.emailLogDetails} columns={columns}
                                pagination fixedHeader fixedHeaderScrollHeight='540px' dense
                                subHeader subHeaderComponent={
                                    <div>
                                        <InputField fieldPlaceholder={"Search by ITS/Name"} fieldType={'text'}
                                            value={search} handleChange={onSearch} />
                                    </div>
                                }
                            />
                        </div>
                        :
                        <div className="table-wrapper view-user-table">
                            <DataTable data={[{ "fullName": "No Data Found" }]} columns={columns}
                                pagination fixedHeader fixedHeaderScrollHeight='540px' dense
                                subHeader
                            />
                        </div>
                    }
                </div>
            }
        </div>
    )

}
export default EmailLogById;