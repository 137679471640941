import { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AWS from "aws-sdk";
import moment from "moment";
import Resizer from "react-image-file-resizer";
import { editor_secret_key, editor_access_key } from "../config";

const modules = {
  toolbar: [
    // ... (your existing toolbar configurations)
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    [{ 'color': ['#E7825E'] }, { 'background': [] }],
    ["link"],
    ["clean"],
    ["image"],
],
};

const TextEditor = ({ label, name, error, control }) => {
  const [quill, setQuill] = useState(null);

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 560, 1000, 'JPEG', 100, 0,
    uri => {
      resolve(uri);
    }, 'file' );
});

  useEffect(() => {
    if (quill) {
      const toolbar = quill.getEditor().getModule("toolbar");

      if (toolbar) {
        const imageButton = toolbar.container.querySelector(".ql-image");

        if (imageButton) {
          imageButton.addEventListener("mousedown", handleImageButtonClick);
        }
      }
    }

    return () => {
      // Cleanup event listener on component unmount
      if (quill) {
        const toolbar = quill.getEditor().getModule("toolbar");

        if (toolbar) {
          const imageButton = toolbar.container.querySelector(".ql-image");

          if (imageButton) {
            imageButton.removeEventListener("mousedown", handleImageButtonClick);
          }
        }
      }
    };
  }, [quill]);

  const handleImageButtonClick = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async (changeEvent) => {
      const file = changeEvent.target.files[0];
      if (file) {
        handleImageUpload(file);
      }
    };
  };

  const handleImageUpload = async (file) => {
    const s3 = new AWS.S3({
      secretAccessKey: editor_secret_key,
      accessKeyId: editor_access_key,
    });
  
    const params = {
      Bucket: "hr-nazafat-prod",
      Key: `email-template/${moment(new Date())}`,
      Body: await resizeFile(file),
      ACL: "public-read",
    };
  
    try {
      const data = await s3.upload(params).promise();
      const imageUrl = data.Location;
  
      // Wait for the editor to be ready
      await quill?.getEditor().ready;
  
      // Get the current cursor position
      const cursorPosition = quill?.getEditor().getLength();
  
      // Insert the image into the editor using Quill's built-in image handling
      quill?.getEditor().insertEmbed(cursorPosition, "image", imageUrl, "user");
    } catch (error) {
      console.error("Error uploading image to S3:", error);
  
      // If there's an error, insert a placeholder or handle it as needed
      const cursorPosition = quill?.getEditor().getLength();
      quill?.getEditor().insertText(cursorPosition, "[Image Upload Failed]", "error");
    }
  };

  return (
    <>
      <div className="field text-editor">
      {label ?
            <label>{label}</label>
        : ''
        }
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref } }) => {
            const convertClassesToInlineStyles = () => {
              const editorContent = document.querySelector('.ql-editor');
        
              if (editorContent) {
                  const alignCenterElements = editorContent.querySelectorAll('.ql-align-center');
                  const alignRightElements = editorContent.querySelectorAll('.ql-align-right');
                  const imageElem = editorContent.querySelectorAll(['img']);
                  const paraElem = editorContent.querySelectorAll(['p']);
        
                  alignCenterElements.forEach(element => {
                      element.style.textAlign = 'center';
                      element.classList.remove('ql-align-center');
                  });
                  alignRightElements.forEach(element => {
                      element.style.textAlign = 'right';
                      element.classList.remove('ql-align-right');
                  });
                  imageElem?.forEach(element => {
                      element.style.maxWidth = '560px';
                  });
                  paraElem?.forEach(element => {
                      element.style.margin = '0';
                  });
              }
          };
            return (
            <>
            <ReactQuill
              modules={{
                ...modules,
                toolbar: {
                  container: modules.toolbar,
                },
              }}
              formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'align', 'link', 'image', 'color', 'background', 'code-block']}
              value={value}
              theme="snow"
              onChange={(e)=>{convertClassesToInlineStyles();onChange(e)}}
              onBlur={onBlur}
              placeholder="Content goes here..."
              ref={(el) => setQuill(el)}
            />
            {/* <div dangerouslySetInnerHTML={{ __html: value }} /> */}
            </>
          )}}
        />
        {error ? <p className="error-message">{error}</p> : ""}
      </div>
    </>
  );
};

export default TextEditor;
