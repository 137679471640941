import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";
// import { CANCEL_EDIT_FORM } from "../reducers/questions";

export const AllQuestionAction = (miqaatId) => async (dispatch) => {
    GetFetch(`/api/Questions/get?miqaatId=${miqaatId}`)
        .then((response) => {
            var data = response.data;
            if (response.status === 200) {
                dispatch({
                    type: ALL_QUESTIONS,
                    payload: data,
                });
            } else {
                // dispatch({
                //   type: REQUEST_FAIL,
                // });
                dispatch({
                    type: ALERT_ERROR,
                    payload: "Something went wrong. Please try again later.",
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: ALERT_ERROR,
                payload: error.data.msg || "Something went wrong. Please try again later.",
            });
        });
};

export const AddQuestionAction = (addQuestionDetails, miqaatId, history) => (dispatch) => {
  dispatch({
    type: QUESTION_LOADING,
  });
    PostFetch("/api/Questions/add", addQuestionDetails, 'POST')
        .then((response) => {
            var data = response.data;
            if (response.status === 200) {
                dispatch({
                    type: ADD_QUESTION,
                    payload: {},
                });
                dispatch({
                    type: ALERT_SUCCESS,
                    payload: "Question added successfully",
                });
                history(`/view-miqaat/${miqaatId}/view-question`);
            } else {
                dispatch({
                    type: QUESTION_FAIL,
                    payload: "",
                });
                dispatch({
                    type: ALERT_ERROR,
                    payload: data.error,
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: QUESTION_FAIL,
                payload: "Something went wrong. Please try again later.",
            });
            dispatch({
                type: ALERT_ERROR,
                payload: error.data.msg || "Something went wrong. Please try again later.",
            });
        });

};

export const GetSingleQuestionAction = (questionId) => (dispatch) => {
  dispatch({
    type: QUESTION_LOADING,
  });
    GetFetch(`/api/Questions/getById?id=${questionId}`)
    .then((response) => {
      var SingleQuestion = response.data;
      if (response.status === 200) {
        dispatch({
          type: SINGLE_QUESTION,
          payload: SingleQuestion || "",
        });
      } else {
        dispatch({
          type: QUESTION_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const DeleteQuestionAction = (id) => (dispatch) => {

    PostFetch('/api/Questions/delete', id, 'DELETE')
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: DELETE_QUESTION,
        });
        dispatch(AllQuestionAction(id.miqaatId))
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Question deleted successfully",
        });
      } else {
        dispatch({
          type: QUESTION_FAIL,
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_FAIL,
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const UpdateQuestionAction = (updateDetails, miqaatId, history) => (dispatch) => {
    PostFetch('/api/Questions/edit', updateDetails, 'PUT')
    .then((response) => {
      if (response.status === 202) {
        dispatch({
          type: UPDATE_QUESTION,
          payload: "",
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Question updated successfully",
        });
        history(`/view-miqaat/${miqaatId}/view-question`);
      } else {
        dispatch({
          type: QUESTION_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });

};

export const removeQuestions = () => (dispatch) => {
  dispatch({
    type: REMOVE_QUESTIONS
  })
}

export const QUESTION_LOADING = "QUESTION_LOADING";
export const ALL_QUESTIONS = "ALL_QUESTIONS";
export const QUESTION_ERROR = "QUESTION_ERROR";
export const PAGINATE_QUESTIONS = "PAGINATE_QUESTIONS";
export const QUESTION_FAIL = "QUESTION_FAIL";
export const ADD_QUESTION = "ADD_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const SINGLE_QUESTION = "SINGLE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const REMOVE_QUESTIONS= 'REMOVE_QUESTIONS'


