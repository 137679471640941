import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { InventoryReportCards } from '../../../appContent';
import { themeColor } from '../../../config';
import Card from '../../../components/Card';

const InventoryReport = () => {
    const params = useParams()
  return (
    <>
      <div className='main-wrapper'>
          <div className='container'>
            <div className="app-head">
                <Link to={`/view-miqaat/${params.id}`} className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack/></Link>
                <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>Inventory Report</h2>
            </div>
            <div className="card-wrapper">
                {InventoryReportCards.map((cardInfo)=>{
                    return <Card key={cardInfo.optionId} cardDetails={cardInfo} /> 
                  })}
            </div>
          </div>
      </div>
  </>
  )
}

export default InventoryReport