import React, { useState } from "react";

const UploadAndDisplayImage = ({selectedImage, label, setSelectedImage, convertToBase64}) => {

  return (
    <div className="upload_wrapper">
      <label htmlFor="myImage" style={{marginBottom: '6px', color: '#696969', fontSize: '14px', fontWeight: '500'}}>{label}</label>
      {selectedImage ? (
        <div className="upload_image_wrapper">
          <img
            alt="not found"
            width={"250px"}
            src={selectedImage}
          />
          <button onClick={() => setSelectedImage(null)}>Remove</button>
        </div>
      )
        :
        <>
          <input
              type="file"
              name="myImage"
              onChange={(event) => {
              convertToBase64(event);
              }}
          />
        </>
    }

    </div>
  );
};

export default UploadAndDisplayImage;