import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IoMdAddCircle } from 'react-icons/io'
import { MdModeEdit, MdDeleteOutline } from 'react-icons/md'
import { IoIosArrowBack } from 'react-icons/io'
// import { AiOutlineFundView } from 'react-icons/ai';
import { GrFormView } from 'react-icons/gr'
import { Spinner } from 'react-bootstrap'
import { themeColor } from '../../config'
import DataTable from 'react-data-table-component'
import InputField from '../../components/inputField'
import { miqaatTableData } from '../../appContent'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { AllMeqaatAction, DeleteMiqaatAction } from '../../store/actions/miqaatAction'
import moment from 'moment';
import { dateFormatFunction, newDateFormatFunction } from '../../utils/dateFormat'
import AppModal from '../../components/Modal'
const Miqaat = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [loader, setLoader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [deleteMiqaatId, setDeleteMiqaatId] = useState('')
  const miqaatData = useSelector((state) => state.miqaatreducer.miqaatData)

  const columns = [
    {
      name: 'Name',
      selector: (row) => row?.name,
      minWidth: '240px',
    },
    {
      // `${row.miqaatDate.toLocaleString('en-us', { weekday: 'long' }).toString()}, ${row.miqaatDate.toISOString().slice(0, 10).toString()}`
      name: 'Miqaat Date',
      selector: (row) => newDateFormatFunction(row?.miqaatDate),
      maxWidth: '180px',
      minWidth: '160px'
    },
    {
      name: 'Status',
      selector: (row) => `${row?.status === 1 ? 'Active' : 'Inactive'}`,
      maxWidth: '110px'
    },
    {
      name: 'Actions',
      cell: (row) => <p className='action-btn-wrapper'>
        <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat/${row.id}`}>
          <GrFormView />
        </Link>
        {/* <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat-report/${row.id}`}>
          <AiOutlineFundView />
        </Link> */}
        <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat/edit/${row.id}`} >
          <MdModeEdit />
        </Link>
        <button className='action-btn' style={{ color: 'red', background: `${themeColor.lightGreen}` }} onClick={() => deleteVenue(row.id)} >
          <MdDeleteOutline />
        </button>
      </p>,
      maxWidth: '170px'
    }
  ]

  useEffect(() => {
    setLoader(true)
    dispatch(AllMeqaatAction())
  }, [])

  useEffect(() => {
    if (miqaatData?.length > 0) {
      setFilteredData(miqaatData)
    }
  }, [miqaatData])

  useEffect(() => {
    if (search.length > 0) {
      const result = miqaatData?.filter(data => Object.keys(data).some(key => {
        return String(data[key]).toLowerCase().includes(search.toLowerCase())
      }))
      setFilteredData(result)
    } else {
      setFilteredData(miqaatData)
    }
  }, [search])

  useEffect(() => {
    if (filteredData?.length > 0) {
      setLoader(false)
    } else {
      setTimeout(() => {
        setLoader(false)
      }, 800);
    }
  }, [filteredData])

  const deleteVenue = (id) => {
    setDeleteMiqaatId(id)
    setShowModal(true)
  }

  const confirDelete = () => {
    dispatch(DeleteMiqaatAction(deleteMiqaatId))
    setShowModal(false)
  }


  return (
    <div className='main-wrapper'>
      <div className="container">
        <div className="app-head">
          <Link to='/' className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack /></Link>
          <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>LIST OF MIQAAT(s)</h2>
        </div>
        {loader
          ? <div className='loader-wrapper'>
            <Spinner animation="border" variant="success" />
          </div>
          :
          <div className="table-wrapper  action-table">
            <DataTable data={filteredData} columns={columns}
              fixedHeader fixedHeaderScrollHeight='482px'
              subHeader pagination
              subHeaderComponent={
                <div>
                  <InputField className={'search-field'} fieldPlaceholder={'Search Miqaat'} fieldType={'text'} value={search}
                    handleChange={(e) => setSearch(e.target.value)} />
                  <Link to={'/view-miqaat/add'} className='icon-btn dark' style={{ background: `${themeColor.primaryColor}` }}><IoMdAddCircle />Add Miqaat</Link>
                </div>} subHeaderAlign="left" />
          </div>
        }
        {/* {filteredData && filteredData.length == 0 ?
            <div className="table-wrapper view-user-table">
            <DataTable data={[{"fullName": "No Data Found"}] } columns={columns}
            pagination fixedHeader fixedHeaderScrollHeight='540px' dense 
            subHeader subHeaderComponent={
              <div>
                <InputField fieldPlaceholder={"Search by ITS/Name"} fieldType={'text'} value={search}
                   />
              </div>
            } subHeaderAlign="left"/>
          </div> 
            : null} */}


        <AppModal handleClose={() => setShowModal(false)} showModal={showModal} hasBody={true} title={'Confirm Delete'}
          handleAction={confirDelete} content='Are you sure, You want to delete Miqaat?' actionBtnText={"Confirm"} />
      </div>
    </div>
  )
}

export default Miqaat
