import React from 'react';
import { Link } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { themeColor } from '../config';

const PrivacyPolicy = () => {
  return (
    <div className='main-wrapper'>
        <div className="container">
            <div className="app-head">
                <Link to='/' className='back-btn' style={{color: `${themeColor.primaryColor}`}}><IoIosArrowBack/></Link>
                <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>Privacy policy</h2>
            </div>
            
        </div>
    </div>
  )
}

export default PrivacyPolicy