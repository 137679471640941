import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { themeColor } from '../../config'

import Card from '../../components/Card'
import { viewMiqaatCards } from '../../appContent'
import { useDispatch, useSelector } from 'react-redux'
import { AllMeqaatAction } from '../../store/actions/miqaatAction'
import { Spinner } from 'react-bootstrap'

const ViewMiqaat = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const [caredList, setCardList] = useState([])
  const [miqaatName, setMiqaatName] = useState([])
  const miqaatData = useSelector((state) => state.miqaatreducer.miqaatData)

  useEffect(() =>{
    if (!miqaatData || miqaatData.length <= 0){
      dispatch(AllMeqaatAction())
    }
  }, [miqaatData])

  useEffect(() =>{
    if(miqaatData && miqaatData.length > 0){
      let filterData = miqaatData.filter((data)=>{
        if(data.id === parseInt(params?.id)){
            return data
          }
      })
      setMiqaatName(filterData)
    }
  }, [params, miqaatData])
  
  useEffect(() =>{
    let newData =[]
    let newChildder = []
    let teadChildren = []
    let chielderData = []
    let communicationData = []
    let departmentData = []
    let attendeesData = []
    let inventoryData = []
      viewMiqaatCards?.map((data)=>{
        if(!data.isDisabled){
          if(data.childrens){
            data.childrens.map((res) =>{
              if(data.name == 'Questions'){
                chielderData = {
                  ...res,
                  link: `/${res.firstLink}/${params?.id}/${res.secondLink}`
                }
                newChildder.push(chielderData)
                // return
              } else if(data.name == 'Team'){
                chielderData = {
                  ...res,
                  link: `/${res.firstLink}/${params?.id}${res.secondryLink}`
                }
                teadChildren.push(chielderData)
              } else if(data.name == 'Communication'){
                chielderData = {
                  ...res,
                  link: `/${res.firstLink}/${params?.id}/${res.secondryLink}`
                }
                communicationData.push(chielderData)
              } else if(data.name == 'Department'){
                chielderData = {
                  ...res,
                  link: `/${res.firstLink}/${params?.id}/${res.secondLink}`
                }
                departmentData.push(chielderData)
              }else if(data.name == 'Attendees'){
                chielderData = {
                  ...res,
                  link: `/${res.firstLink}/${params?.id}/${res.secondryLink}`
                }
                attendeesData.push(chielderData)
              }
              else if(data.name === 'Inventory'){
                chielderData = {
                  ...res,
                  link: `/${res.firstLink}/${params?.id}/${res.secondLink}`
                }
                inventoryData.push(chielderData)
              }
            })
            if(data.name === 'Questions'){
              let datas = {
                ...data,
                childrens:newChildder,
              }
              newData.push(datas)
            } else if(data.name === 'Team') {
              let datas = {
                ...data,
                childrens:teadChildren,
              }
              newData.push(datas)
            } else if (data.name === 'Communication'){
              let datas = {
                ...data,
                childrens:communicationData,
              }
              newData.push(datas)
            } else if(data.name === 'Inventory'){
              let datas = {
                ...data,
                childrens:inventoryData,
              }
              newData.push(datas)
            } else if (data.name === 'Department'){
              let datas = {
                ...data,
                childrens:departmentData,
              }
              newData.push(datas)
            }
            else if (data.name === 'Attendees'){
              let datas = {
                ...data,
                childrens:attendeesData,
              }
              newData.push(datas)
            }
            
          } else {
            
            let datas = {
              ...data,
              link: `/${data.firstLink}/${params?.id}${data.secondLink}`
            }
            newData.push(datas)
          }
        } else {
          let datas = {
            ...data
          }
          newData.push(datas)
        }
      })
      setCardList(newData)
  }, [viewMiqaatCards])
  return (
    <>
     {/* {loading
      ?
     <div className='loader-wrapper'>
          <Spinner animation="border" variant="success" />
      </div>
      :  */}
      <div className='main-wrapper'>
          <div className='container'>
            <div className="app-head">
                <Link to='/all-miqaat' className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack/></Link>
                <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>{miqaatName[0]?.name}</h2>
            </div>
            <div className="card-wrapper">
                {caredList.map((cardInfo) => (                  
                  <Card key={cardInfo.name} cardDetails={cardInfo} />

                ))}
            </div>
          </div>
      </div>
    {/* } */}
  </>
  )
}

export default ViewMiqaat
