import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { themeColor } from '../../../config'
import TextareaField from '../../../components/TextareaField'
import SelectField from '../../../components/SelectField'
import { teamData } from '../../../appContent'

const RegisterForMiqaat = () => {
  const [itsId, setItsId] = useState()
  // const [ITSIds, setITSIds] = useState({
  //   ITSIds: []
  // })
  const [loader, setLoader] = useState(false)
  const [idError, setIdError] = useState(false)

  const schema = yup
    .object({
      ItsIds: yup.string()
        .required('This field is required')
        .matches(/^[0-9,'\n' ]+$/, 'Must be only digits')
    })
    .required('required')
  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })
  const submitAction = (data) => {
    setLoader(false)
    setIdError(false)
    if (itsId === '') {
      setIdError(true)
    } else {
      setIdError(false)
    }
    const arr = itsId.split('\n')
    const numArr = []
    arr && arr.length && arr.forEach((str, i) => {
      if (str.length > 8 || str.length < 8) {
        setIdError(true)
      } else if (str.length === 8) {
        numArr.push(parseInt(str))
        setIdError(false)
      }
    })
    // setITSIds({
    //   ITSIds: numArr
    // })
  }

  return (
    <div className='main-wrapper'>
        <div className="container">
            <Row className='justify-content-center'>
                <Col lg={7}>
                    <div className="app-head">
                        <Link to='/' className='back-btn' style={{ color: `${themeColor.primaryColor}` }}><IoIosArrowBack/></Link>
                        <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>Register For Miqaat</h2>
                    </div>
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit(submitAction)}>
                            <TextareaField fieldPlaceholder={'Enter ITS ID(s)'}
                                fieldLabel={'ITS ID(s):'} name={'ItsIds'} classNm={'no-margin'}
                                error={errors.ItsIds?.message} register={{ ...register('ItsIds') }}
                                value={itsId} handleChng={(e) => setItsId(e.target.value)}
                            />
                            <br/>
                            {idError
                              ? <h6 className="note note-error">ITS Ids should be of 8 digits</h6>
                              : ''
                            }
                            <h6 className="note note-success">Press Enter After Every ITS ID</h6>
                            <SelectField isMulti={true} className='select-field' fieldLabel={'Select Team :'} placeholder={'Select Team'}
                                name={'team'}
                                options={teamData}
                            />
                            <div className="btn-wrapper">
                                <Link to='/sync-user'><Button variant="danger">Cancel</Button></Link>
                                <Button variant="success" type='submit' disabled={loader} >Register{
                                    loader
                                      ? <div className='loader-wrapper btn-loader'>
                                        <Spinner animation="border" variant="white" />
                                    </div>
                                      : ''
                                }</Button>
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </div>

    </div>
  )
}

export default RegisterForMiqaat
